import { Tr, Td } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const TableBody = ({ getData }) => {
  return (
    <>
      {getData?.map((data, i) => {
        return (
          <Tr
            key={i}
            bg="#fff"
            color="#686278"
            borderBottom="10px solid #efedf3"
            borderRadius="10px"
            fontWeight="semibold"
          >
            <Td textTransform={"capitalize"}>{data?.testName}</Td>
            <Td color="#BBBBBB">
              {typeof data?.result === "object"
                ? Number(data?.result?.result).toFixed(0)
                : Number(data?.result).toFixed(0)}
            </Td>
            <Td
              width="150px"
              color={"#5C2BA8"}
              borderRadius={"5px"}
              p="5px 8px"
            >
              <Link to={`/take-test/${data?.testName}`}>Retake Test</Link>
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
