import { Stack, Text } from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

function IncomingNewNotificationDetails({
  show,
  details,
  onCloseNotification,
}) {
  const { title, message } = details || {};
  return show ? (
    <Stack
      bg="#fff"
      borderRadius={"10px"}
      p="15px"
      position={"absolute"}
      left="-150px"
      top="50px"
      border="1px solid #efedf3"
      width="220px"
      color="#252525"
      maxW="220px"
    >
      <FaTimes
        onClick={() => onCloseNotification()}
        style={{
          fontSize: ".8em",
          position: "absolute",
          right: "20px",
          color: "grey",
        }}
      />
      <Text fontSize="1.3em" fontWeight="bold">
        {title}
      </Text>
      <Text fontSize=".8em">{message}</Text>
    </Stack>
  ) : null;
}

export default IncomingNewNotificationDetails;
