import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/media-query";
import { Redirect, Route, Switch } from "react-router";
import { PROTECTED_PATHS } from "./constants";
import { Navigation } from "../components/nav";
import SideBar from "../components/layout/sidebar";
import { Dashboard } from "../pages/dashboard/";
import { Profile } from "../pages/profile";
import { Doctors } from "../pages/doctors";
import { Test } from "../pages/test";
import TrackPromoAvailability from "../components/TrackPromoAvailability";
// import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import { Subscription } from "../pages/subscription";
import { History } from "../pages/history";
import { TakeTest } from "../pages/test/components/TakeTest";
// import { Invite } from "../pages/test/components/Invite";
import { Chats } from "../pages/chats";
import { UserProfileContextProvider } from "../context/UserContext";
//import { Notifications } from "../pages/notifications";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

// const theme = extendTheme({
//   components: {
//     Steps,
//   },
// });
//import { Chats } from '../pages/chats'
//import { UserProfileContextProvider } from '../context/UserContext'
import { useSelector } from "react-redux";
import { connect, disconnect } from "../services/socket";
import { HealthVideos } from "../pages/healthVideos";
import { ViewVideo } from "../pages/healthVideos/components/ViewVideo";
import { BookingSchedule } from "../pages/bookingSchedule";
import FullPageLoader from "../components/fullPageLoader";
import { Notifications } from "../pages/notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import io from 'socket.io-client'
import { BsChatLeftFill } from "react-icons/bs";

const AuthenticatedApp = () => {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");
  const userId = useSelector((state) => state?.auth?.id);

  ///////////////////////
  // const [stream, setStream] = useState()
  // const [receivingCall, setReceivingCall] = useState(false)
  // const myVideo = useRef()
  // const [callerSignal, setCallerSignal] = useState()

  const {
    DASHBOARD,
    TEST,
    HISTORY,
    PROFILE,
    DOCTORS,
    SUBSCRIPTION,
    CHATS,
    // NOTIFICATIONS,
    // INVITE,
    TEST_TITLE,
    HEALTH_VIDEOS,
    VIEW_VIDEO,
    BOOKING_SCHEDULE,
    NOTIFICATIONS,
  } = PROTECTED_PATHS;

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    connect({ name: userId, type: "Users" }).then(() => {
      setIsLoading(false);
    });
    return disconnect;
  }, [userId]);

  const userType = JSON.parse(sessionStorage.getItem("!!OPQ1"));

  return (
    <UserProfileContextProvider>
      {isLoading ? (
        <Text>
          {" "}
          <FullPageLoader />
        </Text>
      ) : (
          <TrackPromoAvailability>
        <Box className="App" display="flex" position={"relative"}>
          <Bot />
          <Box className={`app-container`}>
            <Box
              className="app-sidebar"
              display={`${toggleSide ? "none" : "block"}`}
            >
              {showSidebar ? <SideBar toggle={handleToggle} /> : ""}
            </Box>
            {/* app right */}
            <Box w="100%" h="100%">
              <Navigation
                toggle={handleToggle}
                SideBarActive={toggleSide}
                showSidebar={showSidebar}
              />

              <Box
                width="100%"
                maxWidth="100%"
                mt="90px"
                padding={["5px", "10px", "20px"]}
              >
                <Switch>
                  {/* <Route path={"/"} exact component={Dashboard} /> */}
                  <Route path={DASHBOARD} exact component={Dashboard} />
                  <Route path={TEST} exact component={Test} />
                  <Route path={HISTORY} exact component={History} />
                  <Route path={PROFILE} exact component={Profile} />
                  <Route path={DOCTORS} exact component={Doctors} />
                  {userType?.client !== "Staff" && (
                    <Route path={SUBSCRIPTION} exact component={Subscription} />
                  )}
                  <Route path={CHATS} exact component={Chats} />
                  <Route path={TEST_TITLE} component={TakeTest} />
                  <Route path={HEALTH_VIDEOS} exact component={HealthVideos} />
                  <Route path={VIEW_VIDEO} exact component={ViewVideo} />
                  <Route
                    path={BOOKING_SCHEDULE}
                    exact
                    component={BookingSchedule}
                  />
                  <Route path={NOTIFICATIONS} exact component={Notifications} />
                  <Redirect from="/*" to={DASHBOARD} />
                </Switch>
              </Box>
            </Box>
          </Box>
            </Box>
            </TrackPromoAvailability>
      )}
    </UserProfileContextProvider>
  );
};

export default AuthenticatedApp;

export const Bot = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      console.log(`Window height changed to: ${newHeight}px`);
      setHeight(newHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Flex
        position={"fixed"}
        right={"1%"}
        onClick={onOpen}
        zIndex={999}
        top="50%"
        bg="#5c2ba8c2"
        _hover={{
          background: "#5c2ba88f",
        }}
        borderRadius={"50%"}
        p="15px"
        cursor={"pointer"}
      >
        <BsChatLeftFill color="#fff" size={"1.5em"} />
      </Flex>

      <Modal
        scrollBehavior="inside"
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          {/* <ModalCloseButton /> */}
          <ModalBody p="0">
            <iframe
              src="https://dashboard.tinytalk.ai/bots/812230b0-e0f8-4dce-b662-7bb2efe34ee9/chat"
              width="100%"
              height={height - 200}
              frameborder="0"
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
