import React from "react";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { HeroCard } from "./components/HeroCard";
import { HealthIssuesCard } from "./components/HealthIssuesCard";
import { useEffect } from "react";
import { getAllTests } from "./services/getAllTests";
import { useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";

export const Test = () => {
  const [allTests, setAllTests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllTests(setAllTests, setLoading);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex direction={["column", "column", "row"]}>
        <Box w={"100%"} mb={"20px"}>
          <HeroCard />
        </Box>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
        {allTests?.map((data, index) => {
          return (
            data?.testName !== "Personal (Patient) Health Questionnaire" && (
              <Box w={{ base: "100%" }} key={index}>
                <HealthIssuesCard
                  title={data?.testName}
                  description={data?.description}
                />
              </Box>
            )
          );
        })}
      </SimpleGrid>
    </Box>
  );
};
