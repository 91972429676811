import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FullPageLoader from "../../components/fullPageLoader";
import { verifyEmail } from "../Login/service/loginUser";

function VerifyEmail({ match }) {
  const [loading, setLoading] = useState(true);
  let verificationId = match?.params?.verificationId;
  let history = useHistory();

  useEffect(() => {
    if (!verificationId) {
      setLoading(false);
      return;
    }

    verifyEmail({ otp: verificationId }, history, setLoading);
    //eslint-disable-next-line
  }, [verificationId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <p>Incorrect link, please check and try again </p>
  );
}

export default VerifyEmail;
