import React, { useEffect, useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { AllDoctorsCard } from "./components/AllDoctorsCard";
import { getDoctors } from "./services/getDoctors";
import FullPageLoader from "../../components/fullPageLoader";
import { SearchBar } from "../../components/SearchBar";
import { searchDoctor } from "./services";
import debounce from "lodash.debounce";

export const Doctors = () => {
  const [doctorsOnline, setDoctorsOnline] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentClickIndex, setCurrentClickIndex] = useState(2);
  const [searchResult, setSearchResult] = useState(null);

  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getDoctors(setAllDoctors, setDoctorsOnline, setLoading);
  }, [refresh]);

  const searchContact = (res) => {
    if (!res) {
      setSearchResult([]);
      return;
    }
    searchDoctor(res, setLoading, setSearchResult);
  };

  //wait for user to finish typing before sending request
  const processSearch = debounce((res) => searchContact(res), 1000);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Stack
        mb="20px"
        spacing="20px"
        justifyContent={"space-between"}
        direction={["column-reverse", "row"]}
        alignItems="center"
      >
        <Stack
          direction="row"
          w="250px"
          borderRadius={"33px"}
          bg="#F3F3F3"
          justifyContent={"space-between"}
        >
          <Box
            borderRadius={"33px"}
            p="10px"
            fontSize=".9em"
            cursor={"pointer"}
            bg={currentClickIndex === 1 ? "#5C2BA8" : "transparent"}
            color={currentClickIndex === 1 ? "#fff" : "#737373"}
            onClick={() => {
              setCurrentClickIndex(1);
            }}
          >
            <Text fontWeight="bold">Therapists Online</Text>
          </Box>
          <Box
            borderRadius={"33px"}
            p="10px"
            fontSize=".9em"
            cursor={"pointer"}
            bg={currentClickIndex === 2 ? "#5C2BA8" : "transparent"}
            color={currentClickIndex === 2 ? "#fff" : "#737373"}
            onClick={() => {
              setCurrentClickIndex(2);
            }}
          >
            <Text fontWeight="bold">All Therapists</Text>
          </Box>
        </Stack>
        <SearchBar
          searchDoctor={(search) => {
            processSearch(search);
          }}
          placeholder="Search therapists"
        />
      </Stack>
      {allDoctors?.length > 0 ? (
        <AllDoctorsCard
          currentClickIndex={currentClickIndex}
          doctors={currentClickIndex === 1 ? doctorsOnline : allDoctors}
          searchResult={searchResult}
          refresh={() => setRefresh({})}
        />
      ) : (
        <Text textAlign={"center"} color="grey" marginTop="150px">
          No data available
        </Text>
      )}
    </Box>
  );
};
