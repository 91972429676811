import { createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../pages/Login/service/getUserProfile";
import { setUserData } from "../store/actions/userAction";
// import { AuthContext } from "./authContext";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  // const {
  //   userInfo: { userId },
  // } = useContext(AuthContext);

  const userId = useSelector((state) => state?.auth?.id);
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getUserData(userId)
      .then((resp) => {
        dispatch(setUserData(resp));
        setUserProfile(resp);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId, dispatch]);

  return (
    <UserProfileContext.Provider value={{ userProfile, isLoading }}>
      {children}
    </UserProfileContext.Provider>
  );
};
