import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getUserInfomation = async (setLoading, userId, setUserDetails) => {
  try {
    const { data } = await http.get(AUTH_ROUTES.GET_USER_DETAILS(userId));

    const {
      userDetails: {
        data: { users },
      },
    } = data || {};

    setUserDetails(...users);

    setLoading && setLoading(false);
  } catch (e) {
    setLoading && setLoading(false);
  }
};

export const updateUser = async (payload) => {
  try {
    // const {
    //   data: { result },
    // }
    await http.put(AUTH_ROUTES.UPDATE_USER_DETAILS(payload._id), payload);
    successNotifier("Profile Updated Successfully");
  } catch (e) {
    const {
      response: {
        data: { data },
      },
    } = e || {};
    errorNotifier(data);
  }
};

export const updatePersonalInfo = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_PERSONAL_INFO, payload);
    successNotifier("Personal Information Updated Successfully");
  } catch (e) {
    const {
      response: {
        data: { data },
      },
    } = e || {};
    errorNotifier(data);
  }
};

export const updateEmailInfo = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_EMAIL_INFO, payload);
    successNotifier("Email Updated Successfully");
  } catch (e) {
    const {
      response: {
        data: { data },
      },
    } = e || {};
    errorNotifier(data);
  }
};
export const updatePhoneNumberInfo = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_PHONE_NUMBER_INFO, payload);
    successNotifier("Phone Updated Successfully");
  } catch (e) {
    const {
      response: {
        data: { data },
      },
    } = e || {};

    errorNotifier(data);
  }
};

export const uploadProfileImage = async (payload) => {
  try {
    await http.post(AUTH_ROUTES.UPLOAD_PROFILE_IMAGE, payload);
    successNotifier("Profile uploaded Successfully");
  } catch (e) {
    errorNotifier("Error uploading profile image");
  }
};

export const updatePasswordInfo = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_PASSWORD_INFO, payload);
    sessionStorage.removeItem("HBU#221#");
    sessionStorage.removeItem("#userDetail#");
    window.location.href = "/";
  } catch (e) {
    const {
      response: {
        data: { data },
      },
    } = e || {};
    errorNotifier(data);
  }
};
