export const AUTH_ROUTES = {
  GET_TEST_NAMES: "/users/tests/name",
  LOGIN: "/users/login",
  GOOGLE_LOGIN: "users/google",
  LOGIN_AS: "/users/login/confirmation",
  FORGOT_PASSWORD: "/initiate-forgot-password",
  // ADD_USER: "/admin/add-user",
  GET_USERS: (userId) => `/users?id=${userId}`,
  GET_USER_DETAILS: (userId) => `/users?id=${userId}`,

  GET_CHATS: (userId, doctorId) =>
    `/doctors/messages?doctorId=${doctorId}&userId=${userId}&limit=500`,
  GET_CONVERSATIONS: (userId) =>
    `/users/conversations?userId=${userId}&sort=desc`,
  RATE_DOCTOR: `/users/doctors/ratings`,
  LOCK_CONVERSATION: (id) => `/conversation/${id}/lock-status`,

  UPDATE_USER_DETAILS: (userId) => `/users/${userId}/update`,
  UPDATE_PERSONAL_INFO: "/users/update",
  UPDATE_PASSWORD_INFO: "/users/password",
  UPDATE_EMAIL_INFO: "/users/email/update",
  UPDATE_PHONE_NUMBER_INFO: "/users/number/update",
  DELETE_USER: (userId) => `/users/${userId}/delete`,
  RESET_PASSWORD: "/reset-password",
  SUBSCRIPTION: "/subscriptions",
  VERIFY_SUBSCRIPTIONS: "/subscriptions/verify",
  GET_CREDIT_PURCHASED: (month, year) =>
    `/analytic/credit-purchased?month=${month}&year=${year}`,

  GET_CREDIT_USED: (month, year) =>
    `/analytic/credit-used?month=${month}&year=${year}`,

  GET_DOCTORS: "/doctors",
  GET_DOCTOR_INFO: (doctorId) => `/users/doctors?id=${doctorId}`,
  BOOK_DOCTOR: "/users/doctors/book",
  GET_NOTIFICATIONS: `notifications?recipientId=62668bcd344ac60af03f950f`,
  GIFT_CREDIT: "/users/credit/gift",
  SUBMIT_TEST: "/users/test",
  GET_ALL_TESTS: "/users/get-test",
  GET_TEST: ({ skip, limit, filter }) =>
    `/users/tests?skip=${skip}&limit=${limit}&sort=desc${
      filter ? `&filter=${filter}` : ""
    }`,
  GET_SUBSCRIPTION_LIST: "/users/subscription-list",

  GET_SUBSCRIPTIONS: ({ skip, limit }) =>
    `/subscriptions?skip=${skip}&limit=${limit}&sort=desc`,
  // VERIFY_SUBSCRIPTIONS: '/subscriptions/verify',
  CREATE_SUBSCRIPTIONS: "/subscriptions",
  // GET_CREDIT_PURCHASED: (month, year) =>
  //   `/analytic/credit-purchased?month=${month}&year=${year}`,
  //GET_DOCTORS: '/doctors',
  //BOOK_DOCTORS: '/users/doctors/book',
  GET_PATIENT_TESTS: "/users/tests",
  ADD_TESTS: "/users/test",
  SEND_INVITATION: "/users/invite",
  SEARCH_DOCTOR: (search) => `/users/doctors/search?search=${search}`,
  UPLOAD_PROFILE_IMAGE: "/users/image/upload",
  INVITE_USER: "/users/invite",
  GET_TESTS: "/users/tests",
  SIGNUP: "/users",
  GET_SCHEDULED_DATES: (doctorId, date) =>
    `/users/bookings?doctorId=${doctorId}&date=${date}`, //2022-08-14
  SEARCH_CONTACT: (search) =>
    `/users/chat-conversations/search?search=${search}`,
  GET_BOOKING_TIMER: (bookingId) =>
    `/users/bookings?id=${"62a35f01278643e7c23484cb"}`,
  GET_VIDEOS: "/users/videos",
  GET_SINGLE_VIDEO: (videoId) => `/users/videos?id=${videoId}`,
  GET_PATIENT_SCHEDULES: "/users/bookings?sort=desc",
  RESCHEDULE_BOOKING: "/users/doctors/reschedule",
  GET_ALL_NOTIFICATIONS: "/users/notifications?sort=desc",
  CANCEL_BOOKING: (bookingId) => `/users/booking/cancel/${bookingId}`,
  RESEND_TOKEN: "resend-token",
  VERIFY_EMAIL: "users/verify-email",
};
