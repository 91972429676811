import { Box, Stack, Text } from "@chakra-ui/react";

export const UserInformationsTabs = ({ data }) => {
  const { email, phoneNumber, DOB } = data || {};
  const TITLE_STYLE = {
    fontSize: ".8em",
    color: "#352848",
  };

  const CONTENT_STYLE = {
    bg: "#fff",
    fontSize: ".86em",
    padding: "5px 7px",
    mt: "10px",
    height: "30px",
    maxHeight: "30px",
  };

  return (
    <Box>
      <Stack direction="row" spacing="30px">
        <Box>
          <Text {...TITLE_STYLE}>Email</Text>
          <Text {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {email}
          </Text>
        </Box>
        <Box>
          <Text {...TITLE_STYLE}>Phone number</Text>
          <Text {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {phoneNumber}
          </Text>
        </Box>
        <Box>
          <Text {...TITLE_STYLE}>Date of Birth</Text>
          <Text {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {DOB}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
