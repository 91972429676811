import http, { AUTH_ROUTES } from "../../../services/api";

export const getAllVideos = async (setVideos, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_VIDEOS);
    setVideos(data?.data?.video?.data?.videos);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};
