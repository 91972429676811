import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { errorNotifier } from "../../../components/NotificationHandler";
import { resetPassword } from "../service";

export const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState({ new: false, confirm: false });
  const [errorText, setErrorText] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorText("");
    if (password !== confirmPassword) {
      setErrorText("Password does not match");
      return;
    }
    setLoading(true);
    resetPassword({ ...props.data, password })
      .then(() => {
        navigate.push("/login");
      })
      .catch((e) => {
        errorNotifier(
          e.response?.data?.result?.message || "Failed to send email"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateShow = (key) => {
    setShow((val) => ({ ...val, [key]: !show[key] }));
  };

  return (
    <Box width={"100%"} p="100px">
      <Heading
        fontFamily="Gilroy-Bold"
        mb="10px"
        color="#2A2047"
        fontSize="46px"
        lineHeight={1.1}
      >
        Create New <br />
        <Text style={{ fontWeight: "bold" }}>Password</Text>
      </Heading>
      <Text mb="20px">Input the code sent to your email address</Text>
      <Text color={"red"}>{errorText}</Text>
      <form onSubmit={handleLogin}>
        <FormControl>
          <FormLabel htmlFor="email" color="#929292">
            New Password
          </FormLabel>
          <InputGroup alignItems={"center"}>
            <Input
              height="50px"
              borderRadius={"10px"}
              type={show.new ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement
              top={"auto"}
              children={
                <span
                  style={{ cursor: "pointer", padding: "5px" }}
                  onClick={() => updateShow("new")}
                >
                  {show.new ? <FaEyeSlash /> : <FaEye />}
                </span>
              }
            />
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="email" color="#929292">
            Confirm Password
          </FormLabel>
          <InputGroup alignItems={"center"}>
            <Input
              type={show.confirm ? "text" : "password"}
              height="50px"
              borderRadius={"10px"}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <InputRightElement
              top={"auto"}
              children={
                <span
                  style={{ cursor: "pointer", padding: "5px" }}
                  onClick={() => updateShow("confirm")}
                >
                  {show.confirm ? <FaEyeSlash /> : <FaEye />}
                </span>
              }
            />
          </InputGroup>
        </FormControl>
        <Button
          mt="20px"
          bg="#2A2047"
          color="#fff"
          borderRadius={"10px"}
          width="100%"
          height="50px"
          isLoading={loading}
          type="submit"
          _hover={{ bg: "#5C2BA8" }}
        >
          Create Password
        </Button>
      </form>
    </Box>
  );
};
