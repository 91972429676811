import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
// import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import FullPageLoader from "../../components/fullPageLoader";
import { CompletedSchedules } from "./components/CompletedSchedules";
import { PendingSchedules } from "./components/PendingSchedules";
import { getPatientSchdules } from "./services";
import { ApprovedSchedules } from "./components/ApprovedSchedules";

export const BookingSchedule = () => {
  const [loading, setLoading] = useState(true);
  // const [currentClickIndex, setCurrentClickIndex] = useState(1);

  //completed state
  const [completedScheduledDateKeys, setCompletedScheduledDateKeys] = useState(
    []
  );
  const [
    completedScheduledDateKeysAndValues,
    setCompletedScheduledDateKeysAndValues,
  ] = useState([]);

  //pending state
  const [pendingScheduledDateKeys, setPendingScheduledDateKeys] = useState([]);
  const [
    pendingScheduledDateKeysAndValues,
    setPendingScheduledDateKeysAndValues,
  ] = useState([]);

  //approved state
  const [approvedScheduledDateKeys, setApprovedScheduledDateKeys] = useState(
    []
  );
  const [
    approvedScheduledDateKeysAndValues,
    setApprovedScheduledDateKeysAndValues,
  ] = useState([]);

  const [selectedScheduleKey, setSelectedScheduleKey] = useState("");

  // const [type, setType] = useState("PENDING");

  // const [refresh, setRefresh] = useState({});
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  //diaplay schdeule details of the first date, when it mounts
  useEffect(() => {
    if (!pendingScheduledDateKeys) return;

    if (!selectedScheduleKey) {
      setSelectedScheduleKey(pendingScheduledDateKeys?.[0]);
    }
    //eslint-disable-next-line
  }, [pendingScheduledDateKeys]);

  useEffect(() => {
    getPatientSchdules(
      setCompletedScheduledDateKeys,
      setCompletedScheduledDateKeysAndValues,

      setPendingScheduledDateKeys,
      setPendingScheduledDateKeysAndValues,

      setApprovedScheduledDateKeys,
      setApprovedScheduledDateKeysAndValues,
      setLoading
    );
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box bg="#F6F4F9">
      <CurveBackgroundColor>
        <Tabs variant={"soft-rounded"} defaultIndex={defaultTabIndex}>
          <TabList maxW="350px" bg="#fff" borderRadius={"33px"} mb="20px">
            <Tab
              _selected={{
                background: "#5c2ba8",
                fontWeight: "bold",
                color: "#fff",
              }}
              _focus={{ boxShadow: "none" }}
              onClick={() => {
                setSelectedScheduleKey(pendingScheduledDateKeys?.[0]);
                setActiveIndex(0);
              }}
            >
              Pending
            </Tab>
            <Tab
              _selected={{
                background: "#5c2ba8",
                fontWeight: "bold",
                color: "#fff",
              }}
              _focus={{ boxShadow: "none" }}
              onClick={() => {
                setSelectedScheduleKey(approvedScheduledDateKeys?.[0]);
                setActiveIndex(0);
              }}
            >
              Approved
            </Tab>
            <Tab
              _selected={{
                background: "#5c2ba8",
                fontWeight: "bold",
                color: "#fff",
              }}
              _focus={{ boxShadow: "none" }}
              onClick={() => {
                setSelectedScheduleKey(completedScheduledDateKeys?.[0]);
                setActiveIndex(0);
              }}
            >
              Booking history
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <PendingSchedules
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                pendingScheduledDateKeys={pendingScheduledDateKeys}
                pendingScheduledDateKeysAndValues={
                  pendingScheduledDateKeysAndValues
                }
                selectedScheduleKey={selectedScheduleKey}
                setSelectedScheduleKey={setSelectedScheduleKey}
                refresh={() => {
                  setLoading(true);
                  getPatientSchdules(
                    //completed
                    setCompletedScheduledDateKeys,
                    setCompletedScheduledDateKeysAndValues,
                    //pending
                    setPendingScheduledDateKeys,
                    setPendingScheduledDateKeysAndValues,
                    //approved
                    setApprovedScheduledDateKeys,
                    setApprovedScheduledDateKeysAndValues,
                    setLoading
                  );
                  setDefaultTabIndex(0);
                  setSelectedScheduleKey(pendingScheduledDateKeys?.[0]);
                }}
              />
            </TabPanel>
            <TabPanel>
              <ApprovedSchedules
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                approvedScheduledDateKeys={approvedScheduledDateKeys}
                approvedScheduledDateKeysAndValues={
                  approvedScheduledDateKeysAndValues
                }
                selectedScheduleKey={selectedScheduleKey}
                setSelectedScheduleKey={setSelectedScheduleKey}
                refresh={() => {
                  setLoading(true);
                  getPatientSchdules(
                    //completed
                    setCompletedScheduledDateKeys,
                    setCompletedScheduledDateKeysAndValues,
                    //pending
                    setPendingScheduledDateKeys,
                    setPendingScheduledDateKeysAndValues,
                    //approved
                    setApprovedScheduledDateKeys,
                    setApprovedScheduledDateKeysAndValues,
                    setLoading
                  );
                  setDefaultTabIndex(1);
                  setSelectedScheduleKey(approvedScheduledDateKeys?.[0]);
                }}
              />
            </TabPanel>
            <TabPanel>
              <CompletedSchedules
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                selectedScheduleKey={selectedScheduleKey}
                setSelectedScheduleKey={setSelectedScheduleKey}
                completedScheduledDateKeys={completedScheduledDateKeys}
                completedScheduledDateKeysAndValues={
                  completedScheduledDateKeysAndValues
                }
                refresh={() => {
                  setLoading(true);
                  getPatientSchdules(
                    //completed
                    setCompletedScheduledDateKeys,
                    setCompletedScheduledDateKeysAndValues,
                    //pending
                    setPendingScheduledDateKeys,
                    setPendingScheduledDateKeysAndValues,
                    //approved
                    setApprovedScheduledDateKeys,
                    setApprovedScheduledDateKeysAndValues,
                    setLoading
                  );
                  setDefaultTabIndex(2);
                  setSelectedScheduleKey(completedScheduledDateKeys?.[0]);
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CurveBackgroundColor>
    </Box>
  );
};
