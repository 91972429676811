import jwtDecode from "jwt-decode";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import AuthenticatedApp from "./app/authenticatedApp";
import UnAuthenticatedApp from "./app/unAuthenticatedApp";
import FullPageLoader from "./components/fullPageLoader";
import { authSetUser } from "./store/actions/authAction";

function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const userId = useSelector((state) => state?.auth?.id);

  useEffect(() => {
    const data = sessionStorage.getItem("HBU#221#");
    if (data) {
      const decoded = jwtDecode(data);
      const expiryDate = new Date(decoded.exp * 1000);
      if (new Date() > expiryDate) {
        sessionStorage.removeItem("HBU#221#");
      } else {
        dispatch(authSetUser({ ...decoded }));
        setIsLoggedIn(true);
      }
    }

    setCheckingStatus(false);
  }, [dispatch, userId]);

  if (checkingStatus) {
    return <FullPageLoader />;
  }

  if (isLoggedIn) {
    return <AuthenticatedApp />;
  }

  return <UnAuthenticatedApp />;
}

export default App;
