import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import { SignupForm } from './components/SignupForm'
// import { Wave } from './svgs/Wave'
import background from './assets/background.png'

export const Signup = () => {
  return (
    <Box>
      <Flex direction={['column', 'column', 'row', 'row']}>
        <Box flex='1'>
          <Image
            objectFit={'cover'}
            width='100%'
            height='100%'
            p={['25px', '40px 50px 0', '0px', '0']}
            src={background}
          />
        </Box>
        <Box flex='1'>
          <SignupForm />
        </Box>
      </Flex>
    </Box>
  )
}
