import React, { useEffect, useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { TableBody } from "./components/TableBody";
import { PaginatedTable } from "../../components/PaginatedTable";
import { tableHeadData } from "./components/tableHeadData";
import { TestRow } from "./components/TestRow";
import { getHistory } from "./services";
import FullPageLoader from "../../components/fullPageLoader";

export const History = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [testTotal, setTestTotal] = useState(0);

  const [filter, setFilter] = useState(null);

  useEffect(() => {
    setLoading(true);
    getHistory(filter)
      .then((data) => {
        setTests([...data?.tests]);
        setTestTotal(data?.totalTests);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setTests([]);
        // setTestTotal(0);
      });
  }, [filter]);
  return (
    <>
      <Flex
        w="100%"
        direction={["column", "column", "column"]}
        justifyContent="space-between"
        p="10px"
      >
        <TestRow
          total={testTotal}
          update={(e) => {
            setFilter(e);
          }}
        />
      </Flex>
      <Box>
        {loading ? (
          <FullPageLoader />
        ) : (
          <PaginatedTable
            length={tests?.length}
            columns={tableHeadData}
            bg="#F6F4F9"
            total={testTotal}
            data={<TableBody getData={tests} />}
            updateTable={async (page) => {
              getHistory(filter, page)
                .then((data) => {
                  setTests([...data?.tests]);
                  setTestTotal(data?.totalTests);
                })
                .catch((e) => {
                  setTests([]);
                  // setTestTotal(0);
                });
            }}
          />
        )}
      </Box>
    </>
  );
};
