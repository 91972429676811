import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

export const AuthCode = (props) => {
  const [otp, setOtp] = useState("");

  const submit = (e) => {
    e.preventDefault();
    props.updateData && props.updateData({ otp });
  };

  return (
    // <Box>

    <Box width={"100%"} p="100px">
      <Heading
        fontFamily="Gilroy-Bold"
        mb="10px"
        color="#2A2047"
        fontSize="46px"
        lineHeight={1.1}
      >
        Authentication <br />
        <Text style={{ fontWeight: "bold" }}>Code</Text>
      </Heading>
      <Text mb="20px">Input the code sent to your email address</Text>
      <form onSubmit={submit}>
        <FormControl>
          <FormLabel htmlFor="email" color="#929292">
            Input Code
          </FormLabel>
          <Input
            height="50px"
            borderRadius={"10px"}
            onChange={(e) => setOtp(e.target.value)}
          />
        </FormControl>
        <Button
          mt="20px"
          bg="#2A2047"
          color="#fff"
          borderRadius={"10px"}
          width="100%"
          height="50px"
          type="submit"
          disabled={!otp.length}
          _hover={{ bg: "#5C2BA8" }}
        >
          Continue
        </Button>
      </form>
    </Box>

    // </Box>
  );
};
