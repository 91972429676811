import { DATA_ROWS } from "../../../app/constants";
import http, { AUTH_ROUTES } from "../../../services/api";

export async function getHistory(
  filter = null,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
    filter,
  };
  const resp = await http.get(AUTH_ROUTES.GET_TEST(params));
  return resp.data?.test?.data;
}
