import dayjs from "dayjs";
import { successNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getPatientSchdules = async (
  setCompletedScheduledDateKeys,
  setCompletedScheduledDateKeysAndValues,
  setPendingScheduledDateKeys,
  setPendingScheduledDateKeysAndValues,

  setApprovedScheduledDateKeys,
  setApprovedScheduledDateKeysAndValues,

  setLoading
) => {
  try {
    const res = await http.get(AUTH_ROUTES.GET_PATIENT_SCHEDULES);
    const schedules = res?.data?.booking?.data?.schedules;

    const pendingSchedules = schedules?.filter(
      (schedule) => schedule?.status === "PENDING"
    );
    const approvedSchedules = schedules?.filter(
      (schedule) => schedule?.status === "APPROVED" && !schedule?.isElapsed
    );

    const completedSchedules = schedules?.filter(
      (schedule) =>
        schedule?.status === "COMPLETED" ||
        (schedule?.status === "APPROVED" && schedule?.isElapsed === true) //the reason for including approved is to get isElapsed
    );

    const addGroupByKeyIntoPendingScheduleObjects = pendingSchedules.map(
      (schedule) => ({
        ...schedule,
        groupBy: dayjs(schedule.startDate).format("YYYY-MM-DD"),
      })
    );

    const addGroupByKeyIntoApprovedScheduleObjects = approvedSchedules.map(
      (schedule) => ({
        ...schedule,
        groupBy: dayjs(schedule.startDate).format("YYYY-MM-DD"),
      })
    );

    const addGroupByKeyIntoCompletedScheduleObjects = completedSchedules.map(
      (schedule) => ({
        ...schedule,
        groupBy: dayjs(schedule.startDate).format("YYYY-MM-DD"),
      })
    );
    const pendingScheduleGroup =
      addGroupByKeyIntoPendingScheduleObjects?.reduce(function (r, a) {
        r[a.groupBy] = r[a.groupBy] || [];
        r[a.groupBy].push(a);
        return r;
      }, Object.create(null));

    const approvedScheduleGroup =
      addGroupByKeyIntoApprovedScheduleObjects?.reduce(function (r, a) {
        r[a.groupBy] = r[a.groupBy] || [];
        r[a.groupBy].push(a);
        return r;
      }, Object.create(null));

    const completedScheduleGroup =
      addGroupByKeyIntoCompletedScheduleObjects?.reduce(function (r, a) {
        r[a.groupBy] = r[a.groupBy] || [];
        r[a.groupBy].push(a);
        return r;
      }, Object.create(null));

    const completedScheduleDatekeys = Object.keys(completedScheduleGroup);
    const completedScheduleDatekeysAndValues = completedScheduleGroup;

    const pendingScheduleDatekeys = Object.keys(pendingScheduleGroup);
    const pendingScheduleDatekeysAndValues = pendingScheduleGroup;

    const approvedScheduleDatekeys = Object.keys(approvedScheduleGroup);
    const approvedScheduleDatekeysAndValues = approvedScheduleGroup;

    setCompletedScheduledDateKeys(completedScheduleDatekeys);
    setCompletedScheduledDateKeysAndValues(completedScheduleDatekeysAndValues);

    setPendingScheduledDateKeys(pendingScheduleDatekeys);
    setPendingScheduledDateKeysAndValues(pendingScheduleDatekeysAndValues);

    setApprovedScheduledDateKeys(approvedScheduleDatekeys);
    setApprovedScheduledDateKeysAndValues(approvedScheduleDatekeysAndValues);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const reschedulePatientBooking = async ({
  bookingId,
  startDate,
  endDate,
  setLoading,
  onClose,
  refresh,
}) => {
  try {
    await http.put(AUTH_ROUTES.RESCHEDULE_BOOKING, {
      bookingId,
      startDate,
      endDate,
    });
    setLoading(false);
    successNotifier("Successfully rescheduled");
    refresh();
    onClose();

    // window.location.reload();
  } catch (e) {
    setLoading(false);
  }
};
