import { Box, Button, Divider, Flex, Select, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

export const TestRow = (props) => {
  const handleChange = (e) => {
    const { value } = e.target;
    props.update && props.update(value ? value : null);
  };

  const computeMonthValue = [
    { label: "Last month", value: dayjs().subtract(1, "month").format("MM") },
  ];
  return (
    <Box bg="inherit">
      <Divider
        style={{ border: "1px solid #d9ceeb" }}
        orientation="horizontal"
      />
      <Flex justifyContent={"space-between"} alignItems="center" width="100%">
        <Flex
          width={"40%"}
          justifyContent="space-between"
          alignItems={"center"}
          color="#BBBBBB"
        >
          <Flex
            width={["40%"]}
            justifyContent={["space-between"]}
            alignItems="center"
          >
            <Text color="#9E8FB5" fontSize={"3em"} fontWeight={"bold"}>
              {props.total || 0}
            </Text>
            <Text fontSize=".9em" fontWeight="bold">
              Tests Taken
            </Text>
            <Divider
              style={{ border: "1px solid #d9ceeb", height: "100px" }}
              orientation="vertical"
            />
          </Flex>
          <Text fontSize=".8em" fontWeight="bold">
            Sort By:
          </Text>
          <Select
            name="filter"
            onChange={handleChange}
            _focus={{ boxShadow: "none" }}
            borderRadius="5px"
            bg="#fff"
            width={"140px"}
            size="sm"
            color="#929292"
          >
            <option value={""}>All</option>
            {computeMonthValue.map((month) => (
              <option value={month.value}> {month.label} </option>
            ))}
          </Select>
        </Flex>
        <Box>
          <Link to="/take-test">
            <Button
              w="120px"
              size="sm"
              bg="#5C2BA8"
              _hover={{ bg: "#5C2BA8" }}
              color="#fff"
              borderRadius={"25px"}
            >
              Take a Test
            </Button>
          </Link>
        </Box>
      </Flex>
      <Divider
        style={{ border: "1px solid #d9ceeb" }}
        orientation="horizontal"
      />
    </Box>
  );
};
