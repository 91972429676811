import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BsCameraVideoOff } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { socketClient } from "../../../services/socket";
import {
  getChats,
  lockConversation,
  // lockConversation
} from "../services/getChats";
import { TypeMessage } from "./TypeMessage";
import RateModal from "./RateModal";
import FullPageLoader from "../../../components/fullPageLoader";
import {
  connect,
  // peerClient
} from "../../../services/peer";
import { UserProfileContext } from "../../../context/UserContext";
import { getUserInfomation } from "../../profile/services/updateUser";
import { FiPhoneOff } from "react-icons/fi";
import dayjs from "dayjs";
import PersistentCountdownTimer from "../../../components/PersistentCountdownTimer";
import { BOOKING_END_MINUTE } from "../../../app/constants";
import VideoCallSDK from "./video-call-sdk/VideoCallSDK";
import AudioCallSDK from "./audio-call-sdk/AudioCallSDK";
import { clearCallState } from "../../../store/actions/incomingCallAction";
import { useLocation } from "react-router-dom";
import RateDoctorOnClick from "./RateDoctorOnClick";

export const ChatsComponents = ({
   displayChat,
  refresh,
  setDisplayOrHideChatFeedForSmScreen,
  displayOrHideChatFeedForSmScreen,
  ...props
}) => {
  const userId = useSelector((state) => state?.auth?.id);
  const dispatch = useDispatch();

  const [
     userInformation,
    setUserInformation,
  ] = useState([]);

  const { userProfile } = useContext(UserProfileContext) || {};
  const { _id } = userProfile || {};
 const meetType = (userProfile?.lockedCredit === 0 && userProfile?.used15MinuteOffer=='pending') ?'free': 'paid'
  useEffect(() => {
    getUserInfomation(setLoading, _id, setUserInformation);
  }, [refresh, _id, setUserInformation]);

  const [messages, setMessages] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [chatBoxRef, setChatBoxRef] = useState(null);
  const [ratingModal, setRatingModal] = useState(false);

  const [conversationId, setConversationId] = useState("");

  const { doctorIdOfTimer, startTime: theCountDownStartTime } =
    JSON.parse(sessionStorage.getItem("timerDetailsObject")) || {};

  const timerObjectInSessionStorage = JSON.parse(
    sessionStorage.getItem("timerDetailsObject")
  );

  const { openVideoCall, openAudioCall } =
    useSelector((state) => state?.getIncomingCallData) || {};

  const childVideoEvents = { click: () => {} }; //call child function from the parent component
  const childAudioEvents = { click: () => {} }; //call child function from the parent component
  let location = useLocation();

  const childAudioRef = useRef(null);
  const childVideoRef = useRef(null);

  //leave call when participant decline the call request
  useEffect(() => {
    socketClient.on("end-call", (data) => {
      const { calltype, endType } = data || {};
      if (calltype === "audio") {
        childAudioRef?.current?.callDeclined({ missedCall: endType });
      } else if (calltype === "video") {
        childVideoRef?.current?.callDeclined({ missedCall: endType });
      }
    });
  }, []);

  //open call when it is being called from a different page
  //and call these function after it has finished loading
  useEffect(() => {
    if (location?.state?.openCall && loading === false) {
      if (openAudioCall === true) {
        childAudioEvents?.click();
      } else if (openVideoCall === true) {
        childVideoEvents?.click();
      } else {
        return;
      }
    }
    //eslint-disable-next-line
  }, [location?.state?.openCall, openAudioCall, openVideoCall, loading]);

  useEffect(() => {
    if (openAudioCall === true) {
      childAudioEvents?.click();
    } else if (openVideoCall === true) {
      childVideoEvents?.click();
    } else {
      return;
    }
    //eslint-disable-next-line
  }, [openAudioCall, openVideoCall]);

  //get chat conversation id
  useEffect(() => {
    if (messages.length < 1) return;
    setConversationId(messages?.[0]?.conversationId);
    //eslint-disable-next-line
  }, [messages]);

  let messagesEnd = useRef(null);

  const scrollToBottom = () => {
    messagesEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const [updateScroll, setUpdateScroll] = useState({});
  useEffect(() => {
    scrollToBottom(0, 0);
    //eslint-disable-next-line
  }, [chatBoxRef, updateScroll]);

  const getMessages = useCallback(() => {
    if (!displayChat?._id) {
      return;
    }
    setLoading(true);
    getChats(userId, displayChat?.doctorId?._id)
      .then((resp) => {
        setMessages([...resp]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, displayChat]);

  useEffect(() => {
    if (chatBoxRef) {
      chatBoxRef.scrollTop = chatBoxRef.scrollHeight;
    }
  }, [messages, chatBoxRef]);

  useEffect(() => {
    
    connect(userId);
    setMessages([]);
    // get previous messages
    getMessages();
    // listen to current message from the doctor
    socketClient.on("private-message", (data) => {
      //start timer when message is received, and chat isnt locked and count down timer hasnt started
      if (!displayChat?.isLocked && !startTimer) {
        setStartTimer(true);
      }
      setUpdateScroll({}); //scroll down when new message is received
      setMessages((v) => [
        ...v,
        { message: data, sender: "Doctors", createdAt: new Date() },
      ]);
    });
    socketClient.on("attachment", (data) => {
      //start timer when message is received, and chat isnt locked and count down timer hasnt started
      if (!displayChat?.isLocked && !startTimer) {
        setStartTimer(true);
      }

      setUpdateScroll({}); //scroll down when new message is received
      setMessages((v) => [
        ...v,
        { file: data, sender: "Doctors", createdAt: new Date() },
      ]);
    });
    return () => {
      socketClient.removeAllListeners("private-message");
      socketClient.removeAllListeners("attachment");
    };
    //eslint-disable-next-line
  }, [getMessages, displayChat, userId]);

  function sendMessage(data) {
    setMessages((v) => [...v, data]);
    let dataObj = { ...data };
    if (dataObj.file) {
      dataObj.file = data.file.replace("data:", "").replace(/^.+,/, "");
      socketClient.emit("attachment", dataObj);
    } else {
      socketClient.emit("private-message", dataObj);
    }
  }

  const [startTimer, setStartTimer] = useState(false);
  const [countDownHasCompleted, setCountDownHasCompleted] = useState(false);
  //lock Conversation after countdown has finished
  const handleOnComplete = () => {
    lockConversation(conversationId, true)
      .then(() => {
        setRatingModal(true);
        refresh();
      })
      .catch(() => {
        //reload the browser ones lock chat fails, so they dont still have to be able to type after timeout
        window.location.href = "/chats";
      });
    //delete countdown start time after completed
    sessionStorage.removeItem("timerDetailsObject");
    //clear all call state
    dispatch(clearCallState());
  };
  const shouldStartTimer = useCallback(() => {
    if (!timerObjectInSessionStorage) return false;

    if (doctorIdOfTimer !== displayChat?.doctorId?._id) return false;

    const addXminToTime = dayjs(timerObjectInSessionStorage?.startTime)
      .add(BOOKING_END_MINUTE(meetType), "minute")
      .format();

    //if cuurent time is less than the end time, then time hasnt elapsed, else it has
    const timerHasElasped = new Date() > new Date(addXminToTime);
    if (timerHasElasped) return false;

    return true;
  }, [
    timerObjectInSessionStorage,
    displayChat?.doctorId?._id,
    doctorIdOfTimer,
  ]);

  const [openedMedia, setOpenedMedia] = useState("");
  const [lockMedia, setLockMedia] = useState(true);

  useEffect(() => {
    if (displayChat?.isLocked || countDownHasCompleted) {
      if (openedMedia === "video") {
        childVideoRef?.current?.timeOut();
      } else if (openedMedia === "audio") {
        childAudioRef?.current?.timeOut();
      } else {
      }
      setLockMedia(true);
      return;
    }

    //open call if chat is unlock and there a countdown timer in the local storage
    if (displayChat?.isLocked === false && shouldStartTimer()) {
      setLockMedia(false);
    } else {
      if (openedMedia === "video") {
        childVideoRef?.current?.timeOut();
      } else if (openedMedia === "audio") {
        childAudioRef?.current?.timeOut();
      } else {
      }

      setLockMedia(true);
    }
    //eslint-disable-next-line
  }, [
    countDownHasCompleted,
    displayChat?.isLocked,
    openedMedia,
    shouldStartTimer,
  ]);

  return (
    <>
      {loading ? (
        <FullPageLoader h={"80vh"} />
      ) : displayChat?._id ? (
        <Box
          bg="#F6F4F9"
          borderRadius={"10px"}
          h={["100%", "100%", "80vh"]}
          fontSize={"12px"}
          p={["20px 10px", "20px 20px", "20px 30px"]}
          position={["", "", "relative"]}
        >
          <Box>
            <Stack
              direction={"row"}
              // bg="inherit"
              alignItems={"center"}
              w="100%"
              justifyContent="space-between"
            >
              <Flex mb="10px" alignItems={"center"}>
                <Avatar
                  name={displayChat?.doctorId?.name || ""}
                  src={displayChat?.doctorId?.profilePicture}
                  alt=""
                  onClick={() =>
                    setDisplayOrHideChatFeedForSmScreen(
                      !displayOrHideChatFeedForSmScreen
                    )
                  }
                />
                <Text
                  ml="10px"
                  color="#403058"
                  fontSize="14px"
                  fontWeight={"semibold"}
                >
                  {displayChat?.doctorId?.name || ""}
                </Text>
              </Flex>
              <RateDoctorOnClick
                doctorId={displayChat?.doctorId?._id || displayChat?._id}
              />
              <Stack direction="row" alignItems={"center"}>
                {displayChat?.isLocked === false && shouldStartTimer() ? (
                    <PersistentCountdownTimer
                      meetType={meetType}
                    countDownStartTime={theCountDownStartTime}
                    setCountDownHasCompleted={setCountDownHasCompleted}
                    trackingId={displayChat?.doctorId?._id}
                    startTimer={!!theCountDownStartTime}
                    setStartTimer={setStartTimer}
                    refreshOnCountDownComplete={() => {
                      handleOnComplete();
                    }}
                    conversationId={conversationId}
                  />
                ) : null}

                <Flex alignItems={"center"} justifyContent={["flex-end"]}>
                  <Box mx="30px">
                    {!lockMedia ? (
                      <VideoCallSDK
                        myId={displayChat?.userId?._id}
                        recipientId={displayChat?.doctorId?._id}
                        childRef={childVideoRef}
                        events={childVideoEvents}
                        meetingId={displayChat?.videoMeetingDetails?.meetingId}
                        videoToken={displayChat?.videoMeetingDetails?.token}
                        countDownHasCompleted={countDownHasCompleted}
                        setOpenedMedia={setOpenedMedia}
                      />
                    ) : (
                      <BsCameraVideoOff size="1.4em" color="#403058" />
                    )}
                  </Box>
                  <Box>
                    {!lockMedia ? (
                      <AudioCallSDK
                        childRef={childAudioRef}
                        myId={displayChat?.userId?._id}
                        events={childAudioEvents}
                        recipientId={displayChat?.doctorId?._id}
                        meetingId={displayChat?.videoMeetingDetails?.meetingId}
                        videoToken={displayChat?.videoMeetingDetails?.token}
                        participantImageUrl={
                          displayChat?.doctorId?.profilePicture
                        }
                        imageUrl={displayChat?.userId?.image}
                        countDownHasCompleted={countDownHasCompleted}
                        setOpenedMedia={setOpenedMedia}
                      />
                    ) : (
                      <FiPhoneOff size="1.4em" color="#403058" />
                    )}
                  </Box>
                </Flex>
              </Stack>
            </Stack>
          </Box>
          <Divider my="10px" />
          <style>
            {`
              /* Hide scrollbar for Chrome, Safari and Opera */
            .chat-container::-webkit-scrollbar {
              display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .chat-container {
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
}
              `}
          </style>
          <Stack
            ref={(ref) => ref && setChatBoxRef(ref)}
            width="100%"
            pb={5}
            maxH={"55vh"}
            overflow="auto"
            className="chat-container"
          >
            {messages?.map((message) => {
              let isReply = message?.sender === "Doctors";
              return (
                <>
                  <Flex
                    ref={messagesEnd}
                    alignItems={"center"}
                    flexDirection={isReply ? "row" : "row-reverse"}
                    justifyContent={isReply ? "start" : "end"}
                    width={"100%"}
                  >
                    <Box>
                      {/* <Avatar textColor={"white"} src={""} name={`${displayChat?.firstName} ${displayChat?.lastName}`} alt={`Picture of ${displayChat?.firstName}`} /> */}
                      <Text color="#757575" fontWeight={"bold"}>
                        {message?.createdAt &&
                          dayjs(message?.createdAt).format("hh:mm a")}
                      </Text>
                    </Box>
                    <Box
                      bg={isReply ? "#EBE1FC" : "white"}
                      p={5}
                      mx={3}
                      color="#757575"
                      borderRadius={"25px"}
                      borderTopRightRadius={!isReply && "0px"}
                      borderTopLeftRadius={isReply && "0px"}
                    >
                      {message?.file || message?.attachment ? (
                        <img
                          style={{
                            objectFit: "contain",
                            width: "200px",
                            maxHeight: "200px",
                            height: "200px",
                            maxWidth: "200px",
                          }}
                          src={message?.file || message?.attachment}
                          alt="chat_"
                        />
                      ) : (
                        <Text
                          color="#000000ad"
                          fontWeight={"bold"}
                          fontSize={"1.1em"}
                        >
                          {message?.message}{" "}
                        </Text>
                      )}
                    </Box>
                  </Flex>
                  {/* <div ></div> */}
                </>
              );
            })}
          </Stack>
          <Box position={"absolute"} bottom={0} right={0} left={0}>
            {
              // isLocked
              displayChat?.isLocked ? (
                <HStack
                  border={"1px solid #EBE1FC"}
                  py="20px"
                  justifyContent={"center"}
                  // onClick={() => setRatingModal(true)}
                >
                  <Text fontWeight="bold" fontSize="1.2em" color="#8C78AB">
                    {" "}
                    You can't reply to this chat, you can only chat when you
                    have an active appointment{" "}
                  </Text>
                </HStack>
              ) : //open input message if chat is unlock and there a countdown timer in the local storage
              displayChat?.isLocked === false && shouldStartTimer() ? (
                <TypeMessage
                  startTimer={startTimer}
                  setStartTimer={setStartTimer}
                  conversationId={conversationId}
                  doctor={displayChat}
                  sendMessage={(e) => {
                    setUpdateScroll({});
                    sendMessage(e);
                  }}
                />
              ) : (
                <HStack
                  border={"1px solid #EBE1FC"}
                  py="20px"
                  justifyContent={"center"}
                >
                  <Text fontWeight="bold" fontSize="1.2em" color="#8C78AB">
                    You can't reply to this chat, you can only chat when you
                    have an active appointment
                  </Text>
                </HStack>
              )
            }
          </Box>
        </Box>
      ) : (
        <Stack
          justifyContent={"center"}
          bg="#F6F4F9"
          borderRadius={"10px"}
          h={["100%", "100%", "80vh"]}
          fontSize={"12px"}
          p={["20px 10px", "20px 20px", "20px 30px"]}
          position={["", "", "relative"]}
        >
          <Text textAlign={"center"}> No User Selected </Text>
        </Stack>
      )}

      <RateModal
        open={ratingModal}
        key={displayChat?.doctorId?._id || displayChat?._id}
        close={() => setRatingModal(false)}
        doctorId={displayChat?.doctorId?._id || displayChat?._id}
      />
    </>
  );
};
