import { Stack, Input, Box, Text, Button } from '@chakra-ui/react'
import { UpdateProfileHook } from './UpdateProfileHook'
import { useContext, useState } from 'react'
import { UserProfileContext } from '../../../context/UserContext'

export const ChangePhoneNumberForm = ({ refresh }) => {
  const { userProfile } = useContext(UserProfileContext) || {}
  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(()=>userProfile?.phoneNumber)
  const [confirmNewPhoneNumber, setConfirmNewPhoneNumber] = useState('')
  // const [disabled] = useState(true)

  const formTitleStyle = {
    fontSize: '.9em',
  }
  const formInputStyle = {}

  const { updatePhoneNumberInfo } = UpdateProfileHook()

  const updateInfo = () => {
    const payload = {
      oldNumber: currentPhoneNumber,
      newNumber: newPhoneNumber,
      confirmNewNumber: confirmNewPhoneNumber,
    }
    updatePhoneNumberInfo(payload).then(() => refresh())
  }

  // useEffect(() => {}, [confirmNewPhoneNumber, currentPhoneNumber])

  return (
    <Box bg='#fff' p='50px'>
      <Stack spacing='30px' maxW='500px'>
        <Stack direction='row'>
          <Box>
            <Text {...formTitleStyle}>Current Phone Number</Text>
            <Input
              onChange={(e) => setCurrentPhoneNumber(e.target.value)}
              type='text'
              value={currentPhoneNumber}
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>New Phone Number</Text>
            <Input
             autoComplete='off'
             _autofill={'off'}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
              type='text'
              value={newPhoneNumber}
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction='row'>
          <Box>
            <Text {...formTitleStyle}>Confirm New Phone Number</Text>
            <Input
            autoComplete={false}
            autoSave={'off'}
            _autofill={'off'}
              onChange={(e) => setConfirmNewPhoneNumber(e.target.value)}
              value={confirmNewPhoneNumber}
              type='text'
              {...formInputStyle}
            />
          </Box>
        </Stack>

        <Button
          _hover={{ bg: '#5C2BA8' }}
          bg='#5C2BA8'
          color='#fff'
          onClick={updateInfo}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  )
}
