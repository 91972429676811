import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { BsArrowRight, BsPerson } from "react-icons/bs";
import { HiOutlineClipboardList } from "react-icons/hi";
import { Link } from "react-router-dom";
// import videoImg from "../images/image.png"

export const HealthCards = ({
  title,
  duration,
  totalViews,
  videoImg,
  id,
  ...rest
}) => {
  return (
    <Box
      width={"100%"}
      p={5}
      bg="#fff"
      shadow="md"
      borderWidth="1px"
      borderRadius={"20px"}
      className="box-info"
      {...rest}
      mb="15px"
    >
      <Image
        objectFit={"cover"}
        src={videoImg}
        width="100%"
        height="200px"
        borderRadius="20px"
      />
      <Tooltip label={title}>
        <Heading
          fontFamily="Gilroy-Bold"
          color={"#707070"}
          my="20px"
          width="200px"
          isTruncated
        >
          {title}
        </Heading>
      </Tooltip>
      <Flex width={"100%"} justifyContent={"space-between"} mb="20px">
        <Flex width={"50%"} alignItems="center">
          <HiOutlineClipboardList color="#09B5A9" size={22} />
          <Text fontSize={"14px"} ml="5px" mt="3px">
            {duration}
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <BsPerson color="#09B5A9" size={22} />
          <Text fontSize={"14px"} ml="5px" mt="3px">
            {totalViews} Views
          </Text>
        </Flex>
      </Flex>

      <Link to={`/health-videos/${id}`} ex>
        <Button
          borderRadius={"20px"}
          color={"#fff"}
          bg="#200960"
          _hover={{ bg: "#200960" }}
          mt="10px"
          rightIcon={<BsArrowRight color="#09B5A9" />}
        >
          Watch Video
        </Button>
      </Link>
    </Box>
  );
};
