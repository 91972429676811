import React, { useContext, useEffect, useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

import { UserCard } from "../dashboard/components/Usercard";
import { CreditCard } from "./components/CreditCard";
import { CreditUsed } from "./components/CreditUsed";
import { CreditSubscription } from "./components/CreditSubscription";
import { PaginatedTable } from "../../components/PaginatedTable";
import { TableBody } from "./components/TableBody";
import { tableHead } from "./components/TableHead";
// import { fakeData } from './components/fakeData'
import { UserProfileContext } from "../../context/UserContext";
import {
  getCreditPurchased,
  getCreditUsed,
  getSubscriptions,
} from "./services/index";
// import { errorNotifier } from "../../components/NotificationHandler";
import { getUserInfomation } from "../profile/services/updateUser";
import dayjs from "dayjs";
import FullPageLoader from "../../components/fullPageLoader";

const currentYear = dayjs().format("YYYY");
const currentMonth = dayjs().format("M");

export const Subscription = () => {
  const [creditPurchased, setCreditPurchased] = useState([]);
  const [creditUsed, setCreditUsed] = useState([]);
  // const [creditUsed, setCreditUsed] = useState({})
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState({ history: false });
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const [creditUsedYear, setCreditUsedYear] = useState(currentYear);
  const [creditUsedMonth, setCreditUsedMonth] = useState(currentMonth);

  const [userInformation, setUserInformation] = useState([]);
  const [refresh, setRefresh] = useState({});

  const userProfileData = useContext(UserProfileContext);
  const { userProfile } = userProfileData || {};
  const { _id } = userProfile || {};

  useEffect(() => {
    getUserInfomation(null, _id, setUserInformation);
  }, [refresh, _id]);

  useEffect(() => {
    getCreditPurchased({ month, year })
      .then((data) => {
        setCreditPurchased({ ...data });
      })
      .catch((e) => null);
  }, [month, year, refresh]);

  useEffect(() => {
    getCreditUsed({ creditUsedMonth, creditUsedYear })
      .then((data) => {
        setCreditUsed({ ...data });
      })
      .catch((e) => null);
  }, [creditUsedMonth, creditUsedYear, refresh]);

  useEffect(() => {
    setLoading(true);
    getSubscriptions()
      .then((data) => {
        setMonth((val) => val);
        setSubscriptions({ ...data });
      })
      .catch((e) => null)
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);

  return (
    <Box>
      <Flex
        mt="25px"
        gap={{ base: "10px", md: "15px", lg: "20px" }}
        direction={{ base: "column", lg: "row" }}
      >
        <Box width={{ base: "100%", lg: "50%" }}>
          <UserCard user={userInformation} />
        </Box>
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "10px", md: "15px", lg: "20px" }}
          width={{ base: "100%", lg: "50%" }}
          justifyContent="space-between"
        >
          <Box width={{ base: "100%", md: "50%" }}>
            <CreditCard
              setYear={setYear}
              setMonth={setMonth}
              value={creditPurchased}
              description={"Credit Purchased"}
            />
          </Box>
          <Box width={{ base: "100%", md: "50%" }}>
            <CreditUsed
              setYear={setCreditUsedYear}
              setMonth={setCreditUsedMonth}
              value={creditUsed}
              description={"Credit Used"}
            />
          </Box>
        </Flex>
        {/* <Analytics /> */}
      </Flex>
      <Box my="40px">
        <CreditSubscription
          update={() => setRefresh({})}
          user={userInformation}
          refresh={() => setRefresh({})}
        />
      </Box>
      <Box>
        <Flex
          justifyContent={"space-between"}
          px={["10px", "10px", "10px"]}
          my="20px"
        >
          <Text
            color="#2A2047"
            mt="20px"
            fontSize={"1.2em"}
            fontWeight="bold"
            fontFamily="Gilroy-Bold"
          >
            Purchase History
          </Text>
        </Flex>

        {loading ? (
          <FullPageLoader />
        ) : (
          <PaginatedTable
            columns={tableHead}
            bg="#f6f4f9"
            total={subscriptions?.totalSubscriptions}
            length={subscriptions?.totalSubscriptions}
            data={<TableBody getData={subscriptions?.subscriptions} />}
            updateTable={async (page) => {
              getSubscriptions(page)
                .then((data) => {
                  setMonth((val) => val);
                  setSubscriptions({ ...data });
                })
                .catch((e) => null);
            }}
          />
        )}
        {/* <Payment /> */}
      </Box>
    </Box>
  );
};
