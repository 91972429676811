import { Box } from "@chakra-ui/react";
import React from "react";
import { ChatsComponents } from "./Chats";

export const ChatFeedSection = ({
  file,
  setFile,
  displayChat,
  refresh,
  setDisplayOrHideChatFeedForSmScreen,
  displayOrHideChatFeedForSmScreen,
  ...props
}) => {
  return (
    <Box>
      <ChatsComponents
        refresh={refresh}
        displayChat={displayChat}
        setDisplayOrHideChatFeedForSmScreen={
          setDisplayOrHideChatFeedForSmScreen
        }
        displayOrHideChatFeedForSmScreen={displayOrHideChatFeedForSmScreen}
        file={file}
        setFile={setFile}
        {...props}
      />
    </Box>
  );
};
