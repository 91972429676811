import React, { useContext } from "react";
import { Box, Text, Flex, Divider, Stack } from "@chakra-ui/react";
import { UserCard } from "./components/Usercard";
import { Payment } from "./components/Payment";
import { PaginatedTable } from "../../components/PaginatedTable";
import { GiftCard } from "./components/GiftCard";
import { TestCard } from "./components/TestCard";
import { SubscriptionCard } from "./components/SubscriptionCard";
import { Link } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import { useEffect, useState } from "react";
import { getUserInfomation } from "../profile/services/updateUser";

export const Dashboard = () => {
  const [userInformation, setUserInformation] = useState([]);
  const [refresh, setRefresh] = useState({});
  const userType = JSON.parse(sessionStorage.getItem("!!OPQ1"));

  const userProfileData = useContext(UserProfileContext);
  const { userProfile } = userProfileData || {};
  const { _id } = userProfile || {};

  useEffect(() => {
    if (!_id) return;
    getUserInfomation(null, _id, setUserInformation);
  }, [refresh, _id]);

  return (
    <Box>
      <Stack
        spacing="20px"
        direction={`${userType?.client === "Staff" ? "row" : "column"}`}
      >
        <Stack
          spacing={"20px"}
          direction={["column", "column", "row"]}
          px={["10px", "10px", "0px"]}
          w="100%"
        >
          <UserCard user={userInformation} flex="1" />

          {userType?.client !== "Staff" && (
            <Box flex="1" w="100%">
              <GiftCard
                refresh={() => setRefresh({})}
                userDetails={userInformation}
              />
            </Box>
          )}
        </Stack>
        <Divider
          orientation="horizontal"
          my="20px"
          display={["block", "block", "none"]}
        />
        <Stack
          direction={{ base: "column", md: "row" }}
          gap="25px"
          mt="25px"
          w="100%"
        >
          <Box w={"100%"} flex="1">
            <TestCard
              refresh={() => refresh({})}
              test={userInformation?.tests?.[0]}
            />
          </Box>
          {userType?.client !== "Staff" && (
            <Box flex="1">
              <SubscriptionCard
                refresh={() => refresh({})}
                userDetails={userInformation}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <Divider
        orientation="horizontal"
        mt="20px"
        display={["block", "block", "none"]}
      />
      <Box>
        <Flex
          justifyContent={"space-between"}
          px={["10px", "10px", "10px"]}
          my="20px"
        >
          <Text
            fontFamily="Gilroy-Bold"
            mt="20px"
            fontSize="1.2em"
            fontWeight="bold"
          >
            Test History
          </Text>
          <Link to="/history">
            <Text mt="18px" fontWeight="normal" color="#9E8FB5">
              More Test Result
            </Text>
          </Link>
        </Flex>

        <PaginatedTable
          columns={[]}
          bg="#EFEDF3"
          noPagination={true}
          data={<Payment />}
        />
      </Box>
    </Box>
  );
};
