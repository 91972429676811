import { Box } from '@chakra-ui/react'

export const CurveBackgroundColor = ({
  smallCurve,
  bg,
  children,
  ...props
}) => {
  return (
    <Box
      borderRadius={smallCurve ? '10px' : '30px'}
      p='20px'
      bg={bg || '#F6F4F9'}
      {...props}
    >
      {children}
    </Box>
  )
}
