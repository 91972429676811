import JoinScreen from "./components/JoinScreen";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import React, { useState } from "react";
import { Container } from "./components/Container";

function VideoCallSDK({
  childRef,
  myId,
  recipientId,
  events,
  countDownHasCompleted,
  meetingId,
  videoToken,
  setOpenedMedia,
}) {
  const [isReady, setIsReady] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);

  return meetingId && isReady ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: micOn,
        webcamEnabled: webcamOn,
        name: "patient",
      }}
      reinitialiseMeetingOnConfigChange={true}
      token={videoToken}
    >
      <Container
        ref={childRef}
        events={events}
        setWebcamOn={setWebcamOn}
        setMicOn={setMicOn}
        onMeetingLeave={() => {
          setWebcamOn(false);
          setMicOn(false);
          setIsReady(false);
        }}
        meetingId={meetingId}
        setIsReady={setIsReady}
        countDownHasCompleted={countDownHasCompleted}
      />
    </MeetingProvider>
  ) : (
    <>
      <JoinScreen
        myId={myId}
        recipientId={recipientId}
        events={events}
        setIsReady={(e) => {
          setOpenedMedia("video");
          setIsReady(e);
          setMicOn(true);
          setWebcamOn(true);
        }}
      />
    </>
  );
}

export default VideoCallSDK;
