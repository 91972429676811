import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../service/loginUser";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import playStoreBtn from "../../../assets/images/google-play-badge.png";
import ChooseAccountModal from "./ChooseAccountModal";
import ResendToken from "./ResendToken";
import GoogleSignin from "./GoogleSignin";
import AppleLogin from "react-apple-login";

export const LoginForm = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  const [openChooseAccModal, setOpenChooseAccModal] = useState(false);
  const [accountModalData, setAccountModalData] = useState({});
  const [hasNotBeenVerified, setHasNotBeenVerified] = useState({
    bool: false,
    data: {},
  });

  const handleClick = () => setShow(!show);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await loginUser({ email, password }, setLoading).then((data) => {
      const token = data?.token;
      if (token) {
        sessionStorage.setItem("HBU#221#", token);
        window.location.reload();
      } else if (data?.enterpriseDetails) {
        setAccountModalData({ ...data, email });
        setOpenChooseAccModal(true);
      } else if (data?.emailVerification === false) {
        setHasNotBeenVerified({ bool: true, data });
        return;
      } else {
        return;
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!email || !password) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [email, password]);

  return hasNotBeenVerified?.bool ? (
    <ResendToken id={hasNotBeenVerified?.data?._id} />
  ) : (
    <Box>
      <ChooseAccountModal
        data={accountModalData}
        open={openChooseAccModal}
        onClose={() => setOpenChooseAccModal(false)}
      />
      <Box width={"100%"} p={["20px", "100px", "100px"]}>
        <Heading
          fontFamily="Gilroy-Bold"
          mb="10px"
          color="#2A2047"
          fontSize="46px"
          lineHeight={1.1}
        >
          Welcome <br />
          <Text style={{ fontWeight: "bold" }}>Back</Text>
        </Heading>
        <Box>
          <form onSubmit={handleLogin}>
            <Text mb="20px">Enter your login details to begin</Text>
            <FormControl>
              <FormLabel htmlFor="email" color="#929292">
                Email
              </FormLabel>
              <Input
                height="50px"
                borderRadius={"10px"}
                placeholder="Enter Your Email"
                // type={show ? "text" : "email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl my="20px">
              <FormLabel htmlFor="password" color="#929292">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  height="50px"
                  borderRadius={"10px"}
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement
                  top={"auto"}
                  children={
                    <span
                      style={{ cursor: "pointer", padding: "5px" }}
                      onClick={handleClick}
                    >
                      {show ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  }
                />
              </InputGroup>
            </FormControl>
            <Button
              mt="20px"
              bg="#2A2047"
              color="#fff"
              borderRadius={"10px"}
              type="submit"
              width="100%"
              height="50px"
              isLoading={loading}
              disabled={disabled}
              _hover={{ bg: "#5C2BA8" }}
            >
              Login
            </Button>
          </form>
        </Box>
        <br />
        <GoogleSignin
          setAccountModalData={setAccountModalData}
          setHasNotBeenVerified={setHasNotBeenVerified}
          setOpenChooseAccModal={setOpenChooseAccModal}
        />

        {/* <AppleLogin
          clientId="com.howbodi.application2"
          redirectURI="https://app.howbodi.io/apple-sign-in"
          usePopup={true}
          // callback={this.appleResponse} // Catch the response
          scope="email name"
          responseMode="query"
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "white",
                padding: 10,
                border: "1px solid black",
                fontFamily: "none",
                lineHeight: "25px",
                fontSize: "25px",
              }}
            >
              <i className="fa-brands fa-apple px-2 "></i>
              Continue with Apple
            </button>
          )}
        /> */}

        <Text mt="15px">
          <Link
            to="/forgot-password"
            style={{ color: "#ED819C", fontSize: ".9em" }}
          >
            Forgot password? Click here
          </Link>
        </Text>
        <Text mt="15px">
          Dont have an account?{" "}
          <Link to="/signup" style={{ color: "#2A2047", fontSize: ".9em" }}>
            Sign Up
          </Link>
        </Text>

        <Stack
          mt="20px"
          spacing="2px"
          cursor={"pointer"}
          onClick={() =>
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.howbodi_mobile")
          }
        >
          <Text color="#7e7e7e" textAlign="center" fontSize=".9em">
            Download the App{" "}
          </Text>
          <Box display="flex" justifyContent="center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps"
            >
              <Image width="190px" src={playStoreBtn} alt="google play store" />
            </a>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
