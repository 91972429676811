// import { errorNotifier } from '../../../components/NotificationHandler'
import http, { AUTH_ROUTES } from "../../../services/api";

export const getPatientTests = async (setPatientTests) => {
  try {
    const {
      data: {
        test: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_PATIENT_TESTS);

    setPatientTests(data?.tests);
  } catch (e) {
    // setPatientTests([])g
  }
};

export const addTests = async (payload) => {
  try {
    const data = await http.post(AUTH_ROUTES.ADD_TESTS, payload);
    const {
      data: {
        test: { data: res },
      },
    } = data;
    return res;
  } catch (e) {
    throw new Error();
  }
};
export const getAllTests = async (setAllTests) => {
  try {
    const res = await http.get(AUTH_ROUTES.GET_TEST_NAMES);
    setAllTests(res?.data?.test?.data?.tests);
  } catch (e) {}
};
