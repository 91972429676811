import { Stack, Input, Box, Text, Button } from "@chakra-ui/react";
import { UpdateProfileHook } from "./UpdateProfileHook";
import { useContext, useState } from "react";
import { UserProfileContext } from "../../../context/UserContext";

export const HealthInfoForm = ({ refresh }) => {
  const { userProfile } = useContext(UserProfileContext) || {};
  const [bloodGroup, setBloodGroup] = useState(() => userProfile?.bloodGroup);
  const [genotype, setGenoType] = useState(() => userProfile?.genotype);
  const [disabilities, setDisabilities] = useState(
    () => userProfile?.disabilities?.[0]
  );
  const [allergies, setAllergies] = useState(() => userProfile?.allergies?.[0]);
  // const [loading, setLoading] = useState(false)

  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};

  const { updateHealthInfo } = UpdateProfileHook();

  const updateInfo = async () => {
    // setLoading(true)
    const payload = {
      ...(bloodGroup && { bloodGroup }),
      ...(genotype && { genotype }),
      ...(disabilities?.length > 0 && { disabilities: [disabilities] }),
      ...(allergies?.length > 0 && { allergies: [allergies] }),
    };
    updateHealthInfo(payload).then(() => refresh());
  };

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Blood group</Text>
            <Input
              onChange={(e) => setBloodGroup(e.target.value)}
              value={bloodGroup}
              type="text"
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>Genotype</Text>
            <Input
              onChange={(e) => setGenoType(e.target.value)}
              value={genotype}
              type="text"
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Disabilities</Text>
            <Input
              onChange={(e) => setDisabilities(e.target.value)}
              value={disabilities}
              type="text"
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>Allergies</Text>
            <Input
              onChange={(e) => setAllergies(e.target.value)}
              value={allergies}
              type="text"
              {...formInputStyle}
            />
          </Box>
        </Stack>

        <Button
          bg="#5C2BA8"
          color="#fff"
          onClick={updateInfo}
          _hover={{ bg: "#5C2BA8" }}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
};
