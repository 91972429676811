import { Box, Flex } from "@chakra-ui/react"
import { HealthCards } from "./HealthCards"

export const VideoRows = ({ videos }) => {
  return (
    <Flex direction='row' wrap={"wrap"} justifyContent="">

       {
       videos?.map(video => {
        return(
          <Box width="24%" key={video?._id} mr="10px">
            <HealthCards
              title={video?.videoTitle}
              duration={video?.duration}
              totalViews={video?.totalViews}
              videoImg={video?.image}
              id={video?._id}
            />
          </Box>
        )
       })
       
      }
    </Flex>
  )
}