import { Stack, Text } from '@chakra-ui/react'
import { SettingsAccordion } from './SettingsAccordion'

export const UserProfileSettings = ({ refresh }) => {
  return (
    <Stack spacing='30px'>
      <Text>
        <b>Settings</b>
      </Text>
      <SettingsAccordion refresh={refresh} />
    </Stack>
  )
}
