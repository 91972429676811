// import { errorNotifier } from '../../../components/NotificationHandler'
import { DATA_ROWS } from "../../../app/constants";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getCreditPurchased = async (filter) => {
  const resp = await http.get(
    AUTH_ROUTES.GET_CREDIT_PURCHASED(filter.month, filter.year)
  );
  return resp.data?.analytics?.data?.creditsPurchased || {};
};

export const getCreditUsed = async (filter) => {
  const resp = await http.get(
    AUTH_ROUTES.GET_CREDIT_USED(filter.creditUsedMonth, filter.creditUsedYear)
  );
  return resp.data?.analytics?.data?.creditUsed || {};
};

export const getSubscriptionList = async () => {
  const resp = await http.get(AUTH_ROUTES.GET_SUBSCRIPTION_LIST);
  // need to cache response
  return resp.data?.data?.subscriptionSettings;
};

export const getSubscriptions = async (skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };
  const resp = await http.get(AUTH_ROUTES.GET_SUBSCRIPTIONS(params));
  return resp.data?.subscription?.data;
};

export const createSubscription = async (data) => {
  const resp = await http.post(AUTH_ROUTES.CREATE_SUBSCRIPTIONS, data);
  // need to cache response
  return resp.data?.subscription?.data;
};

export const verifySubscription = async (data) => {
  const resp = await http.post(AUTH_ROUTES.VERIFY_SUBSCRIPTIONS, data);
  // need to cache response
  return resp.data?.subscription;
};
