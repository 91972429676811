import "./index.css";
import {
  Box,
  Text,
  Flex,
  Avatar,
  Stack,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
// import dayjs from "dayjs"
import { RiMenu2Fill } from "react-icons/ri";
import { BsBell } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getComponentTitle } from "./components/GetComponentTitle";
import { UserProfileContext } from "../../context/UserContext";
import { getUserInfomation } from "../../pages/profile/services/updateUser";
import { socketClient } from "../../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationData } from "../../store/actions/notificationAction";
import { getDoctorDetails } from "./service";
import IncomingNewNotificationDetails from "./components/IncomingNewNotificationDetails";
import clickSound from "../../assets/sound/click.mp3";
// import { customSuccessNotifier } from "../NotificationHandler";
// import { FaTimes } from "react-icons/fa";
import howbodiLogo from "../../assets/icons/howbodi_logo.svg";
import {
  getIncomingCallData,
  clearCallState,
} from "../../store/actions/incomingCallAction";
import IncomingCallPopup from "./components/IncomingCallPopup";
import ringSound from "../../assets/sound/phone-ringing-7006.mp3";

export const Navigation = ({ toggle, showSidebar }) => {
  const [toggleSide] = useState(false);
  const [userInformation, setUserInformation] = useState([]);
  const { image, firstName, lastName } = userInformation || {};
  // const [notifications, setNotifications] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const profileDetails = useContext(UserProfileContext);
  const { userProfile } = profileDetails || {};
  const { _id } = userProfile || {};

  const location = useLocation();
  const pathname = location.pathname;

  const [newIncomingNotification, setNewIncomingNotification] = useState(false);
  const [
    showNewIncomingNotificationDetails,
    setShowNewIncomingNotificationDetails,
  ] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState({});

  const { incomingCall, ring } =
    useSelector((state) => state?.getIncomingCallData) || {};

  const { userType } = useSelector((state) => state?.auth);

  const handleToggle = () => {
    toggle(!toggleSide);
  };
  useEffect(() => {
    getUserInfomation(null, _id, setUserInformation);
  }, [_id]);

  const [callDataObject, setCallDataObject] = useState({});
  const [stop, setStop] = useState(true);

  //these is listening for incoming calls
  useEffect(() => {
    socketClient.on("ring", (data) => {
      const { calltype } = data || {};
      setCallDataObject(data);
      setStop(false);
      dispatch(getIncomingCallData({ incomingCall: true, callType: calltype }));
    });
    //eslint-disable-next-line
  }, []);

  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    socketClient.on("booking-notification", (data) => {
      const { doctorId } = data?._doc || {};
      getDoctorDetails(doctorId)
        .then((result) => {
          //play sound when a new notification comes in
          const audio = new Audio(clickSound);
          audio?.play();

          const response = result?.data?.doctorDetails?.data?.doctors?.[0];

          const payload = {
            doctorId: response,
            _id: data?._doc?._id, //booking id,
            status: data?._doc?.status,
            eventMessage: data?._doc?.eventMessage,
            eventMessageData: { ...data?._doc },
          };
          dispatch(setNotificationData(payload));

          // const func = ({ onClose }) => {
          //   return (
          //     <Stack
          //       color="white"
          //       p={3}
          //       bg="#303030"
          //       borderRadius={"5px"}
          //       position="relative"
          //     >
          //       <FaTimes
          //         cursor={"pointer"}
          //         onClick={() => onClose()}
          //         style={{ position: "absolute", right: "10px" }}
          //       />

          //       <Text fontWeight="bold" fontSize={".8em"}>
          //         {data?.message}
          //       </Text>
          //       <Text fontSize=".7em">
          //         Booking {data?._doc?.status?.toLowerCase()}
          //       </Text>
          //       {/* <Stack direction={"row"} alignItems="center">
          //     <Text
          //       cursor="pointer"
          //       fontSize=".7em"
          //       fontWeight={"bold"}
          //       onClick={() => {
          //         goToSchedules(onClose);
          //       }}
          //     >
          //       Go to schedules
          //     </Text>
          //     <BsArrowRight />
          //   </Stack> */}
          //     </Stack>
          //   );
          // };

          if (toastIdRef.current) {
            toast.update(toastIdRef.current, {
              title: `${data?.message}`,
              description: `Booking ${data?._doc?.status?.toLowerCase()}`,
              variant: "subtle",
              status: "info",
              duration: null,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toastIdRef.current = toast({
              title: `${data?.message}`,
              description: `Booking ${data?._doc?.status?.toLowerCase()}`,
              variant: "subtle",
              status: "info",
              duration: null,
              isClosable: true,
              position: "top-right",
            });
          }
        })
        .catch(() => {});
    });
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    socketClient.on("in-app-notification", (data) => {
      //play sound when a new notification comes in
      const audio = new Audio(clickSound);
      audio?.play();

      setNewIncomingNotification(true);
      setNotificationDetails(data);
    });
  }, []);

  //ring when theres incoming call
  const [audio] = useState(new Audio(ringSound));
  useEffect(() => {
    if (!incomingCall?.incomingCall) return;
    audio.loop = true;
    audio?.play();
    //eslint-disable-next-line
  }, [incomingCall?.incomingCall]);

  useEffect(() => {
    if (ring === false) {
      audio.pause();
      audio.currentTime = 0;
    }
    //eslint-disable-next-line
  }, [ring]);

  const timer = useRef(null);
  //after x minutes of ringing, and no one answers the call, close it
  useEffect(() => {
    if (stop === false) {
      timer.current = setTimeout(() => {
        // if (stop === false) {
        socketClient.emit("end-call", {
          ...callDataObject,
          endType: "missed",
          userType,
        });
        dispatch(clearCallState());
        setStop(true);
        // }
      }, 30000); //30 seconds in milisecond
    }
    //eslint-disable-next-line
  }, [stop]);

  useEffect(() => {
    if (!timer?.current) return;

    if (stop === true) {
      clearInterval(timer?.current);
    }
  }, [stop]);

  return (
    <Box position="relative">
      {incomingCall?.incomingCall && (
        <IncomingCallPopup
          hasClicked={() => setStop(true)}
          callDataObject={callDataObject}
          incomingCall={incomingCall}
        />
      )}
      <Box
        className="navigation-inner"
        fontSize={{ sm: ".9em", md: ".9em", lg: "1em" }}
        px="20px"
        bg="#EFEDF3"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Flex
            className="brand-style"
            justifyContent={"center"}
            alignItems="center"
          >
            <Flex>
              <Box className="toggle-side-bar" mr="10px">
                {showSidebar && (
                  <RiMenu2Fill
                    color="#000"
                    onClick={handleToggle}
                    size={30}
                    style={{ cursor: "pointer", paddingLeft: "10px" }}
                  />
                )}
              </Box>
            </Flex>
            <Box mr="40px">
              <Image src={howbodiLogo} alt="logo" />
            </Box>
            <Text
              fontFamily="Gilroy-Bold"
              color="#403058"
              fontSize={[".9em", "30px"]}
            >
              {getComponentTitle(pathname)}
            </Text>
          </Flex>

          <Stack
            direction="row"
            justifyContent={["flex-end"]}
            alignItems="center"
          >
            <Box
              mr="5%"
              cursor="pointer"
              position="relative"
              border={"1px solid #DFD2F5"}
              borderRadius="50%"
              padding="6px"
            >
              <Box
                onClick={() => {
                  setNewIncomingNotification(false);
                  setShowNewIncomingNotificationDetails(true);
                }}
              >
                {newIncomingNotification && (
                  <Box
                    bg="#09B5A9"
                    width="8px"
                    height={"8px"}
                    borderRadius="50%"
                    position={"absolute"}
                    right="5px"
                  />
                )}
                <BsBell size={"1.1em"} color={"#403058"} />
              </Box>

              <IncomingNewNotificationDetails
                details={notificationDetails}
                show={showNewIncomingNotificationDetails}
                onCloseNotification={() => {
                  setShowNewIncomingNotificationDetails(false);
                  setNotificationDetails({});
                }}
              />
            </Box>
            <Box mr="5%">
              <Avatar
                bg={"#403058"}
                name={`${firstName} ${lastName}`}
                src={image}
                onClick={() => history.push("/profile")}
                color="#fff"
                cursor="pointer"
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
