import {
  deleteNotificationData,
  setNotificationData,
} from "../actions/notificationAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * @return {*}
 *
 *
 */

function notificationReducer(state = {}, action) {
  switch (action.type) {
    case setNotificationData.type:
      return {
        ...state,
        incomingNotification: action.payload,
      };
    case deleteNotificationData.type:
      return {};
    default:
      return state;
  }
}

export default notificationReducer;
