import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { LinkTo } from "../../nav/components/LinkTo";
import "./index.css";
import { ADMIN_NAV_ITEMS } from "./components/sidebar.constatnt";
import { useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { logUserOut } from "../../../pages/profile/components/UserProfileSummary";

const SideBar = ({ toggle }) => {
  const [activeIconId, setActiveIconId] = useState("");
  return (
    <Box bg="#EFEDF3" mt={["110px"]}>
      <Box className="sidebar">
        <Box className="sidebar-inner">
          <Flex flex={1} flexDirection="column">
            {ADMIN_NAV_ITEMS?.map(({ title, to, icon: Icon }, id) => (
              <LinkTo
                key={id}
                to={to}
                title={title}
                toggle={toggle}
                id={id}
                iconComponent={Icon}
                activeIconId={activeIconId}
                setActiveIconId={setActiveIconId}
              />
            ))}
            <Box className="link" flex=".8">
              <Stack
                cursor="pointer"
                onClick={logUserOut}
                direction="row"
                alignItems="center"
                spacing="20px"
              >
                <BiLogOut fontSize="1.5em" color={"#8C78AB"} />
                <Text color="#929292">Log out</Text>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
export default SideBar;
