import React from "react";
import { Switch, Route } from "react-router-dom";
import { Login } from "../pages/Login";
import { ForgotPassword } from "../pages/forgotPassword";
import { PUBLIC_PATHS } from "./constants";
import { Signup } from "../pages/signup/Index";
import VerifyEmail from "../pages/verifyEmail";

export default function UnAuthenticatedApp() {
  const { LOGIN, FORGOT_PASSWORD, SIGNUP, VERIFY_EMAIL } = PUBLIC_PATHS;

  return (
    <Switch>
      <Route path={LOGIN} exact component={Login} />
      <Route path={VERIFY_EMAIL} exact component={VerifyEmail} />
      <Route path={SIGNUP} exact component={Signup} />
      <Route path={FORGOT_PASSWORD} exact component={ForgotPassword} />
      <Route path="/*" component={Login} />
    </Switch>
  );
}
