import React, { useEffect, useState } from "react";
import { Text, Tr, Td, Box } from "@chakra-ui/react";
// import { BiDotsHorizontalRounded } from "react-icons/bi"
import { Link } from "react-router-dom";
import { getHistory } from "../../history/services";
import FullPageLoader from "../../../components/fullPageLoader";

export const Payment = () => {
  const [loading, setLoading] = useState(false);
  const [testHistory, setTestHistory] = useState([]);

  useEffect(() => {
    setLoading(true);
    let skip = 0;
    let limit = 5;
    getHistory(null, skip, limit)
      .then((data) => {
        setTestHistory(data?.tests);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <FullPageLoader />
      ) : testHistory?.length < 1 ? (
        <Box p={5}>
          <Text textAlign={"center"} color="gray.500" fontSize={"1.5em"}>
            {" "}
            No Test Taken
          </Text>
        </Box>
      ) : (
        testHistory?.slice?.(0, 5)?.map?.((data, i) => {
          return (
            <Tr
              key={i}
              bg="#fff"
              color="#686278"
              borderBottom="10px solid #efedf3"
              fontWeight="semibold"
            >
              <Td>{i + 1}</Td>
              <Td textTransform={"capitalize"}>{data?.testName}</Td>

              <Td color="#BBBBBB">
                {typeof data?.result === "object"
                  ? Number(data?.result?.result).toFixed(0)
                  : Number(data?.result).toFixed(0)}
              </Td>
              <Td
                width="150px"
                color={"#5C2BA8"}
                borderRadius={"5px"}
                p="5px 8px"
              >
                <Link to={`/take-test/${data?.testName}`}>Retake Test</Link>
              </Td>
            </Tr>
          );
        })
      )}
    </>
  );
};
