import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
} from '@chakra-ui/react'
import { UserInformationsTabs } from './UserInformationsTab'
import { UserMoreInformationTab } from './UserMoreInfomationTab'
import { UserProfileSummary } from './UserProfileSummary'

export const UserInformationComponent = ({ data, refresh }) => {
  const TABS = ['Information', 'More info']
  return (
    <Stack spacing='50px'>
      <UserProfileSummary refresh={refresh} data={data} />
      <Tabs>
        <TabList>
          {TABS?.map((data, id) => {
            return (
              <Tab
                _selected={{
                  borderBottom: '5px solid #5C2BA8',
                }}
                _focus={{ boxShadow: 'none' }}
                key={id}
              >
                {data}
              </Tab>
            )
          })}
        </TabList>

        <TabPanels>
          <TabPanel>
            <UserInformationsTabs data={data} />
          </TabPanel>
          <TabPanel>
            <UserMoreInformationTab data={data} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
