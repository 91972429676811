import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { rateDoctor } from "../services/getChats";
import ReactStars from "react-rating-stars-component";
import { AiFillStar } from "react-icons/ai";
export default function RateModal(props) {
  const [data, setData] = useState({
    comment: "",
    ratings: 0,
    doctorId: props.doctorId,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const { comment, ratings } = data || {};
    if (!comment || !ratings) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((val) => ({ ...val, [name]: value }));
  };

  const submit = () => {
    setErrorMessage("");
    if (!data.comment) {
      setErrorMessage("A comment is required");
      return;
    }
    setLoading(true);
    rateDoctor(data)
      .then(() => {
        successNotifier("Thank you.");
        props.close && props.close();
      })
      .catch((e) => {
        errorNotifier(e?.response?.data?.message || "Ratings failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={props.open} onClose={props.close}>
      <ModalContent>
        <ModalHeader>Rating</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <Stack spacing={2} alignItems="center">
            <Text>
              {" "}
              Would you please rate your experience with the therapist{" "}
            </Text>

            <ReactStars
              activeColor={"#00B4D8"}
              size={32}
              onChange={(value) =>
                setData((val) => ({ ...val, ratings: value }))
              }
              fullIcon={<AiFillStar />}
            />
            <Textarea
              name="comment"
              onChange={handleChange}
              placeholder="Leave a comment"
            />
            <Text> {errorMessage} </Text>
            <Button
              disabled={disabled}
              bg={"#5C2BA8"}
              _hover={{ background: "#5C2BA8" }}
              textColor="white"
              isLoading={loading}
              onClick={submit}
              isFullWidth
            >
              Proceed{" "}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
