import { Box, Flex, Text, Image, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import wallet from "../images/wallet.svg";
// import { SubscribeModal } from "./SubscribeModal";
export const SubscriptionCard = ({ userDetails }) => {
  return (
    <Box bg="#f6f4f9" borderRadius="10px" p="30px" className="box-info">
      <Flex justify={"space-between"}>
        <Box>
          <Text fontSize="20px" fontWeight="normal" color="#686278" mb="5px">
            Current Subscription
          </Text>
        </Box>
        <Box>
          <Image src={wallet} alt="allet" />
        </Box>
      </Flex>

      <Flex justifyContent="space-between">
        <Box>
          <Text
            fontFamily="Gilroy-Bold"
            fontSize="3em"
            fontWeight="bold"
            color="#3D348B"
            lineHeight={1.1}
          >
            {userDetails.credits || "00"}
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent={"space-between"} mt="5%" mb="7px">
        {/* <Box>
          <Text fontSize={'14px'} color='#BBBBBB'>
            21st March, 2022
          </Text>
          <Text fontSize={'14px'} color='#BBBBBB'>
            Expiring date
          </Text>
        </Box> */}

        {/* <SubscribeModal /> */}
        <Link to={"/subscription"}>
          <Button
            // onClick={onOpen}
            padding="15px 35px"
            _hover={{ bg: "#09B5A9" }}
            borderRadius="25px"
            bg="#09B5A9"
            color="#fff"
          >
            Manage Subscription
          </Button>
        </Link>
      </Flex>
    </Box>
  );
};
