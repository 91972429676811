import { Stack, Input, Box, Text, Button } from "@chakra-ui/react";
import { UpdateProfileHook } from "./UpdateProfileHook";
import { useContext, useState } from "react";
import { UserProfileContext } from "../../../context/UserContext";

export const ChangeEmailForm = ({ refresh }) => {
  const { userProfile } = useContext(UserProfileContext) || {};
  const [currentEmail, setCurrentEmail] = useState(() => userProfile?.email);
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  // const [loading, setLoading] = useState(true)
  // const [disabled, setDisabled] = useState(true)
  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};

  const { updateEmailInfo } = UpdateProfileHook();

  const updateInfo = () => {
    const payload = {
      ...(currentEmail && { oldEmail: currentEmail }),
      ...(newEmail && { newEmail }),
      ...(confirmNewEmail && { confirmNewEmail }),
    };
    updateEmailInfo(payload).then(() => refresh());
  };

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Current Email</Text>
            <Input
              onChange={(e) => setCurrentEmail(e.target.value)}
              type="text"
              value={currentEmail}
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>New Email</Text>
            <Input
              onChange={(e) => setNewEmail(e.target.value)}
              type="text"
              value={newEmail}
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Confirm New Email</Text>
            <Input
              onChange={(e) => setConfirmNewEmail(e.target.value)}
              type="text"
              value={confirmNewEmail}
              {...formInputStyle}
            />
          </Box>
        </Stack>

        <Button
          _hover={{ bg: "#5C2BA8" }}
          bg="#5C2BA8"
          color="#fff"
          onClick={updateInfo}
          // isLoading={loading}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
};
