import React from "react";
import { Flex, Tr, Td, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

export const TableBody = ({ getData }) => {
  return (
    <>
      {getData?.map?.((data, i) => {
        return (
          <Tr
            key={i}
            bg="#fff"
            color="#686278"
            borderBottom="10px solid #f6f4f9"
            borderRadius="5px"
            // boxShadow='md'
            fontWeight="normal"
          >
            <Td fontSize={["12px", "14px", "16px"]}>
              {dayjs(data?.createdAt).format("D, MMM, YYYY")}
            </Td>
            <Td fontSize={["12px", "14px", "16px"]}>
              {dayjs(data?.createdAt).format("hh:mm a")}
            </Td>

            <Td>{data?.credits}</Td>
            <Td>&#8358;{data?.amount}</Td>
            <Td>
              <Flex>
                <Text>
                  ********
                  {data?.card || "0000"}
                </Text>
              </Flex>
            </Td>
          </Tr>
        );
      })}

      {/* );
      })} */}
    </>
  );
};
