import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { VisitationDetails } from "./VisitationDetails";
import { SearchBar } from "../../../components/SearchBar";
import debounce from "lodash.debounce";
import { searchDoctor } from "../services";
import dayjs from "dayjs";

export const ChatListSection = ({
  setDisplayChat,
  activeIndex,
  doctors,
  loading,
  setActiveIndex,
  setDisplayOrHideChatFeedForSmScreen,
  displayOrHideChatFeedForSmScreen,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [holdDoctorsToMemory, setHoldDoctorsToMemory] = useState([]);

  useEffect(() => {
    if (doctors?.length < 1) return;
    setHoldDoctorsToMemory(doctors);
  }, [doctors]);

  const searchContact = (res) => {
    searchDoctor(res)
      .then((response) => {
        setHoldDoctorsToMemory(response);
      })
      .catch(() => setHoldDoctorsToMemory(doctors));
  };

  //wait for user to finish typing before sending request
  const processSearch = debounce((res) => searchContact(res), 1000);

  return (
    <Box
      bg="#F6F4F9"
      borderRadius={"10px"}
      h={["100%", "100%", "80vh"]}
      fontSize={"12px"}
      pt="15px"
    >
      <Box p="15px">
        <SearchBar
          // width={"100%"}
          inputValue={searchTerm}
          searchDoctor={(search) => {
            setSearchTerm(search);
            processSearch(search);
          }}
          // searchDoctor={setSearchTerm}
          placeholder="search contacts"
        />
      </Box>
      <Divider />

      {loading ? (
        <Box p={5}>
          <Flex gap={5} alignItems="center">
            <SkeletonCircle />
            <SkeletonText noOfLines={2} spacing="4" width={"100%"} />
          </Flex>
          <Flex gap={5} alignItems="center" mt={10}>
            <SkeletonCircle />
            <SkeletonText noOfLines={2} spacing="4" width={"100%"} />
          </Flex>
        </Box>
      ) : !doctors?.length ? (
        <Box p={5}>
          <Text color={"gray.500"} textAlign="center">
            {" "}
            Start a converstation{" "}
          </Text>
        </Box>
      ) : (
        holdDoctorsToMemory?.map((doctor) => (
          <Box
            key={doctor?._id}
            onClick={() => {
              setDisplayChat(doctor);
              setActiveIndex(doctor);
              if (window?.innerWidth < 760) {
                setDisplayOrHideChatFeedForSmScreen(
                  !displayOrHideChatFeedForSmScreen
                );
              }
            }}
            cursor="pointer"
          >
            <VisitationDetails
              px="30px"
              // bg="bg"
              bg={activeIndex === doctor ? "#EFEDF3" : "inherit"}
              name={doctor?.doctorId?.name}
              frequency={doctor?.chat}
              avatar={doctor?.doctorId?.profilePicture}
              time={dayjs(doctor?.updatedAt).format("hh:mm a")}
              data={doctor}
              cursor="pointer"
            />
          </Box>
        ))
      )}
    </Box>
  );
};
