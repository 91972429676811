import {
  Avatar,
  Box,
  Button,
  Flex,
  ModalCloseButton,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { AiTwotoneStar } from "react-icons/ai";
import { GoCalendar } from "react-icons/go";
import { bookDoctor } from "../services/bookDoctor";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { BOOKING_END_MINUTE } from "../../../app/constants";
import { WaitingToBeAccepted } from "./WaitingToBeAccepted";
import { getScheduleDates } from "../services";
import ConfirmBookingModal from "./ConfirmBookingModal";
import FullPageLoader from "../../../components/fullPageLoader";
import { useDispatch } from "react-redux";
import { deleteNotificationData } from "../../../store/actions/notificationAction";
import { useLocation } from "react-router-dom";
// import { useContext } from "react";
// import { UserProfileContext } from "../../../context/UserContext";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export const DoctorsCard = ({
  name,
  star,
  description,
  doctorId,
  // date,
  specialty,
  times,
  isOnline,
  profilePicture,
  refresh,
  currentClickIndex,
}) => {
  const [loading, setLoading] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [showTime, setShowTime] = useState(false);
  const [styleId, setStyleId] = useState(null);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [disabled, setDisabled] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [daySelected, setDaySelected] = useState(null);
  const [choosenTime, setChoosenTime] = useState(null);
  const [waitForDoctorApproval, setWaitForDoctorApproval] = useState(false);
  const [scheduledDates, setScheduledDates] = useState([]);

  const [scheduledDatesLoader, setScheduledDatesLoader] = useState(false);
  const dispatch = useDispatch();
  const [bookingId, setBookingId] = useState("");
  const location = useLocation();

//    const { userProfile } = useContext(UserProfileContext) || {};
//  const meetType = (userProfile?.lockedCredit === 0 && userProfile?.used15MinuteOffer==='pending') ?'free': 'paid'

  
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h?.split(" ") || [];

    let [hours, minutes] = time?.split(":") || [];

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleBook = (useCurrentDateTime = false) => {
    setLoading(true);
    const year = dayjs(daySelected)?.get("year");
    const month = dayjs(daySelected)?.get("month");
    const date = dayjs(daySelected)?.get("date");

    const convertedTime = convertTime12to24(choosenTime);
    const splitTime = convertedTime?.split(":");
    const hour = splitTime?.[0];
    const minute = splitTime?.[1];

    const startDate = dayjs()
      .year?.(year)
      .month?.(month)
      .date?.(date)
      .hour?.(hour)
      .minute?.(minute)
      .format();

    const endDate = dayjs()
      .year?.(year)
      .month?.(month)
      .date?.(date)
      .hour?.(hour)
      .minute?.(minute)
      .add?.(BOOKING_END_MINUTE('paid'), "minute")
      .format(); //x minutes add to it, as the end time

    //using current start and end date and time
    const currentStartDate = dayjs().format();
    const currentEndDate = dayjs().add(BOOKING_END_MINUTE('paid'), "minute").format(); //x minutes add to it, as the end time

    const payload = {
      ...(useCurrentDateTime ? { startDate: currentStartDate } : { startDate }),
      ...(useCurrentDateTime ? { endDate: currentEndDate } : { endDate }),
      doctorId,
      ...(location?.state ? location?.state : {}),
    };

    //the third parameter (useCurrentDateTime) means- dont show notification when patient wants
    //to consult now
    bookDoctor(payload, setLoading, useCurrentDateTime, setBookingId)
      .then(() => {
        setDisabled(true);
        setDaySelected(null);
        setShowTime(false);
        refresh();
        !useCurrentDateTime && onClose(); // close modal after they book a schedule that's not current date and time
        useCurrentDateTime && setWaitForDoctorApproval(true); //show modal only when they want to book immediately
      })
      .catch(() => null);
  };

  //the last parameter, make sure users dont select time that is less than the specified datetime
  const getTimes = (start, end, step = "30", date) => {
    if (!date) return [];
    const selectedDay = dayjs(date).format("YYYY-MM-DD");
    const today = dayjs().format("YYYY-MM-DD");
    let dt = new Date(`December 17, 1995 ${start}`);
    let dc = new Date(`December 17, 1995 ${end}`);

    if (selectedDay > today) {
      let rc = [];
      while (dt <= dc) {
        let availability =
          dt.toLocaleTimeString("en-US", { timeStyle: "short" }) +
          " - " +
          new Date(
            dt.setMinutes(dt.getMinutes() + Number(step))
          ).toLocaleTimeString("en-US", { timeStyle: "short" });
        let splitAvailability = availability.split("-");
        let startAvailability = splitAvailability[0];
        rc.push(startAvailability);
      }
      return rc;
    } else {
      const nowTime = dayjs().format("HH:mm");
      let ft = [];

      if (dayjs(dc).format("HH:mm") > nowTime) {
        while (dt <= dc) {
          if (dayjs(dt).format("HH:mm") > nowTime) {
            let availability =
              dt.toLocaleTimeString("en-US", { timeStyle: "short" }) +
              " - " +
              new Date(
                dt.setMinutes(dt.getMinutes() + Number(step))
              ).toLocaleTimeString("en-US", { timeStyle: "short" });
            let splitAvailability = availability.split("-");
            let startAvailability = splitAvailability[0];
            ft.push(startAvailability);
          } else {
            dt.setMinutes(dt.getMinutes() + Number(step));
          }
        }
        return ft;
      } else {
        return [];
      }
    }
  };

  // const getTimes = (start, end, step = "30") => {
  //   const dt = new Date(`December 17, 1995 ${start}`);
  //   const dc = new Date(`December 17, 1995 ${end}`);
  //   const rc = [];
  //   while (dt <= dc) {
  //     rc.push(dt.toLocaleTimeString("en-US", { timeStyle: "short" }));
  //     dt.setMinutes(dt.getMinutes() + Number(step));
  //   }
  //   return rc;
  // };

  const isDayDisabled = (day) => {
    const newDay = availableDays.filter(
      (availableDay) => availableDay >= new Date().setHours(0, 0, 0, 0)
    );
    return !newDay?.some((availabledDay) =>
      DateUtils?.isSameDay(day, availabledDay)
    );
  };

  useEffect(() => {
    const dates = times?.map((date) => new Date(date?.startDate));
    setAvailableDays(dates);
  }, [times]);

  //check if they have selected both day and choosenTime
  useEffect(() => {
    if (choosenTime && daySelected) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [choosenTime, daySelected]);

  const selectCalendarDay = (res) => {
    const selectedDateObject = times.find(
      (date) =>
        dayjs(date.startDate).format("DD/MM/YYYY") ===
        dayjs(res).format("DD/MM/YYYY")
    );

    const getStartTime = dayjs
      .utc(selectedDateObject?.startDate)
      .format("HH:mm");
    const getEndTime = dayjs.utc(selectedDateObject?.endDate).format("HH:mm");

    setStartTime(getStartTime);
    setEndTime(getEndTime);
    setShowTime(true);
  };

  const overrideDayPicker = `
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #6027B2;
}

  .DayPicker-Day {
background: #6027B2;
color: #fff;
    background #6027B2;
    border
    color: #000;
    padding: 10px 15px;
    font-weight: bold;
   
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
     background-color: #6027B2;
    color: #fff;
   ;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #6027B2;
}
.DayPicker-Day--disabled {
   pointer-events: none;
   color: #DCE0E0;
    font-weight: normal;
    background: none;
 
}
.DayPicker-Caption > div {
    color: #6027B2;
}
  `;

  const scheduledDatesArray = () =>
    scheduledDates?.map((date) => dayjs(date).format("h:mm A"));

  const filterOutBookedDates = () => {
    return getTimes(
      startTime,
      endTime,
      BOOKING_END_MINUTE('paid'),
      daySelected
    )?.filter((time) => !scheduledDatesArray()?.includes(time?.trim()));
  };

  const [showCancelButton, setShowCancelButton] = useState(false);
  // after x amount of time waited, cancel booking
  useEffect(() => {
    if (!waitForDoctorApproval) return;
    setTimeout(() => {
      setShowCancelButton(true);
      // setWaitForDoctorApproval(false);
      // LoadingMessage({
      //   text: "Doctor is currently unavailable, cancelling booking...",
      //   duration: 7000,
      //   w: "500px",
      //   bg: "#ff5252",
      // });
    }, 3 * 60 * 1000); //fire after x minutes
  }, [waitForDoctorApproval]);

  return (
    <Box borderRadius={"10px"} bg="#F7F6FA" p="30px">
      <Stack
        direction={["column", "row"]}
        justifyContent={["space-between"]}
        alignItems="center"
        width="100%"
      >
        <Stack
          direction={["column", "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          spacing="50px"
        >
          <Avatar
            width="120px"
            height={"120px"}
            src={profilePicture}
            name={name}
          />
          <Stack>
            <Text fontSize="1.5em" color="#403058" fontWeight={"semibold"}>
              {name}
            </Text>
            <Stack direction="row" spacing="10px">
              <Text color="#929292">{specialty}</Text>
              <Stack direction="row" alignItems={"center"}>
                <Text>{star}</Text>
                <AiTwotoneStar color="#09B5A9" />
                <Text>Ratings</Text>
              </Stack>
            </Stack>

            <Text maxW="500px" color="#929292" my="20px" fontSize={"16px"}>
              {description}
            </Text>

            <Stack marginTop="50px">
              <Text color="#403058" fontWeight={"normal"}>
                Specialization:
              </Text>
              <Stack direction="row">
                <Box fontSize={"14px"} fontWeight="normal" color="#403058">
                  <Text>{specialty}</Text>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {isOnline && currentClickIndex === 1 ? (
          <>
            <Button
              bg="#6027B2"
              _hover={{ bg: "#6027B2" }}
              color="#fff"
              onClick={() => {
                //delete the old notification from redux incase there's one
                dispatch(deleteNotificationData({}));
                handleBook(true);
              }}
              isLoading={loading}
            >
              Consult
            </Button>
            <WaitingToBeAccepted
              setWaitForDoctorApproval={setWaitForDoctorApproval}
              bookingId={bookingId}
              setShowCancelButton={setShowCancelButton}
              showCancelButton={showCancelButton}
              open={waitForDoctorApproval}
              setOpen={setWaitForDoctorApproval}
            />
          </>
        ) : (
          <Box bg="#fff" borderRadius={"16px"}>
            <Flex width={"100%"} justifyContent={"space-between"} p="20px">
              <Box>
                <Stack direction="row">
                  <GoCalendar fontSize={"1.5em"} />
                  <Text
                    onClick={onOpen}
                    fontWeight="bold"
                    color="#403058"
                    fontSize={"1.4em"}
                    cursor="pointer"
                  >
                    {daySelected
                      ? dayjs(daySelected).format("D MMMM")
                      : "Choose day"}
                  </Text>
                </Stack>

                <Modal
                  isOpen={isOpen}
                  onClose={() => {
                    onClose();
                    setDisabled(true);
                    setDaySelected(null);
                    setShowTime(false);
                    refresh();
                  }}
                  size="xl"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalBody>
                      <ModalCloseButton _focus={{ boxShadow: "none" }} />
                      <style>{overrideDayPicker}</style>
                      <Stack direction="row" alignItems={"center"}>
                        <DayPicker
                          selectedDays={daySelected}
                          onDayClick={(res) => {
                            selectCalendarDay(res);
                            setDaySelected(res);
                            setChoosenTime(null);
                            setStyleId(null);
                            setScheduledDatesLoader(true);
                            getScheduleDates(
                              setScheduledDates,
                              doctorId,
                              dayjs(res).format("YYYY-MM-DD"),
                              setScheduledDatesLoader
                            );
                          }}
                          disabledDays={isDayDisabled}
                        />
                        {scheduledDatesLoader ? (
                          <FullPageLoader h="100%" bg="transparent" />
                        ) : (
                          <Stack spacing={"20px"}>
                            <Stack
                              direction="column"
                              align="center"
                              maxHeight="240px"
                              overflow="auto"
                            >
                              <SimpleGrid columns={2} spacing={2}>
                                {showTime ? (
                                  filterOutBookedDates()?.map?.((time, i) => {
                                    return (
                                      <Text
                                        cursor="pointer"
                                        textAlign={"center"}
                                        p="5px"
                                        fontSize={".8em"}
                                        borderRadius="10px"
                                        key={i}
                                        width={"100%"}
                                        bg={styleId === i ? "#403058" : "#fff"}
                                        color={
                                          styleId === i ? "#fff" : "#403058"
                                        }
                                        border="1px solid #CAF0F8"
                                        fontWeight={"normal"}
                                        onClick={() => {
                                          setStyleId(i);
                                          setChoosenTime(time);
                                        }}
                                      >
                                        {time}
                                      </Text>
                                    );
                                  })
                                ) : (
                                  <Text>No day selected</Text>
                                )}
                              </SimpleGrid>
                            </Stack>
                            {!disabled && (
                              <ConfirmBookingModal
                                handleBook={() => handleBook()}
                                disabled={disabled}
                                loading={loading}
                              />
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </ModalBody>
                  </ModalContent>
                </Modal>

                <Text fontSize={"12px"} mb="10px">
                  Chat. Voice. Video {BOOKING_END_MINUTE('paid')} min
                </Text>
              </Box>
            </Flex>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
