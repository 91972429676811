import { Text, Box } from "@chakra-ui/react";
import ChildCheckListForParentForm from "./CHILD_SYMPTOM_CHECKLIST_FOR_PARENT_FORM";
import { TestButtonsFormat } from "./TestButtonsFormat";

export const ABox = ({
  testName,
  answer,
  id,
  script,
  buttonsFormat,
  test,
  next,
  filledFormData,
  setFilledFormData,
}) => {
  const CHILD_SYMPTOM_CHECKLIST_FOR_PARENT =
    "Child Symptom Checklist for Parent";
  return (
    <>
      {testName === CHILD_SYMPTOM_CHECKLIST_FOR_PARENT &&
      Object.keys(filledFormData).length < 1 ? (
        <ChildCheckListForParentForm filledFormData={setFilledFormData} />
      ) : (
        <Box textAlign="center" py="40px">
          <Text
            mt="30px"
            fontSize="50px"
            fontWeight={"semibold"}
            color="#686278"
          >
            Question {id}
          </Text>
          <Text
            color="#5C2BA8"
            textAlign="center"
            transform={"translate(10%, 20%)"}
            fontSize={"20px"}
            fontWeight={"semibold"}
            maxWidth="80%"
          >
            {script}
          </Text>

          <TestButtonsFormat
            format={buttonsFormat}
            answer={(data) => {
              let payload = {
                ...(testName === CHILD_SYMPTOM_CHECKLIST_FOR_PARENT
                  ? filledFormData //only send the form data for CHILD_SYMPTOM_CHECKLIST_FOR_PARENT
                  : {}),
                response: data,
              };
              answer(payload);
            }}
            id={id}
            next={next}
            test={test}
          />
        </Box>
      )}
    </>
  );
};
