import http, { AUTH_ROUTES } from "../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const InviteUser = async (payload, setLoading) => {
  try {
    setLoading(true);
    const response = await http.post(AUTH_ROUTES.INVITE_USER, payload);
    successNotifier(response?.data?.invite?.message);
    setLoading(false);
  } catch (e) {
    errorNotifier(e?.response?.data?.data);
    setLoading(false);
    throw new Error();
  }
};
