import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";

import cardBg from "../images/cardBg.svg";

export const UserCard = ({ user, ...props }) => {
  return (
    <Box
      backgroundImage={cardBg}
      backgroundSize="cover"
      borderRadius="10px"
      p="20px 40px"
      boxShadow="base"
      w="100%"
      className="box-info"
      {...props}
    >
      <Flex justifyContent="space-between" direction={"column"} mb="20px">
        <Box mt="40px">
          <Text fontSize="1.2em" color="#5C2BA8" fontWeight="normal">
            Hello,
          </Text>
          <Heading fontFamily="Gilroy-Bold" color="#200960">
            {user?.firstName} {user?.lastName}
          </Heading>

          <Link to="take-test">
            <Button
              _hover={{ bg: "#5C2BA8" }}
              bg="#5C2BA8"
              color="#fff"
              mt="20px"
              borderRadius={"25px"}
            >
              Take a test
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};
