import { Stack } from "@chakra-ui/react";
import { GoogleLogin } from "@react-oauth/google";
import { googleVerifyUser } from "../service/loginUser";

function GoogleSignin({
  setAccountModalData,
  setHasNotBeenVerified,
  setOpenChooseAccModal,
}) {
  const handleSignIn = async (credential) => {
    await googleVerifyUser({ idToken: credential }).then((data) => {
      const token = data?.token;
      const email = data?.user?.email;

      if (token) {
        sessionStorage.setItem("HBU#221#", token);
        window.location.reload();
      } else if (data?.enterpriseDetails) {
        setAccountModalData({ ...data, email });
        setOpenChooseAccModal(true);
      } else if (data?.emailVerification === false) {
        setHasNotBeenVerified({ bool: true, data });
        return;
      } else {
        return;
      }
    });
  };
  return (
    <Stack justifyContent={"center"} alignItems="center">
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          const credential = credentialResponse?.credential;
          handleSignIn(credential);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Stack>
  );
}

export default GoogleSignin;
