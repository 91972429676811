import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const giftCredit = async (payload, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.GIFT_CREDIT, payload);
    successNotifier("Credit successfully gifted");
    setLoading && setLoading(false);
  } catch (error) {
    setLoading && setLoading(false);
    errorNotifier(error?.response?.data?.data);
    throw new Error();
  }
};
