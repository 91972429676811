import http, { AUTH_ROUTES } from "../../../services/api";

export const cancelBooking = async (bookingId) => {
  return await http.put(AUTH_ROUTES.CANCEL_BOOKING(bookingId));
};

export const searchDoctor = async (payload, setLoading, setSearchResult) => {
  try {
    const {
      data: {
        doctor: { data },
      },
    } = await http.get(AUTH_ROUTES.SEARCH_DOCTOR(payload));
    setLoading(false);

    data?.length < 1 ? setSearchResult([]) : setSearchResult(data);
  } catch (e) {
    setLoading(false);
    setSearchResult([]);
  }
};

export const getScheduleDates = async (
  setScheduledDates,
  doctorId,
  date,
  setScheduledDatesLoader
) => {
  try {
    const res = await http.get(AUTH_ROUTES.GET_SCHEDULED_DATES(doctorId, date));
    const allBookedDates = res?.data?.booking?.data?.schedules?.map(
      (data) => data?.startDate
    );
    setScheduledDates(allBookedDates);
    setScheduledDatesLoader && setScheduledDatesLoader(false);
  } catch (e) {
    setScheduledDates([]);
    setScheduledDatesLoader && setScheduledDatesLoader(false);
  }
};
