import { Stack, Input, Box, Text, Button } from '@chakra-ui/react'
import { UpdateProfileHook } from './UpdateProfileHook'
import { useContext, useState } from 'react'
import { UserProfileContext } from '../../../context/UserContext'

export const PersonalInfoForm = ({ refresh }) => {
  const { userProfile } = useContext(UserProfileContext) || {}
  const [firstName, setFirstName] = useState(()=>userProfile?.firstName)
  const [lastName, setLastName] = useState(()=>userProfile?.lastName)
  const [dob, setDob] = useState(()=>userProfile?.DOB)
  const [gender, setGender] = useState(()=>userProfile?.gender)

  const formTitleStyle = {
    fontSize: '.9em',
  }
  const formInputStyle = {}

 

  const { updatePersonalInfo } = UpdateProfileHook()

  const updateInfo = () => {
    const payload = {
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(dob && { DOB: dob }),
      ...(gender && { gender }),
    }
    updatePersonalInfo(payload).then(() => refresh())
  }

  return (
    <Box bg='#fff' p='50px'>
      <Stack spacing='30px' maxW='500px'>
        <Stack direction='row'>
          <Box>
            <Text {...formTitleStyle}>First Name</Text>
            <Input
              onChange={(e) => setFirstName(e.target.value)}
              type='text'
              value={firstName}
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>Last Name</Text>
            <Input
              onChange={(e) => setLastName(e.target.value)}
              type='text'
              value={lastName}
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction='row'>
          <Box>
            <Text {...formTitleStyle}>Date of Birth</Text>
            <Input
              onChange={(e) => setDob(e.target.value)}
              type='text'
              value={dob}
              {...formInputStyle}
            />
          </Box>
          {/* <Box>
          <Text {...formTitleStyle}>Phone Number</Text>
          <Input
            onChange={(e) => setPhoneNumber(e.target.value)}
            type='text'
            value={phoneNumber}
            {...formInputStyle}
          />
        </Box> */}
          <Box>
            <Text {...formTitleStyle}>Gender</Text>
            <Input
              onChange={(e) => setGender(e.target.value)}
              type='text'
              value={gender}
              {...formInputStyle}
            />
          </Box>
        </Stack>

        <Button
          _hover={{ bg: '#5C2BA8' }}
          bg='#5C2BA8'
          color='#fff'
          onClick={updateInfo}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  )
}
