export const getComponentTitle = (pathname) => {
  const urls = window.location.href;
  const data = new URL(urls)?.pathname?.split("/")?.[2];

  switch (pathname) {
    case "/dashboard":
      return "Dashboard";

    case "/profile":
      return "Profile";

    case "/subscription":
      return "Subscription";
    case "/take-test":
      return "Take Test";
    case `/take-test/${data}`:
      return "Take Test";

    case "/doctors":
      return "Therapists";
    case "/history":
      return "History";
    case "/chats":
      return "Chats";
    case "/booking-schedule":
      return "Booking Schedules";
    case "/health-videos":
      return "Health Videos";
    case "/notifications":
      return "Notifications";
    default:
      return "Welcome";
  }
};
