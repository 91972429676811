import { Box } from "@chakra-ui/react";
import React from "react";
import { DoctorsCard } from "./DoctorsCard";

export const AllDoctorsCard = ({
  doctors,
  searchResult,
  refresh,
  currentClickIndex,
}) => {
  return (
    <Box mb="30px">
      {searchResult && searchResult?.length > 0
        ? searchResult?.map((data) => {
            return (
              <Box mb="30px" key={data?._id}>
                <DoctorsCard
                  currentClickIndex={currentClickIndex}
                  doctorId={data?._id}
                  name={data?.name}
                  specialty={data?.specialty}
                  star={data?.ratings}
                  description={data?.bio}
                  date={data?.availability}
                  specialisation={data?.specialisation}
                  activityLog={data?.activityLog}
                  times={data?.availability}
                  isOnline={data?.available}
                  profilePicture={data?.profilePicture}
                  refresh={refresh}
                />
              </Box>
            );
          })
        : doctors?.map((data) => {
            return (
              <Box mb="30px" key={data?._id}>
                <DoctorsCard
                  currentClickIndex={currentClickIndex}
                  doctorId={data?._id}
                  name={data?.name}
                  specialty={data?.specialty}
                  star={data?.ratings}
                  description={data?.bio}
                  date={data?.availability}
                  specialisation={data?.specialisation}
                  activityLog={data?.activityLog}
                  times={data?.availability}
                  isOnline={data?.available}
                  profilePicture={data?.profilePicture}
                  refresh={refresh}
                />
              </Box>
            );
          })}
    </Box>
  );
};
