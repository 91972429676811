import { Box, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";

export const DateCard = ({ date, color, bg, onClick, isActive }) => {
  const splitDate = date.split("-");

  const year = splitDate?.[0];
  const month = splitDate?.[1];
  const day = splitDate?.[2];

  const formattedDateInDayOfWeek = dayjs()
    .month(Number(month) - 1)
    .year(year)
    .date(Number(day))
    .format("ddd");
  const formattedDateInDayOfMonth = dayjs()
    .month(Number(month) - 1)
    .year(year)
    .date(Number(day))
    .format("D");

  const formattedMonthOfTheYear = dayjs()
    .month(Number(month) - 1)
    .year(year)
    .date(Number(day))
    .format("MMM");
  return (
    <Box
      bg={bg}
      color={color}
      borderRadius={"8px"}
      maxHeight="99px"
      height="99px"
      p="8px 29px"
      textAlign={"center"}
      cursor="pointer"
      fontWeight={"bold"}
      className="box-info"
      // onClick={() => {
      //   onClick(date);
      // }}
    >
      <Text fontSize=".9em">{formattedDateInDayOfWeek}</Text>
      <Text fontSize="1.6em" fontWeight={"bold"}>
        {formattedDateInDayOfMonth}
      </Text>
      <Text fontSize=".7em">{formattedMonthOfTheYear}</Text>
      {isActive && (
        <Stack
          spacing={"4px"}
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box borderRadius="50%" width="7px" height="7px" bg="#09B5A9" />
          <Box borderRadius="50%" width="7px" height="7px" bg="#F79256" />
        </Stack>
      )}
    </Box>
  );
};
