import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Input,
  FormControl,
  FormLabel,
  Heading,
  InputRightElement,
  Button,
  InputGroup,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { signupUser } from "../services/signupUser";
import { Link } from "react-router-dom";

export const SignupForm = () => {
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyCode, setcompanyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const handleClick = () => setShow(!show);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    await signupUser(
      { firstName, lastName, email, phoneNumber, password, isWeb: true },
      setLoading
    );
  };

  useEffect(() => {
    if (!firstName || !lastName || !email || !phoneNumber || !password) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, email, phoneNumber, password]);

  return (
    <Box width={"100%"} p={["20px", "100px", "100px"]}>
      <Heading
        fontFamily="Gilroy-Bold"
        mb="10px"
        color="#2A2047"
        fontSize="46px"
        lineHeight={1.1}
      >
        Get <br />
        <Text style={{ fontWeight: "bold" }}>started</Text>
      </Heading>
      <Box>
        <form onSubmit={handleSignup}>
          <Text mb="20px">Enter your details to begin....</Text>
          <FormControl>
            <FormLabel htmlFor="First Name" color="#929292">
              First Name
            </FormLabel>
            <Input
              height="50px"
              borderRadius={"10px"}
              placeholder=""
              // type={show ? "text" : "email"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="Last Name" color="#929292" mt="5px">
              Last Name
            </FormLabel>
            <Input
              height="50px"
              borderRadius={"10px"}
              placeholder=""
              // type={show ? "text" : "email"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email" color="#929292" mt="5px">
              Email Address
            </FormLabel>
            <Input
              height="50px"
              borderRadius={"10px"}
              placeholder=""
              // type={show ? "text" : "email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="Phone Number" color="#929292" mt="5px">
              Phone Number
            </FormLabel>
            <Input
              height="50px"
              borderRadius={"10px"}
              placeholder=""
              // type={show ? "text" : "email"}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password" color="#929292" mt="5px">
              Password
            </FormLabel>
            <InputGroup>
              <Input
                height="50px"
                borderRadius={"10px"}
                type={show ? "text" : "password"}
                placeholder=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement
                top={"auto"}
                children={
                  <span
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={handleClick}
                  >
                    {show ? <FaEye /> : <FaEyeSlash />}
                  </span>
                }
              />
            </InputGroup>
          </FormControl>
           <FormControl>
            <FormLabel htmlFor="Promo Code" color="#929292" mt="5px">
             Promo Code
            </FormLabel>
            <Input
              height="50px"
              borderRadius={"10px"}
              placeholder="Optional"
              // type={show ? "text" : "email"}
              value={companyCode}
              onChange={(e) => setcompanyCode(e.target.value)}
            />
          </FormControl>
          <Button
            mt="20px"
            bg="#2A2047"
            color="#fff"
            borderRadius={"10px"}
            type="submit"
            width="100%"
            height="50px"
            onClick={handleClick}
            isLoading={loading}
            disabled={disabled}
            _hover={{ bg: "#5C2BA8" }}
          >
            Submit
          </Button>
          <Text mt="15px">
            You already have an account?{" "}
            <Link to="/Login" style={{ color: "#2A2047", fontSize: ".9em" }}>
              {" "}
              Login Here
            </Link>
          </Text>
        </form>
      </Box>
    </Box>
  );
};
