import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const bookDoctor = async (
  payload,
  setLoading,
  notifierTurnedOff,
  setBookingId
) => {
  try {
    const { data } = await http.post(AUTH_ROUTES.BOOK_DOCTOR, payload);
    setBookingId(data?.booking?.data?._id); // i need the booking id to be able to delete the booking
    !notifierTurnedOff && successNotifier(data?.booking?.message);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
    throw new Error();
  }
};
