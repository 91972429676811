import { PROTECTED_PATHS } from "../../../../app/constants";
import { ImStack } from "react-icons/im";
import { MdOndemandVideo, MdOutlineDashboard } from "react-icons/md";
import { IoPersonOutline } from "react-icons/io5";
import { BsHeart, BsBell, BsChatDots } from "react-icons/bs";
import { RiWaterFlashLine } from "react-icons/ri";
import { AiOutlineClockCircle } from "react-icons/ai";

const {
  DASHBOARD,
  PROFILE,
  DOCTORS,
  SUBSCRIPTION,
  TEST,
  HISTORY,
  CHATS,
  HEALTH_VIDEOS,
  BOOKING_SCHEDULE,
  NOTIFICATIONS,
} = PROTECTED_PATHS;

const userType = JSON.parse(sessionStorage.getItem("!!OPQ1"));

export const ADMIN_NAV_ITEMS = [
  {
    title: "Dashboard",
    to: DASHBOARD,
    icon: MdOutlineDashboard,
  },
  {
    title: "Chats",
    to: CHATS,
    icon: BsChatDots,
  },
  {
    title: "Therapists",
    to: DOCTORS,
    icon: BsHeart,
  },
  {
    title: "Take Test",
    to: TEST,
    icon: RiWaterFlashLine,
  },

  ...(userType?.client !== "Staff"
    ? [
        {
          title: "Subscription",
          to: SUBSCRIPTION,
          icon: ImStack,
        },
      ]
    : []),
  {
    title: "History",
    to: HISTORY,
    icon: BsBell,
  },
  {
    title: "Profile",
    to: PROFILE,
    icon: IoPersonOutline,
  },
  {
    title: "Booking Schedule",
    to: BOOKING_SCHEDULE,
    icon: AiOutlineClockCircle,
  },
  {
    title: "Health Videos",
    to: HEALTH_VIDEOS,
    icon: MdOndemandVideo,
  },
  {
    title: "Notifications",
    to: NOTIFICATIONS,
    icon: BsBell,
  },
];
