import { Avatar, Box, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
// import Reschedule from "./Reschedule";
import RescheduleBooking from "./RescheduleBookingModal";

export const ScheduleDetails = ({
  type,
  name,
  startTime,
  endTime,
  visitationType,
  bookingId,
  schedule,
  refresh,
  scheduledDate,
  availability,
  doctorId,
  imgUrl,
  isElapsed,
  status,
}) => {
  const colors = ["#5C2BA8", "#09B5A9", "#FFC727"];
  const random = Math.floor(Math.random() * colors.length);
  const getRandomColor = colors[random];

  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);

  let highLightStyle = {
    borderRadius: "20px",
    fontSize: ".8em",
    fontWeight: "bold",
    textAlign: "center",
    maxWidth: "100px",
    padding: "7px",
    marginTop: "10px",
  };

  return (
    <Box
      bg="inherit"
      borderRadius={"10px"}
      borderRight={`6px solid ${getRandomColor}`}
      borderBottom={`3px solid ${getRandomColor}`}
      w={"250px"}
      maxW={"250px"}
      className="box-info"
    >
      <Flex mb="15px" justifyContent="space-between">
        <Flex>
          <Avatar
            size={"lg"}
            src={imgUrl}
            name={name}
            alt={`Picture of ${name}`}
          />
          <Box ml="20px">
            <Text color="#403058" fontWeight={"semibold"}>
              {name}
            </Text>
            <Text color="#929292" my="3px">
              {visitationType}
            </Text>
            <Stack direction={"row"} alignItems={"center"} fontSize=".77em">
              <BiTimeFive />

              <Text ml="10px" color="#929292">
                {startTime}
              </Text>
              <Text>-</Text>
              <Text ml="10px" color="#929292">
                {endTime}
              </Text>
            </Stack>
            {type === "COMPLETED" &&
              status === "COMPLETED" &&
              isElapsed === false && (
                <Text bg="#95FFAB" color="#0A0A0A" {...highLightStyle}>
                  Completed
                </Text>
              )}
            {type === "COMPLETED" && isElapsed && (
              <Text bg="#FF9BB4" color="#0A0A0A" {...highLightStyle}>
                Elapsed
              </Text>
            )}

            {type === "PENDING" && (
              <Box mt="10px">
                <RescheduleBooking
                  buttonProps={{
                    color: "#fff",
                    bg: "#5c2ba8",
                    _hover: { background: "#5c2ba8" },
                    _focus: { boxShadow: "none" },
                    size: "xs",
                    onClick: () => setOpenRescheduleModal(true),
                  }}
                  buttonTitle={"Reschedule"}
                  availability={availability}
                  isOpen={openRescheduleModal}
                  onClose={() => setOpenRescheduleModal(false)}
                  refresh={refresh}
                  doctorId={doctorId}
                  bookingId={bookingId}
                />
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
