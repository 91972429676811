import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getUserData = async (userId, cache = true) => {
  const localData = sessionStorage.getItem("#userDetail#");
  if (localData) {
    const userData = JSON.parse(localData);
    if (userData._id && cache) {
      return userData;
    }
  }
  const data = await http.get(AUTH_ROUTES.GET_USERS(userId));
  const user = data?.data?.userDetails?.data?.users?.[0];
  sessionStorage.setItem("#userDetail#", JSON.stringify(user));
  return user;
};

export const getUserProfile = async (userId, setUserProfile, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_USERS(userId));
    setUserProfile(data?.data?.userDetails?.data?.users);
    setLoading(false);
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};
