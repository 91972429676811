import { Box, Button, Stack } from "@chakra-ui/react";
import { useState } from "react";

export const TestButtonsFormat = ({ format, id, answer }) => {
  const [currentChoiceStyle, setCurrentChoiceStyle] = useState(null);

  switch (format) {
    case "yes":
      return (
        <Box mt="40px">
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              // answer({ [id]: test?.answerOptions[0] });
              answer({ [id]: "yes" });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
            }}
          >
            Yes
          </Button>
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "no" });
              // answer({ [id]: test?.answerOptions[1] });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            No
          </Button>
        </Box>
      );

    case "true".toLowerCase():
      return (
        <Box mt="40px">
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: true });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
              // next()
            }}
          >
            True
          </Button>
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: false });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            False
          </Button>
        </Box>
      );

    case "Not at all".toLowerCase():
      return (
        <Stack mt="40px" w="100%" direction="row" justifyContent={"center"}>
          {/* Not at all = 0 A little bit = 1 Moderately = 2 Quite a bit = 3
          Extremely = 4 */}
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 0 });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
            }}
          >
            Not at all
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 1 });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            A little bit
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 2 });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Moderately
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 3 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 3 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 3 });
              setCurrentChoiceStyle(3);
              // canContinueToNextQuestion(true);
            }}
          >
            Quite a bit
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 4 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 4 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 4 });
              setCurrentChoiceStyle(4);
              // canContinueToNextQuestion(true);
            }}
          >
            Extremely
          </Button>
        </Stack>
      );

    case "Never".toLowerCase():
      return (
        <Stack mt="40px" w="100%" direction="row" justifyContent={"center"}>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 0 });
              setCurrentChoiceStyle(0);
            }}
          >
            Never
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 1 });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            Sometimes
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 2 });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Often
          </Button>
        </Stack>
      );
    case "Never-1".toLowerCase():
      return (
        <Stack mt="40px" w="100%" direction="row" justifyContent={"center"}>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 0 });
              setCurrentChoiceStyle(0);
            }}
          >
            Never
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 1 });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            A little bit
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 2 });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Quite a bit
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 3 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 3 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 3 });
              setCurrentChoiceStyle(3);
              // canContinueToNextQuestion(true);
            }}
          >
            Extremely
          </Button>
        </Stack>
      );

    case 5:
      return (
        <Stack mt="40px" w="100%" direction="row" justifyContent={"center"}>
          {/* Not at all = 0 A little bit = 1 Moderately = 2 Quite a bit = 3
          Extremely = 4 */}
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "Not at all" });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
            }}
          >
            Not at all
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "A little bit" });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            A little bit
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "Moderately" });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Moderately
          </Button>
          <Button
            style={{
              padding: "20px 50px",
              width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 3 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 3 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "Quite a bit" });
              setCurrentChoiceStyle(3);
              // canContinueToNextQuestion(true);
            }}
          >
            Quite a bit
          </Button>
        </Stack>
      );

    case "Disagree strongly".toLowerCase():
      return (
        <Stack mt="40px" w="100%" direction="row" justifyContent={"center"}>
          {/* Not at all = 0 A little bit = 1 Moderately = 2 Quite a bit = 3
          Extremely = 4 */}
          <Button
            style={{
              padding: "10px 10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 1 });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            Disagree strongly
          </Button>
          <Button
            style={{
              padding: "10px 10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 2 });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Disagree a little
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 3 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 3 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 3 });
              setCurrentChoiceStyle(3);
              // canContinueToNextQuestion(true);
            }}
          >
            Neither agree nor disagree
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 4 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 4 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 4 });
              setCurrentChoiceStyle(4);
              // canContinueToNextQuestion(true);
            }}
          >
            Agree a little
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 5 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 5 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 5 });
              setCurrentChoiceStyle(5);
              // canContinueToNextQuestion(true);
            }}
          >
            Agree strongly
          </Button>
        </Stack>
      );
    case "Did not apply to me at all".toLowerCase():
      return (
        <Stack
          mt="40px"
          w="100%"
          direction="row"
          justifyContent={"center"}
          wrap="wrap"
        >
          {/* Not at all = 0 A little bit = 1 Moderately = 2 Quite a bit = 3
            Extremely = 4 */}
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              marginBottom: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 0 });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
            }}
          >
            Did not apply to me at all
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 1 });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            Applied to me to some degree, or some of the time
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 2 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 2 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 2 });
              setCurrentChoiceStyle(2);
              // canContinueToNextQuestion(true);
            }}
          >
            Applied to me to a considerable degree or a good part of time
          </Button>
          <Button
            style={{
              padding: "10px",
              // width: "80px",
              my: "15px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 3 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 3 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: 3 });
              setCurrentChoiceStyle(3);
              // canContinueToNextQuestion(true);
            }}
          >
            Applied to me very much or most of the time
          </Button>
        </Stack>
      );
    default:
      return (
        <Box mt="40px">
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 0 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 0 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "yes" });
              setCurrentChoiceStyle(0);
              // canContinueToNextQuestion(true);
            }}
          >
            Yes
          </Button>
          <Button
            style={{
              width: "80px",
              margin: "5px",
              border: "1px solid #E1E1E1",
              borderRadius: "30px",
              background: currentChoiceStyle !== 1 ? "transparent" : "#5C2BA8",
              color: currentChoiceStyle !== 1 ? "#5C2BA8" : "#fff",
            }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              answer({ [id]: "no" });
              setCurrentChoiceStyle(1);
              // canContinueToNextQuestion(true);
            }}
          >
            No
          </Button>
        </Box>
      );
  }
};
