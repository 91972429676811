import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  HorizontalScrollContainer,
  HorizontalScrollItem,
} from "react-simple-horizontal-scroller";
import { DateCard } from "./DateCard";
// import { fakeData } from './fakeData'
import { ScheduleDetails } from "./ScheduleDetails";

export const CompletedSchedules = ({
  refresh,
  completedScheduledDateKeys,
  completedScheduledDateKeysAndValues,
  selectedScheduleKey,
  setSelectedScheduleKey,
  activeIndex,
  setActiveIndex,
}) => {
  const [displaySchedule, setDisplaySchedule] = useState([]);

  const selectedItemId = Number(dayjs().format("D")) - 1; //scroll to today date

  useEffect(() => {
    if (!selectedScheduleKey) return;
    const scheduleValues =
      completedScheduledDateKeysAndValues[selectedScheduleKey];
    if (!scheduleValues) return;
    setDisplaySchedule(scheduleValues);
  }, [selectedScheduleKey, completedScheduledDateKeysAndValues]);

  return completedScheduledDateKeys?.length > 0 ? (
    <Box>
      <HorizontalScrollContainer
        selectedItemId={selectedItemId} //scroll to today date
        // controlsConfig={{
        //   right: { visibility: "AUTO" },
        //   left: { visibility: "AUTO" },
        // }}
      >
        {completedScheduledDateKeys?.map((data, i) => {
          return (
            <HorizontalScrollItem
              style={{ marginRight: "20px" }}
              id={i}
              onClick={() => {
                setActiveIndex(i);
                setSelectedScheduleKey(data);
              }}
            >
              <DateCard
                id={i}
                date={data}
                color={activeIndex === i ? "#fff" : "#929292"}
                bg={activeIndex === i ? "#5C2BA8" : "#fff"}
                isActive={activeIndex === i}
              />
            </HorizontalScrollItem>
          );
        })}
      </HorizontalScrollContainer>
      {displaySchedule?.length > 0 && (
        <Box my="30px" p="30px" bg="#fff" borderRadius={"20px"}>
          <SimpleGrid minChildWidth="200px" spacing="40px">
            {displaySchedule?.map((scheduleDetails) => {
              return (
                <ScheduleDetails
                  type={"COMPLETED"}
                  imgUrl={scheduleDetails?.doctorId?.profilePicture}
                  name={scheduleDetails?.doctorId?.name}
                  startTime={dayjs(scheduleDetails?.startDate).format(
                    "hh:mm a"
                  )}
                  endTime={dayjs(scheduleDetails?.endDate).format("hh:mm a")}
                  status={scheduleDetails.status}
                  bookingId={scheduleDetails?._id}
                  patientId={scheduleDetails?.patientId}
                  refresh={refresh}
                  doctorId={scheduleDetails?.doctorId?._id}
                  availability={scheduleDetails?.doctorId?.availability}
                  isElapsed={
                    scheduleDetails?.status === "APPROVED" &&
                    scheduleDetails?.isElapsed === true
                  }
                />
              );
            })}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  ) : (
    <Text>No completed schedules</Text>
  );
};
