import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { ImSpinner3 } from "react-icons/im";

export const TestFlow = ({
  result,
  loading,
  children,
  currentIndex,
  setCurrentIndex,
}) => {
  const currentChild = React.Children.toArray(children)[currentIndex];
  const [testResult, setTestResult] = useState([]);

  const [filledFormData, setFilledFormData] = useState({});
  const previous = () => {
    if (currentIndex < 1) return;
    setCurrentIndex((prev) => prev - 1);
  };

  const answer = (obj) => {
    setTestResult((prev) => ({
      response: { ...prev?.response, ...obj?.response },
      extraDetails: obj.extraDetails,
    }));

    const totalFlowLength = React.Children.toArray(children).length;
    if (currentIndex + 1 === totalFlowLength) {
      const testTaken = {
        ...testResult,
        response: { ...testResult?.response, ...obj?.response },
      };
      result(testTaken);
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  };

  return (
    <>
      {React.cloneElement(currentChild, {
        answer,
        filledFormData,
        setFilledFormData,
      }) ? (
        <>
          {React.cloneElement(currentChild, {
            answer,
            filledFormData,
            setFilledFormData,
          })}
          <Box position="relative">
            <button
              style={{ position: "absolute", left: "0", top: "-90px" }}
              onClick={() => previous()}
            >
              <MdOutlineArrowBackIosNew fontSize={"2.5em"} color="#707070" />
            </button>
            {loading && (
              <p style={{ position: "absolute", right: "0", top: "-90px" }}>
                <ImSpinner3 fontSize={"2.5em"} />
              </p>
            )}
          </Box>
        </>
      ) : (
        currentChild
      )}
    </>
  );
};
