import http, { AUTH_ROUTES } from "../../../services/api";

export const getSingleVideo = async (videoId, setVideo, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_SINGLE_VIDEO(videoId));
    setVideo(data?.data?.video?.data?.videos);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};
