import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import testImage from "../images/testImage.svg";
import { InviteModal } from "./InviteModal";
export const HeroCard = () => {
  return (
    <Box bg="#5C2BA8" p="40px" borderRadius="10px" boxShadow="lg">
      <Flex justifyContent="space-between" position="relative" zIndex={1}>
        <Box>
          <Text
            fontWeight="bold"
            color="#fff"
            fontSize={["1.2em", "30px"]}
            maxWidth="500px"
            lineHeight={1.2}
          >
            Your mental health is a{" "}
            <span style={{ color: "#09B5A9" }}>priority</span> Your{" "}
            <span style={{ color: "#09B5A9" }}>happiness</span> is an essential
          </Text>
          <Box>
            <Text
              color="#fff"
              mt="40px"
              mb="5px"
              fontSize={["1.2em", "22px"]}
              fontWeight="bold"
              maxWidth="500px"
            >
              Invite your friends & family to take a test
            </Text>
            <InviteModal />
          </Box>
        </Box>
        <Box>
          <Image src={testImage} alt="" />
        </Box>
      </Flex>
    </Box>
  );
};
