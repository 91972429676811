import { CurveBackgroundColor } from "../../../components/CurveBackgroundColor";
import { Avatar, Box, Stack, Text, Button } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiUser, FiMail } from "react-icons/fi";
import { BsTelephoneForward } from "react-icons/bs";
import { uploadProfileImage } from "../services/updateUser";
import { useRef } from "react";
import { disconnect } from "../../../services/socket";

export const logUserOut = () => {
  disconnect();
  sessionStorage.removeItem("HBU#221#");
  sessionStorage.removeItem("!!OPQ1"); //user type
  sessionStorage.removeItem("#userDetail#");
  sessionStorage.removeItem("timerDetailsObject"); // chat timer
  window.location.href = "/";
};
export const UserProfileSummary = ({ data, refresh }) => {
  const { email, phoneNumber, firstName, lastName, image } = data || {};
  const imageUploadRef = useRef("");

  const uploadImage = async (res) => {
    const formData = new FormData();
    formData.append("profileImage", res);
    await uploadProfileImage(formData).then(() => {
      // refresh()

      // return;
      window.location.reload();
    });
  };

  return (
    <Box>
      <CurveBackgroundColor>
        <Stack direction={"row"} alignItems="center" spacing="60px">
          <Box position={"relative"}>
            <Avatar size="2xl" name={`${firstName} ${lastName}`} src={image} />
            <AiFillPlusCircle
              fontSize={"2em"}
              cursor="pointer"
              onClick={() => imageUploadRef.current.click()}
              style={{
                color: "#09B5A9",
                position: "absolute",
                right: "-10px",
                top: "90px",
              }}
            />
            <input
              style={{ display: "none" }}
              ref={imageUploadRef}
              onChange={(res) => uploadImage(res?.target?.files?.[0])}
              type="file"
              accept=".jpg, .png, .jpeg"
            />
          </Box>

          <CurveBackgroundColor smallCurve bg="#F9F9F9">
            <Stack direction="row" spacing="60px" alignItems={"center"}>
              <Stack spacing="10px">
                <Stack direction="row" alignItems={"center"}>
                  <FiUser />{" "}
                  <Text fontWeight="bold" color="#200960" fontSize="1.5em">
                    {firstName} {lastName}
                  </Text>
                </Stack>
                <Stack direction="row" alignItems={"center"}>
                  <BsTelephoneForward />{" "}
                  <Text color="#686278">{phoneNumber}</Text>
                </Stack>
                <Stack direction="row" alignItems={"center"}>
                  <FiMail /> <Text color="#686278">{email}</Text>
                </Stack>
              </Stack>
              <Button
                fontSize=".7em"
                bg="#5C2BA8"
                color="#fff"
                size="sm"
                width="120px"
                onClick={logUserOut}
                _hover={{ bg: "#5C2BA8" }}
              >
                Logout
              </Button>
            </Stack>
          </CurveBackgroundColor>
        </Stack>
      </CurveBackgroundColor>
    </Box>
  );
};
