import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { UserProfileContext } from "../context/UserContext";

function TrackPromoAvailability({ children }) {
  const { userProfile } = useContext(UserProfileContext) || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [counter, setCounter] = useState(0);
  
  const [firstTimeMounting, setFirstTimeMounting] = useState(true);
  const [loading, setLoading] = useState(false);

  const doctorId = useSelector((state) => state.auth.docId);
  const dispatch = useDispatch();
  const [availability, setAvailability] = useState(false);

  console.log("Status", firstTimeMounting , userProfile?.used15MinuteOffer == 'pending');
  
  useEffect(() => {
    if (firstTimeMounting) {
      onOpen();
      return;
    }
  }, []);

  const handleNo = async () => {
    if (firstTimeMounting) {
      setFirstTimeMounting(false);
    }
    onClose();
  };

  
  return (
    <>
      {children}
   {firstTimeMounting && userProfile?.used15MinuteOffer=='pending' && (
      <Modal isOpen={isOpen} onClose={handleNo} isCentered>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton _focus={{ boxShadow: "none" }} />
          <ModalBody>
         
              <>
             
              < Text fontWeight={"bold"} fontSize="1.5em" color="#2A2047">
              { "You have an active free 15-Minutes session "}
          </Text>
          <Stack direction="row" my="30px">
                  
            <Button
              bg="transparent"
              color="#000000ab"
              _focus={{ background: "transparent" }}
              _hover={{ background: "transparent" }}
              border="1px solid #efefef"
              flex="1"
              onClick={handleNo}
            >
              Ok
            </Button>
          </Stack>
        </>
      </ModalBody>
    </ModalContent >
      </Modal >)}
    </>
  );
}

export default TrackPromoAvailability;
