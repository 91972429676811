import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import {
  createSubscription,
  getSubscriptionList,
  verifySubscription,
} from "../services";

export const CreditSubscription = ({ user, refresh, ...props }) => {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedSub, setSelectedSub] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const getSubscriptionData = useCallback(() => {
    setLoading(true);
    getSubscriptionList()
      .then((data) => {
        data.sort((a, b) => a.credits - b.credits);
        setSubscriptionList(data);
      })
      .catch((e) => null)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getSubscriptionData();
  }, [getSubscriptionData]);

  const handleChange = (e) => {
    const { value } = e.target;
    const sub = subscriptionList.find((item) => item._id === value);
    setSelectedSub({ ...sub });
  };

  const submit = () => {
    setPaymentLoading(true);
    createSubscription({ subscriptionId: selectedSub._id })
      .then((data) => {
        setPaymentData({ ...data });
        setPaymentModal(true);
      })
      .catch((e) => {
        errorNotifier(e?.response?.data?.data || "Subscription failed");
      })
      .finally(() => {
        setPaymentLoading(false);
      });
  };

  const verifyPayment = () => {
    if (paymentData?.authorization_url) {
      verifySubscription({ reference: paymentData.reference })
        .then((resp) => {
          successNotifier(resp?.message);
          props.update && props.update();
          // getSubscriptionData()
        })
        .catch((e) => {
          errorNotifier(
            e?.response?.data?.message || "Payment verification failed"
          );
        })
        .finally((e) => {
          setPaymentModal(false);
          refresh();
        });
    }
  };

  return (
    <Box borderRadius={"10px"} p="30px" bg="#F6F4F9">
      <Text fontSize={"28px"} color="#200960">
        Subscription
      </Text>
      <Flex
        justifyContent={"space-between"}
        direction={["column", "column", "row"]}
      >
        <Box width={{ base: "100%", md: "45%" }}>
          <Text color="#000000">Input the preferred subscription package</Text>
          <Select
            _loading={loading}
            placeholder="select package"
            bg="#fff"
            color="#143861"
            my="25px"
            onChange={handleChange}
          >
            {subscriptionList.map((subscriptionItem) => {
              return (
                parseInt(subscriptionItem?.amount) > 0 && (
                  <option
                    value={subscriptionItem?._id}
                    key={subscriptionItem?._id}
                  >
                    {subscriptionItem?.subscriptionName} - &#8358;
                    {subscriptionItem?.amount?.toLocaleString() || 0}{" "}
                  </option>
                )
              );
            })}
          </Select>

          <Button
            borderRadius={"5px"}
            bg="#5C2BA8"
            color="#fff"
            width={"100%"}
            fontWeight="normal"
            _hover={{ bg: "#5C2BA8" }}
            isLoading={paymentLoading}
            loadingText={"Initializing Payment"}
            onClick={submit}
          >
            Proceed to payment
          </Button>
        </Box>
        <Box
          width={{ base: "100%", md: "45%" }}
          p="40px"
          bg="#fff"
          borderRadius={"10px"}
          fontWeight="bold"
        >
          <Flex width={"100%"} maxW={"150px"} justifyContent="space-between">
            <Text color="#ED819C">Credits</Text>
            <Text color="#2A2047"> {selectedSub.credits || 0}</Text>
          </Flex>
          <Flex
            width={"100%"}
            maxW={"150px"}
            my="5px"
            justifyContent="space-between"
          >
            <Text color="#ED819C">Cost</Text>
            <Text color="#2A2047"> &#8358;{selectedSub.amount || 0}</Text>
          </Flex>
          <Flex width={"100%"} maxW={"150px"} justifyContent="space-between">
            <Text color="#ED819C">Total</Text>
            <Text color="#2A2047"> &#8358;{selectedSub.amount || 0}</Text>
          </Flex>
        </Box>
      </Flex>

      <Modal isOpen={paymentModal} onClose={verifyPayment} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment checkout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              src={paymentData?.authorization_url}
              frameborder="0"
              width="100%"
              height="450"
              title="payment checkout"
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
