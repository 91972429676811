import { Box, Stack } from "@chakra-ui/react";
import React, {
  // useCallback,
  useEffect,
  useState,
} from "react";
import { tableBodyData } from "./components/tableBodyData";
import { ChatFeedSection } from "./components/ChatFeedSection";
import { ChatListSection } from "./components/ChatListSection";
import { useSelector } from "react-redux";
import { getConversations } from "../doctors/services/getDoctors";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useContext } from "react";
import { UserProfileContext } from "../../context/UserContext";

// import { IoReorderThreeSharp } from "react-icons/io5";
import {
  BOOKING_END_MINUTE,
  // BOOKING_TIMER_IN_SECONDS,
} from "../../app/constants";

export const Chats = () => {
  const location = useLocation();

  const [displayChat, setDisplayChat] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const [
    displayOrHideChatFeedForSmScreen,
    setDisplayOrHideChatFeedForSmScreen,
  ] = useState(true);
  const [file, setFile] = useState();

  const [contacts, setContacts] = useState([]);
  const userId = useSelector((state) => state?.auth?.id);
  const { userProfile } = useContext(UserProfileContext) || {};
 const meetType = (userProfile?.lockedCredit === 0 && userProfile?.used15MinuteOffer=='pending') ?'free': 'paid'
  const [conversationData, setConversationData] = useState([]);

  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    setLoading(true);
    getConversations(userId)
      .then((data) => {
        setConversationData(data);

        setContacts([...data]);

        const getDoctorDataFromLink = location?.state?.doctor;
        
        if (getDoctorDataFromLink) {
          // get only the doctor details, the patient is/wants to chat with
          const getFullDoctorObject = data?.find(
            (obj) => obj?.doctorId?._id === getDoctorDataFromLink?.doctorId?._id
          );

          if (!getFullDoctorObject) {
            setDisplayChat(data?.[0]);
            return;
          }

          // the room id is in this object
          setDisplayChat(getFullDoctorObject);
        } else {
          setDisplayChat(data?.[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, refresh, location?.state?.doctor]);

  // get timer of the conversation data, and store in local storage if time hasnt expired
  useEffect(() => {
    if (!conversationData) return;
    const filterConversationData = conversationData?.find(
      (data) => data?.doctorId?._id === displayChat?.doctorId?._id
    );
    
    const getStartTimer = filterConversationData?.timer;
    const timerHasNotExpired =
      new Date() <
      new Date(dayjs(getStartTimer).add(BOOKING_END_MINUTE(meetType), "minute").format())
        ? true
        : false;

    if (getStartTimer && timerHasNotExpired) {
      const doctorIdOfTimer = filterConversationData?.doctorId?._id;
      sessionStorage.setItem(
        "timerDetailsObject",
        JSON.stringify({
          startTime: dayjs(getStartTimer).format(), //convert it to local format,
          doctorIdOfTimer,
        })
      );
    }
  }, [displayChat, conversationData, refresh]);

  return (
    <Box>
      <Stack
        spacing="10px"
        width="100%"
        direction={["column", "column", "row"]}
        justifyContent={"space-between"}
        display={["flex", "flex", "none"]}
      >
        {displayOrHideChatFeedForSmScreen ? (
          <Box flex="2" maxW="300px">
            <ChatListSection
              getData={tableBodyData}
              doctors={contacts}
              loading={loading}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              setDisplayChat={setDisplayChat}
              setDisplayOrHideChatFeedForSmScreen={
                setDisplayOrHideChatFeedForSmScreen
              }
              displayOrHideChatFeedForSmScreen={
                displayOrHideChatFeedForSmScreen
              }
            />
          </Box>
        ) : (
          <Box flex="5">
            <ChatFeedSection
              displayChat={displayChat}
              refresh={() => setRefresh({})}
              setDisplayOrHideChatFeedForSmScreen={
                setDisplayOrHideChatFeedForSmScreen
              }
              displayOrHideChatFeedForSmScreen={
                displayOrHideChatFeedForSmScreen
              }
              file={file}
              setFile={setFile}
            />
          </Box>
        )}
      </Stack>
      <Stack
        spacing="10px"
        width="100%"
        direction={["column", "column", "row"]}
        justifyContent={"space-between"}
        display={["none", "none", "flex"]}
      >
        <Box flex="2" maxW="300px">
          <ChatListSection
            getData={tableBodyData}
            doctors={contacts}
            loading={loading}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            setDisplayChat={setDisplayChat}
            setDisplayOrHideChatFeedForSmScreen={
              setDisplayOrHideChatFeedForSmScreen
            }
            displayOrHideChatFeedForSmScreen={displayOrHideChatFeedForSmScreen}
          />
        </Box>
        <Box flex="5">
          <ChatFeedSection
            refresh={() => setRefresh({})}
            setDisplayOrHideChatFeedForSmScreen={
              setDisplayOrHideChatFeedForSmScreen
            }
            displayOrHideChatFeedForSmScreen={displayOrHideChatFeedForSmScreen}
            displayChat={displayChat}
          />
        </Box>
      </Stack>
    </Box>
  );
};
