import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Text,
  Stack,
  Flex,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { deleteNotificationData } from "../../../store/actions/notificationAction";
import { cancelBooking } from "../services";
import { getDoctorById } from "../services/getDoctors";

export const WaitingToBeAccepted = ({
  bookingId,
  open,
  setOpen,
  showCancelButton,
  setShowCancelButton,

  setWaitForDoctorApproval,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const doctorInfo = useSelector(
    (state) => state?.notification?.incomingNotification
  );

  const [doctorTransferredToName, setDoctorTransferredToName] =
    useState("loading");

  const [loading, setLoading] = useState(false);

  const startChat = () => {
    history.push({
      pathname: "/chats",
      state: { doctor: doctorInfo },
    });

    //delete the notification from redux
    dispatch(deleteNotificationData({}));
  };

  const getDoctorName = async (id) => {
    await getDoctorById(id)
      .then((res) => {
        setDoctorTransferredToName(res?.name);
      })
      .catch(() => {
        setDoctorTransferredToName("Error getting doctor's name");
      });
  };

  useEffect(() => {
    if (doctorInfo?.eventMessage !== "Booking Transferred") return;
    getDoctorName(doctorInfo?.eventMessageData?.doctorId);
  }, [doctorInfo?.eventMessage, doctorInfo?.eventMessageData?.doctorId]);
  const dotStyles = `#dots #dot1{
  animation: load 1s infinite;
}

#dots #dot2{
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3{
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}`;

  return (
    <>
      <Modal
        isOpen={open}
        // onClose={onClose}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalCloseButton
            _focus={{ boxShadow: "none" }}
            onClick={() => setOpen(false)}
          /> */}
          <ModalBody>
            {!doctorInfo && (
              <Flex
                direction={"column"}
                width={"100%"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Text fontSize="1.2em" textAlign={"center"} mb="20px">
                  Please wait, while the doctor respond...
                  {/* <p
                    style={{
                      fontSize: ".6em",
                      color: "#ff6161",
                      marginTop: "2px",
                    }}
                  >
                    Note: Booking will be cancelled in 3 min, if no response
                  </p> */}
                </Text>

                <style>{dotStyles}</style>
                <svg
                  id="dots"
                  width="60px"
                  height="30px"
                  viewBox="0 0 132 58"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSketch="http://www.bohemiancoding.com/sketch/ns"
                >
                  {/* <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch --> */}
                  <title>dots</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    sketchType="MSPage"
                  >
                    <g id="dots" sketchType="MSArtboardGroup" fill="#5C2BA8">
                      <circle
                        id="dot1"
                        sketchType="MSShapeGroup"
                        cx="25"
                        cy="30"
                        r="13"
                      ></circle>
                      <circle
                        id="dot2"
                        sketchType="MSShapeGroup"
                        cx="65"
                        cy="30"
                        r="13"
                      ></circle>
                      <circle
                        id="dot3"
                        sketchType="MSShapeGroup"
                        cx="105"
                        cy="30"
                        r="13"
                      ></circle>
                    </g>
                  </g>
                </svg>
                {showCancelButton && (
                  <Button
                    mt="10px"
                    bg="#6027B2"
                    _hover={{ bg: "#6027B2" }}
                    _focus={{ boxShadow: "none" }}
                    color="#fff"
                    size="xs"
                    isLoading={loading}
                    onClick={() => {
                      setLoading(true);

                      cancelBooking(bookingId)
                        .then(() => {
                          setLoading(false);
                          setWaitForDoctorApproval(false);
                          setShowCancelButton(false);
                          successNotifier("Successfully cancelled booking.");
                        })
                        .catch(() => {
                          setLoading(false);
                          setShowCancelButton(true);
                          errorNotifier(
                            "Error cancelling booking, please try again"
                          );
                        });
                    }}
                  >
                    Cancel booking
                  </Button>
                )}
              </Flex>
            )}
            {doctorInfo?.eventMessage === "Booking Transferred" && (
              <Stack p="10px" fontSize={"1.1em"}>
                <Text textAlign={"center"}>
                  Your booking has been transferred to{" "}
                  <b>{doctorTransferredToName}</b>
                  , <br /> waiting for his/her response
                </Text>
                {showCancelButton && (
                  <Button
                    alignSelf="center"
                    bg="#6027B2"
                    _hover={{ bg: "#6027B2" }}
                    _focus={{ boxShadow: "none" }}
                    color="#fff"
                    size="xs"
                    isLoading={loading}
                    onClick={() => {
                      setLoading(true);

                      cancelBooking(bookingId)
                        .then(() => {
                          setLoading(false);
                          setWaitForDoctorApproval(false);
                          setShowCancelButton(false);
                          successNotifier("Successfully cancelled booking.");
                        })
                        .catch(() => {
                          setLoading(false);
                          setShowCancelButton(true);
                          errorNotifier(
                            "Error cancelling booking, please try again"
                          );
                        });
                    }}
                  >
                    Cancel booking
                  </Button>
                )}
              </Stack>
            )}
            {doctorInfo?.eventMessage === "Booking Rescheduled" && (
              <Stack p="10px" fontSize={"1.1em"}>
                <Text textAlign={"center"}>
                  Your booking has been resheduled to{" "}
                  <b>
                    {dayjs(doctorInfo?.eventMessageData?.startDate).format(
                      "D MMMM YYYY"
                    )}
                  </b>
                  , from{" "}
                  <b>
                    {dayjs(doctorInfo?.eventMessageData?.startDate).format(
                      "hh:mm a"
                    )}
                  </b>{" "}
                  to{" "}
                  <b>
                    {dayjs(doctorInfo?.eventMessageData?.endDate).format(
                      "hh:mm a"
                    )}
                  </b>
                </Text>
                <Button
                  alignSelf="center"
                  background="#5C2BA8"
                  _hover={{ background: "#5C2BA8" }}
                  color="#fff"
                  size="xs"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </Stack>
            )}
            {doctorInfo?.eventMessage === "Booking APPROVED" &&
            doctorInfo?.status !== "INITIATED" ? (
              <Stack textAlign={"center"}>
                <Text fontWeight="bold" fontSize="1.5em" textAlign={"center"}>
                  Your request has been approved
                </Text>

                <Text>Waiting for doctor to initialize chat...</Text>
              </Stack>
            ) : (
              doctorInfo?.status === "INITIATED" && (
                <Stack>
                  <Text fontWeight="bold" fontSize="1.5em" textAlign={"center"}>
                    Chat Initiated
                  </Text>

                  <Button
                    color="#fff"
                    bg="#5C2BA8"
                    _hover={{ bg: "#5C2BA8" }}
                    onClick={() => startChat()}
                  >
                    Start chat now
                  </Button>
                </Stack>
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
