import { Box, Stack, Text } from "@chakra-ui/react";

export const UserMoreInformationTab = ({ data }) => {
  const { disabilities, allergies, bloodGroup, genotype } = data || {};

  const TITLE_STYLE = {
    fontSize: ".8em",
    color: "#352848",
  };

  const CONTENT_STYLE = {
    bg: "#fff",
    fontSize: ".86em",
    padding: "5px 7px",
    mt: "10px",
    height: "30px",
    maxHeight: "30px",
  };

  return (
    <Box>
      <Stack direction="row" spacing="30px">
        <Box>
          <Text {...TITLE_STYLE}>Disabilities</Text>
          <Box {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {disabilities?.length > 0 ? disabilities?.toString() : "None"}
          </Box>
        </Box>
        <Box>
          <Text {...TITLE_STYLE}>Allergies</Text>
          <Box {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {allergies?.length > 0 ? allergies?.toString() : "None"}
          </Box>
        </Box>
        <Box>
          <Text {...TITLE_STYLE}>Blood Group</Text>
          <Box {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {bloodGroup}
          </Box>
        </Box>
        <Box>
          <Text {...TITLE_STYLE}>Genotype</Text>
          <Box {...CONTENT_STYLE} style={{ bg: "#fff" }}>
            {genotype}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
