import React, { useState } from "react";
import { Box, Stack, Image } from "@chakra-ui/react";
import { ForgetPassword } from "./components/ForgetPassword";
// import { Wave } from './svgs/Wave'
import background from "./images/background.png";
import { AuthCode } from "./components/AuthCode";
import { ResetPassword } from "./components/ResetPassword";

export const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [current, setCurrent] = useState(0);

  function updateFormData(data) {
    setFormData((val) => ({ ...val, ...data }));
    setCurrent((val) => val + 1);
  }

  return (
    <Box>
      <Stack
        direction={["column", "column", "row"]}
        width="100vw"
        height="100vh"
        justifyContent="center"
      >
        <Box flex="1">
          <Image objectFit={"cover"} boxSize="100%" src={background} />
        </Box>

        <Box flex="1">
          {current === 0 ? (
            <ForgetPassword updateData={updateFormData} />
          ) : current === 1 ? (
            <AuthCode updateData={updateFormData} />
          ) : (
            <ResetPassword data={formData} updateData={updateFormData} />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
