import {
  updatePersonalInfo,
  updatePhoneNumberInfo,
  updateEmailInfo,
  updatePasswordInfo,
} from "../services/updateUser";

export const UpdateProfileHook = () => {
  const updatePersonalInformation = async (payload) => {
    updatePersonalInfo(payload);
  };

  const updateHealthInformation = async (payload) => {
    updatePersonalInfo(payload);
  };

  const updatePasswordInformation = (payload) => {
    updatePasswordInfo(payload);
  };

  const updateEmailInformation = async (payload) => {
    updateEmailInfo(payload);
  };

  const updatePhoneNumberInformation = async (payload) => {
    updatePhoneNumberInfo(payload);
  };

  return {
    updatePersonalInfo: (payload) => updatePersonalInformation(payload),
    updateHealthInfo: (payload) => updateHealthInformation(payload),
    updatePasswordInfo: (payload) => updatePasswordInformation(payload),
    updateEmailInfo: (payload) => updateEmailInformation(payload),
    updatePhoneNumberInfo: (payload) => updatePhoneNumberInformation(payload),
  };
};
