import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";

export const NotificationCard = ({ data }) => {
  var relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  return (
    <Box p="10px 30px" color="#929292">
      <Flex alignItems={"center"} mb="5px">
        <Box ml="20px">
          <Text color="#352848" fontWeight="bold" fontSize="1em">
            {data?.title}
          </Text>
          <Text fontSize=".87em" color="#352848b8">
            {data?.message}
          </Text>
          <Text fontSize={".7em"}>{dayjs(data?.createdAt).from(dayjs())}</Text>
        </Box>
      </Flex>
    </Box>
  );
};
