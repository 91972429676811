import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'

import cardBg from '../images/giftBg.svg'
import giftBg from '../images/gift.svg'
import { GiftCreditModal } from './GiftCreditModal'

export const GiftCard = ({ userDetails, refresh }) => {
  return (
    <Box
      backgroundImage={cardBg}
      bgSize="cover"
      borderRadius="10px"
      p="20px 40px"
      boxShadow="base"
      className="box-info"
    >
      <Flex justifyContent="space-between" direction={"column"} mb="10px">
        <Box mt="0px">
          <Text fontSize="22px" color="#200960" fontWeight="semibold">
            Gift Credit
          </Text>
          <Flex>
            <Text fontSize="16px" fontWeight="normal" color="#5C2BA8">
              You can gift family some part of your subscription
            </Text>
            <Box>
              <Image src={giftBg} />
            </Box>
          </Flex>
          <Flex justifyContent={"flex-end"} alignItems="center" my="14px">
            <GiftCreditModal refresh={refresh} userDetails={userDetails} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
