import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { BsArrowLeft } from "react-icons/bs";

import pic from "../images/testImage.svg";
import { TestFlow } from "./TestFlow";
import { ABox } from "./Box";
import { addTests } from "../service";
import { errorNotifier } from "../../../components/NotificationHandler";
import { TestResult } from "./TestResult";
import { useHistory } from "react-router-dom";
import { getAllTests } from "../services/getAllTests";

export const TakeTest = ({ match, children }) => {
  const testName = match.params.name?.split("%20").join(" ");
  const inviteId = match?.params?.inviteId;

  const [scripts, setScripts] = useState(false);
  const [getTestResult, setTestResult] = useState(null);
  const [, setTestResponseResult] = useState({});
  const [allTests, setAllTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTestTitleFromSessionStorage = sessionStorage.getItem("testTitle");
  const firstTestNameCharacterInCapitalLetter =
    getTestTitleFromSessionStorage?.charAt?.(0).toUpperCase?.() +
    getTestTitleFromSessionStorage?.slice?.(1);

  const history = useHistory();

  const test = allTests?.find(
    (data) => data?.testName?.toLowerCase() === testName?.toLowerCase()
  );

  const findResponse = () => {
    const testResult = test?.testName === firstTestNameCharacterInCapitalLetter;

    setTestResponseResult(testResult);
  };

  const sendTestResult = (allTestResult) => {
    setLoading(true);

    const payload = {
      testId: test?._id,
      ...allTestResult,
      ...(inviteId ? { inviteId } : {}),
    };

    addTests(payload)
      .then((res) => {
        setLoading(false);
        setTestResult(res);
        setScripts(false);
        findResponse();
      })
      .catch(() => {
        errorNotifier("Error sending tests result.");
        setTestResult(null);
        setLoading(false);
      });
  };

  const recommendations = test?.recommendations
    ?.map((recommendation) => {
      return {
        originalGrade: recommendation?.score,
        scores: recommendation?.score?.split(/[," "+-\s]/),
        figures: recommendation?.score
          ?.split(/[," "+-\s]/)
          ?.flat(1)
          ?.map((str) => str.replace(/[A-Za-z]/g, ""))
          ?.filter((nums) => nums !== "")
          ?.map((toNums) => parseInt(toNums)),
        figuresss:
          recommendation?.score
            ?.split(/[," "+-\s]/)
            ?.flat(1)
            ?.map((str) => str.replace(/[A-Za-z]/g, ""))
            ?.filter((nums) => nums !== "")?.[1] * 1 ||
          recommendation?.score
            ?.split(/[," "+-\s]/)
            ?.flat(1)
            ?.map((str) => str.replace(/[A-Za-z]/g, ""))
            ?.filter((nums) => nums !== "")?.[0] * 1,
        comments: recommendation?.comment,
        interpretation: recommendation?.interpretation,
        info: recommendation?.info,
        testName: recommendation?.testName,
        testInfo: recommendation?.testInfo,
      };
    })
    .sort((a, b) => a.figuresss - b.figuresss);

  const neuroticism = getTestResult?.result?.neuroticism;
  const agreeableness = getTestResult?.result?.agreeableness;
  const extraversion = getTestResult?.result?.extraversion;
  const conscientiousness = getTestResult?.result?.conscientiousness;
  const opennessToExperience = getTestResult?.result?.opennessToExperience;

  //Personality: Special case

  //Conscientiousness Case 1
  const ConscientiousnessResultFiltered = recommendations?.filter(
    (searched) => {
      let result;
      if (searched?.testName === "Conscientiousness") {
        result = searched?.figuresss <= Number(conscientiousness);
      }

      return result;
    }
  );

  //Conscientiousness Case 2
  const ConscientiousnessResultFiltered1 = recommendations?.filter(
    (searched) => {
      let result;
      if (searched?.testName === "Conscientiousness") {
        result = searched?.figuresss >= Number(conscientiousness);
      }

      return result;
    }
  );

  //Neuroticism Case 1
  const NeurosisResultFiltered = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Neurosis") {
      result = searched?.figuresss <= Number(neuroticism);
    }

    return result;
  });

  //Neuroticism Case 2
  const NeurosisResultFiltered1 = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Neurosis") {
      result = searched?.figuresss >= Number(neuroticism);
    }

    return result;
  });

  //Aggreeableness Case 1
  const AgreeablenessFiltered = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Agreeableness") {
      result = searched?.figuresss <= Number(agreeableness);
    }

    return result;
  });

  //Aggreeableness Case 2
  const AgreeablenessFiltered1 = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Agreeableness") {
      result = searched?.figuresss >= Number(agreeableness);
    }

    return result;
  });

  //"Openness to Experience" Case 1
  const OpennessResultFiltered = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Openness to Experience") {
      result = searched?.figuresss <= Number(opennessToExperience);
    }

    return result;
  });

  //"Openness to Experience" Case 2
  const OpennessResultFiltered1 = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Openness to Experience") {
      result = searched?.figuresss >= Number(opennessToExperience);
    }

    return result;
  });

  // Extraversion case 1
  const ExtraversionResultFiltered = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Extraversion") {
      result =
        Number(getTestResult?.result?.extraversion) >= searched?.figuresss;
    }

    return result;
  });

  // Extraversion case 2
  const ExtraversionResultFiltered1 = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName === "Extraversion") {
      result =
        Number(getTestResult?.result?.extraversion) <= searched?.figuresss;
    }

    return result;
  });

  // Special Case: Persnality Test
  const returnSelected = (
    filteredTestOne,
    filteredTestTwo,
    filteredTestResult
  ) => {
    const res =
      filteredTestOne?.length === 0
        ? filteredTestTwo
            .sort((a, b) => a.figuresss - b.figuresss)
            ?.find(
              (searched) => {
                let result =
                  searched?.figuresss <= Number(filteredTestResult)
                    ? searched?.figuresss <= Number(filteredTestResult)
                    : searched?.figuresss >= Number(filteredTestResult);

                return result;
              }
              // searched?.figuresss <= getTestResult?.result?.result ||
              // getTestResult?.result
            )
        : filteredTestOne && filteredTestOne?.length > 1
        ? filteredTestOne
            .sort((a, b) => b.figuresss - a.figuresss)
            ?.find((searched) => {
              let result =
                searched?.figuresss <= Number(filteredTestResult)
                  ? searched?.figuresss <= Number(filteredTestResult)
                  : searched?.figuresss >= Number(filteredTestResult);

              return result;
            })
        : filteredTestOne?.find((searched) => {
            let result = searched?.figuresss >= Number(filteredTestResult);

            return result;
          });

    return { testRecommendations: res, testScores: filteredTestResult };
  };

  const filtered = recommendations?.filter((searched) => {
    let result = getTestResult?.result?.result
      ? searched?.figuresss <= getTestResult?.result?.result
      : searched?.figuresss <= getTestResult?.result;

    return result;
  });

  const findOne =
    filtered?.length > 1 && filtered?.length < recommendations?.length
      ? recommendations
          .sort((a, b) => a.figuresss - b.figuresss)
          ?.find(
            (searched) => {
              let result = getTestResult?.result?.result
                ? searched?.figuresss >= getTestResult?.result?.result
                : searched?.figuresss >= getTestResult?.result;

              return result;
            }
            // searched?.figuresss <= getTestResult?.result?.result ||
            // getTestResult?.result
          )
      : filtered?.length > 1
      ? recommendations
          .sort((a, b) => b.figuresss - a.figuresss)
          ?.find((searched) => {
            let result = getTestResult?.result?.result
              ? searched?.figuresss <= getTestResult?.result?.result
              : searched?.figuresss <= getTestResult?.result;

            return result;
          })
      : recommendations?.find((searched) => {
          let result = getTestResult?.result?.result
            ? searched?.figuresss >= getTestResult?.result?.result
            : searched?.figuresss >= getTestResult?.result;

          return result;
        });

  const calculateResult = () => {
    const testObject = test?.testName === firstTestNameCharacterInCapitalLetter;

    return {
      getTotalScore: testObject?.totalScore,
      findOne,
    };
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goBack = () => {
    history.push("/take-test");
  };

  useEffect(() => {
    getAllTests(setAllTests, setLoading);
  }, []);

  //DASS TEST

  const filteredAllAnxiety = recommendations?.filter((searched) => {
    let result;
    if (searched?.testName?.trim() === "Anxiety") {
      result =
        Number(searched?.figuresss) >= Number(getTestResult?.result?.anxiety);
    }

    return result;
  });

  const filteredAllDepression = recommendations?.filter((searched) => {
    let result = searched?.figuresss <= getTestResult?.result?.depression;

    return result;
  });

  const filteredAllStress = recommendations?.filter((searched) => {
    // let result = searched?.figuresss <= getTestResult?.result?.stress;
    let result;
    if (searched?.testName?.trim() === "Stress") {
      result =
        Number(searched?.figuresss) >= Number(getTestResult?.result?.stress);
    }

    return result;
  });

  const filteredDepression =
    filteredAllDepression?.length > 1 &&
    filteredAllDepression?.length < recommendations?.length
      ? recommendations
          .sort((a, b) => a.figuresss - b.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Depression") {
              result = searched?.figuresss >= getTestResult?.result?.depression;
            }

            return result;
          })
      : filteredAllDepression?.length > 1
      ? recommendations
          .sort((a, b) => b?.figuresss - a?.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Depression") {
              result = searched?.figuresss >= getTestResult?.result?.depression;
            }

            return result;
          })
      : recommendations?.find((searched) => {
          let result;
          if (searched?.testName?.trim() === "Depression") {
            result = searched?.figuresss >= getTestResult?.result?.depression;
          }

          return result;
        });

  const filteredAnxiety =
    filteredAllAnxiety?.length > 1 &&
    filteredAllAnxiety?.length < recommendations?.length
      ? recommendations
          .sort((a, b) => a.figuresss - b.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Anxiety") {
              result = searched?.figuresss >= getTestResult?.result?.anxiety;
            }

            return result;
          })
      : filteredAllDepression?.length > 1
      ? recommendations
          .sort((a, b) => b?.figuresss - a?.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Anxiety") {
              result = searched?.figuresss >= getTestResult?.result?.anxiety;
            }

            return result;
          })
      : recommendations?.find((searched) => {
          let result;
          if (searched?.testName?.trim() === "Anxiety") {
            result = searched?.figuresss <= getTestResult?.result?.anxiety;
          }

          return result;
        });

  const filteredStress =
    filteredAllStress?.length > 1 &&
    filteredAllStress?.length < recommendations?.length
      ? recommendations
          .sort((a, b) => a.figuresss - b.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Stress") {
              result = searched?.figuresss >= getTestResult?.result?.stress;
            }

            return result;
          })
      : filteredAllStress?.length > 1
      ? recommendations
          .sort((a, b) => b?.figuresss - a?.figuresss)
          ?.find((searched) => {
            let result;
            if (searched?.testName?.trim() === "Stress") {
              result = searched?.figuresss >= getTestResult?.result?.stress;
            }

            return result;
          })
      : recommendations?.find((searched) => {
          let result;
          if (searched?.testName?.trim() === "Stress") {
            result = searched?.figuresss <= getTestResult?.result?.stress;
          }

          return result;
        });

  return (
    <>
      <Flex align="center" mb="20px" onClick={goBack} cursor="pointer">
        <BsArrowLeft size={26} style={{ margin: "0 15px 0px 0px" }} />
        <Text fontSize="18px">Home</Text>
      </Flex>
      <Box w="100%">
        <Stack
          bg="#5C2BA8"
          color="#fff"
          borderTopRadius="15px"
          p="60px"
          fontSize="13px"
          width={"100%"}
        >
          <Heading fontFamily="Gilroy-Bold" color="#EEE4FE">
            {test?.testName}
          </Heading>
          <Flex>
            <Box color="#EEE4FE">
              <Text
                // key={i}
                fontSize={["18px"]}
                maxWidth={"600px"}
                mb="20px"
              >
                {test?.description}
              </Text>
            </Box>

            <Box width="40%">
              <Image width="100%" src={pic} alt="testImage" />
            </Box>
          </Flex>
        </Stack>
      </Box>
      {scripts ? (
        <Box bg="#fff" px="20px">
          <Box mx="auto">
            <TestFlow
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              result={(allTestResult) => {
                sendTestResult(allTestResult);
              }}
              loading={loading}
            >
              {test?.questionTypes?.map((script, i) => {
                return (
                  <ABox
                    testName={test?.testName}
                    key={i}
                    test={test}
                    buttonsFormat={
                      test.testName === "Depression, Anxiety and Stress (DASS)"
                        ? `${test?.answerOptions?.[0]?.toLowerCase()}-1`
                        : // ||
                          //   test?.answerOptions?.[1]?.toLowerCase()
                          test?.answerOptions?.[0]?.toLowerCase()
                      // ||
                      //   test?.answerOptions?.[1]?.toLowerCase()
                    }
                    id={i + 1}
                    script={script?.questions}
                    scoreScale={test?.scoreScale}
                  />
                );
              })}
            </TestFlow>
          </Box>
        </Box>
      ) : getTestResult ? (
        <>
          <TestResult
            testId={test?._id}
            totalScore={calculateResult()?.getTotalScore}
            // response={calculateResult()?.getData()}
            response={calculateResult()?.findOne}
            testResult={getTestResult}
            totalQuestions={calculateResult()?.getTotalQuestions}
            testName={test?.testName}
            test={test}
            recommendations={recommendations}
            returnSelected={returnSelected(
              OpennessResultFiltered,
              OpennessResultFiltered1,
              opennessToExperience
            )}
            extraversion={returnSelected(
              ExtraversionResultFiltered,
              ExtraversionResultFiltered1,
              extraversion
            )}
            aggreeableness={returnSelected(
              AgreeablenessFiltered,
              AgreeablenessFiltered1,
              agreeableness
            )}
            neuroticism={returnSelected(
              NeurosisResultFiltered,
              NeurosisResultFiltered1,
              neuroticism
            )}
            conscientiousness={returnSelected(
              ConscientiousnessResultFiltered,
              ConscientiousnessResultFiltered1,
              conscientiousness
            )}
            dassDepression={filteredDepression}
            dassAnxiety={filteredAnxiety}
            dassStress={filteredStress}
          />
        </>
      ) : (
        <Box
          bg="#fff"
          mt="10px"
          color="#2A2047"
          py="30px"
          px="50px"
          w="100%"
          borderBottomRadius="15px"
        >
          <Heading fontFamily="Gilroy-Bold" pb="10px">
            Instructions
          </Heading>
          <Flex justify="space-between" fontSize="13px">
            <Box color="#2A2047">
              <Text maxWidth={"470px"} fontSize="18px" mb="10px">
                Please, note that all questions are to be answered for the
                assessment to be complete. Also, note that you cannot close the
                page or refresh once you start the test if you do the assessment
                will be submitted automatically.
              </Text>
              <Text color="#5C2BA8" fontWeight="semibold">
                {test?.questionTypes?.length} Questions
              </Text>
            </Box>
            <Button
              fontSize="16px"
              color="#fff"
              bg="#5C2BA8"
              borderRadius="50px"
              w="40%"
              mt="10%"
              py="15px"
              _hover={{ bg: "#5C2BA8" }}
              isDisabled={!test || test?.length < 0}
              onClick={() => setScripts(true)}
            >
              Continue
              <HiOutlineChevronRight
                size="2em"
                style={{ paddingLeft: "12px" }}
              />
            </Button>
          </Flex>
          <Flex fontWeight="light" fontSize="13px" my="20px">
            <Text pr="10px" borderRight="1px solid gray">
              Test
            </Text>
            <Text fontWeight="medium" color="#5C2BA8" px="10px">
              {test?.title}
            </Text>
            <Text px="10px" borderRight="1px solid gray">
              Duration
            </Text>
            <Text color="#5C2BA8" pl="10px" fontWeight="semibold">
              5 mins
            </Text>
          </Flex>
        </Box>
      )}
    </>
  );
};
