import Peer from "peerjs";

export let peerClient;

export async function connect(id) {
  peerClient = new Peer(id, {
    host: "https://backend.howbodi.io",
    port: 9000,
    path: "/peer-server",
  });
  peerClient.on("connection", (connect) => {});
}

export function disconnect() {
  if (peerClient) {
    peerClient.disconnect();
  }
}
