import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { InviteModal } from "../../test/components/InviteModal";

import hands from "../images/hands.svg";

export const TestCard = ({ test }) => {
  return (
    <Box bg="#f6f4f9" borderRadius="10px" p="30px" className="box-info">
      <Flex justify={"space-between"}>
        <Box>
          <Text
            textTransform={"capitalize"}
            fontSize="20px"
            fontWeight="normal"
            color="#686278"
            mb="5px"
          >
            {test?.testName ? `${test?.testName} Test` : "No test"}
          </Text>
          <Text fontSize="12px" color="#c4c4c4" fontWeight="bold">
            {test?.date && new Date(test?.date).toLocaleDateString()}
          </Text>
        </Box>
        <Box>
          <Image src={hands} alt="" />
        </Box>
      </Flex>

      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="16px" fontWeight="normal" color="#352848" mt="0px">
            Test Score
          </Text>
          <Text
            fontFamily="Gilroy-Bold"
            fontSize="3em"
            fontWeight="bold"
            color="#200960"
            lineHeight={1.1}
          >
            {test?.result?.result || test?.result || 0}
          </Text>
          <Text fontSize={"14px"} color="#BBBBBB" textTransform={"capitalize"}>
            Average {test?.testName}
          </Text>
        </Box>

        <Box mt="40px">
          <InviteModal />
        </Box>
      </Flex>
    </Box>
  );
};
