import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { VideoRows } from "./components/VideoRows";
import { getAllVideos } from "./services/getAllVideos";

export const HealthVideos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllVideos(setVideos, setLoading);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <VideoRows videos={videos} />
    </Box>
  );
};
