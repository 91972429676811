import React, { useState } from "react";
import { Box, Button, Center, Flex, Text, Tooltip } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";

export const HealthIssuesCard = ({ title, description }) => {
  const [btnVisibility, setBtnVisibility] = useState(false);
  return (
    <Box
      bg="#fff"
      borderRadius="10px"
      p="20px"
      width="100%"
      height={"100%"}
      className="box-info"
    >
      <Tooltip label={title}>
        <Text
          fontSize="22px"
          fontWeight="bold"
          color="#200960"
          mb="10px"
          isTruncated
          maxWidth={"250px"}
        >
          {title}
        </Text>
      </Tooltip>
      <Flex
        // height={"150px"}
        direction={"column"}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Tooltip label={description}>
          <Text color="#929292" fontSize={"16px"}>
            {description.length > 150
              ? description?.substr(0, 150) + "..."
              : description}
          </Text>
        </Tooltip>
        <Box position={"relative"}>
          {btnVisibility && (
            <Link
              to={`/take-test/${title?.toLowerCase()}`}
              onMouseOut={() => setBtnVisibility(!btnVisibility)}
              style={{ position: "absolute", top: "0%" }}
            >
              <Button
                borderRadius={"20px"}
                color={"#fff"}
                bg="#200960"
                _hover={{ bg: "#200960" }}
                mt="10px"
                rightIcon={<BsArrowRight color="#09B5A9" />}
                onClick={() => sessionStorage.setItem("testTitle", title)}
              >
                View Report
              </Button>
            </Link>
          )}
          <Center w="40px" h="40px" bg="#FFF5E9" color="white" mt="10px">
            <MdKeyboardArrowRight
              color="#200960"
              size={"1.9em"}
              style={{ borderRadius: "50px" }}
              onMouseEnter={() => setBtnVisibility(!btnVisibility)}
            />
          </Center>
        </Box>
        {/* <Link to={`/take-test/${title?.toLowerCase()}`}>
          <Button
            borderRadius={"20px"}
            onClick={() => sessionStorage.setItem("testTitle", title)}
            color={"#fff"}
            bg="#200960"
            _hover={{ bg: "#200960" }}
            mt="10px"
            rightIcon={<BsArrowRight color="#09B5A9" />}
          >
            Take Test
          </Button>
        </Link> */}
      </Flex>
    </Box>
  );
};
