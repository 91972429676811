import { createStandaloneToast, Box, Text } from "@chakra-ui/react";

export const LoadingMessage = ({
  bg,
  text = "Loading, please wait...",
  duration = 2000,
  w = "200px",
}) => {
  const toast = createStandaloneToast();
  return toast({
    position: "bottom",
    duration,
    render: () => (
      <Box borderRadius="5px" color="#fff" bg={bg || "#bfbf36"} p={2} w={w}>
        <Text textAlign="center" fontWeight="bold">
          {text}
        </Text>
      </Box>
    ),
  });
};
