import { createAction } from "@reduxjs/toolkit"

export const setUserData = createAction("user/setUser", (idsObj) => {
  return {
    payload: idsObj,
  };
});

// export const toggleState = createAction("changeState", (state) => {
//   return {
//     payload: state,
//   };
// });

