/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LANDING: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SIGNUP: "/signup",
  VERIFY_EMAIL: "/verify-email/:verificationId?",
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  DASHBOARD: "/dashboard",
  TEST: "/take-test",
  SUBSCRIPTION: "/subscription",
  PROFILE: "/profile",
  DOCTORS: "/doctors",
  HISTORY: "/history",
  ADDICTION: "/take-test/addiction",
  TEST_TITLE: "/take-test/:name/:inviteId?",
  INVITE: "/take-test/invite",
  CHATS: "/chats",
  HEALTH_VIDEOS: "/health-videos",
  VIEW_VIDEO: "/health-videos/:id",
  BOOKING_SCHEDULE: "/booking-schedule",
  NOTIFICATIONS: "/notifications",
};

export const DATA_ROWS = {
  LIMIT: 20,
};

export const BOOKING_END_MINUTE = (condition) => (condition==='free' ? 15 : 30);

export const BOOKING_TIMER_IN_SECONDS= (condition) => (condition==='free' ? 15*60000 : 30*60000); //BOOKING_END_MINUTE in miliseconds

//one minute in miliseconds is 60000
