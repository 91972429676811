import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,

  styles: {
    global: (props) => ({
      body: {
        fontFamily: "Gilroy-Medium",
      },
    }),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID}
      >
        <ChakraProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
