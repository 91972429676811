import { Box, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import FullPageLoader from "../../../components/fullPageLoader";
import { getSingleVideo } from "../services/getSingleVideo";
import { IoReturnUpBackOutline } from "react-icons/io5";
// import { FaExternalLinkAlt } from "react-icons/fa";
import ReactPlayer from "react-player/lazy";

export const ViewVideo = ({ match }) => {
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const videoId = match.params.id;
  const history = useHistory();

  useEffect(() => {
    getSingleVideo(videoId, setVideo, setLoading);
  }, [match, videoId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box p="30px 40px" color={"#4a4a4a"} bg="#F6F4F9" borderRadius={"20px"}>
      <IoReturnUpBackOutline
        cursor="pointer"
        size={30}
        onClick={() => history.goBack()}
      />
      <Text fontSize={"1.5em"} fontWeight="bold" mb={"20px"} color={"#4a4a4a"}>
        {video?.[0]?.videoTitle}
      </Text>
      <ReactPlayer controls={true} url={video?.[0]?.videoUrl} />
      <Text mt="20px" mb="5px" fontWeight="bold">
        Description
      </Text>
      <Text fontSize={".9em"} width="65%">
        {video?.[0]?.videoDescription}
      </Text>
    </Box>
  );
};
