// import { errorNotifier, successNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getChats = async (userId, doctorId) => {
  const resp = await http.get(AUTH_ROUTES.GET_CHATS(userId, doctorId));
  return resp.data?.result?.data?.chats || [];
};

export const lockConversation = async (id, status) => {
  const resp = await http.put(AUTH_ROUTES.LOCK_CONVERSATION(id), {
    isLocked: status,
  });
  return resp.data;
};

export const rateDoctor = async (data) => {
  const resp = await http.post(AUTH_ROUTES.RATE_DOCTOR, data);
  return resp;
};
