import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const loginUser = async (payload, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.LOGIN, payload);
    setLoading(false);
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data || e.response?.data?.login?.message);
    //for users that have not verified their email
    if (e.response?.data?.login?.data?.emailVerification === false) {
      return e.response?.data?.login?.data;
    }
  }
};
export const googleVerifyUser = async (payload, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.GOOGLE_LOGIN, payload);

    return data;
  } catch (e) {
    // setLoading(false);
    errorNotifier(e.response?.data?.data || e.response?.data?.login?.message);
    //for users that have not verified their email
    if (e.response?.data?.login?.data?.emailVerification === false) {
      return e.response?.data?.login?.data;
    }
  }
};

export const loginAs = async (payload) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.LOGIN_AS, payload);
    const token = data?.token;
    if (token) {
      if (data?.userType?.client === "Staff") {
        sessionStorage.setItem("!!OPQ1", JSON.stringify(data?.userType));
      }
      sessionStorage.setItem("HBU#221#", token);
      window.location.reload();
    }
  } catch (e) {
    errorNotifier(e.response?.data?.errors?.[0]?.msg);
  }
};

export const resendToken = async (payload, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.RESEND_TOKEN, payload);
    setLoading(false);
    successNotifier("Please check your mail");
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
  }
};
export const verifyEmail = async (payload, history, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.VERIFY_EMAIL, payload);
    setLoading(false);
    successNotifier("Verification successful, please login");
    history.push("/login");
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
    setLoading(false);
  }
};
