import { setUserData } from "../actions/userAction";

/**
 *
 * @param {*} state
 * @param {*} action
 * @return {*}
 *
 *
 */
function r(state = { user: {} }, action) {
  switch (action.type) {

    case setUserData.type:
      return {
        ...state,
        user: action.payload
      };

    default:
      return state;
  }
}

export default r
