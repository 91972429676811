import { Box, Text, Divider, Stack, Button, Flex } from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export const TestResult = ({
  response,
  testResult = [],
  testId,
  testName,
  test,
  recommendations,
  returnSelected,
  extraversion,
  aggreeableness,
  neuroticism,
  conscientiousness,
  dassDepression,
  dassAnxiety,
  dassStress,
}) => {
  // const { result } = testResult || {};
  const history = useHistory();

  const retakeTest = () => {
    const currentUrl = window.location.href;
    window.location.href = currentUrl;
  };

  return (
    <Box bg="#fff">
      <Box color={"#5C2BA8"} p="80px">
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Text fontFamily="Gilroy-Bold" fontSize="2em" fontWeight={"bold"}>
            Test Result
          </Text>
          {/* <Text fontWeight="bold">
            <span
              style={{
                fontSize: "1.1em",
                color: "#09B5A9",
                fontWeight: "bold",
              }}
            >
              Test Score:
            </span>{" "}
            {result?.result || result}
          </Text> */}
        </Stack>
        <Divider borderColor="#DEDEDE" mt="20px" />
        <Stack mt="30px">
          {testName === "Personality" && (
            <>
              <Box>
                <Text fontWeight="bold">
                  {conscientiousness?.testRecommendations?.testName}
                  {" : "}
                  {conscientiousness?.testScores}
                </Text>
                <Text>
                  {conscientiousness?.testRecommendations?.interpretation}
                </Text>
                <b>Recomendations</b>
                {conscientiousness?.testRecommendations?.comment?.map(
                  (single, i) => (
                    <li key={i}>{single}</li>
                  )
                )}
              </Box>

              <Box>
                <Text fontWeight="bold">
                  {returnSelected?.testRecommendations?.testName}
                  {" : "}
                  {returnSelected?.testScores}
                </Text>
                <Text>
                  {returnSelected?.testRecommendations?.interpretation}
                </Text>
                <b>Recomendations</b>
                {returnSelected?.testRecommendations?.comment?.map(
                  (single, i) => (
                    <li key={i}>{single}</li>
                  )
                )}
              </Box>

              <Box>
                <Text fontWeight="bold">
                  {neuroticism?.testRecommendations?.testName}
                  {" : "}
                  {neuroticism?.testScores}
                </Text>
                <Text>{neuroticism?.testRecommendations?.interpretation}</Text>
                <b>Recomendations</b>
                {neuroticism?.testRecommendations?.comment?.map((single, i) => (
                  <li key={i}>{single}</li>
                ))}
              </Box>

              <Box>
                <Text fontWeight="bold">
                  {aggreeableness?.testRecommendations?.testName}
                  {" : "}
                  {aggreeableness?.testScores}
                </Text>
                <Text>
                  {aggreeableness?.testRecommendations?.interpretation}
                </Text>
                <b>Recomendations</b>
                {aggreeableness?.testRecommendations?.comment?.map(
                  (single, i) => (
                    <li key={i}>{single}</li>
                  )
                )}
              </Box>

              <Box>
                <Text fontWeight="bold">
                  {extraversion?.testRecommendations?.testName}
                  {" : "}
                  {extraversion?.testScores}
                </Text>
                <Text>{extraversion?.testRecommendations?.interpretation}</Text>
                <b>Recomendations</b>
                {extraversion?.testRecommendations?.comment?.map(
                  (single, i) => (
                    <li key={i}>{single}</li>
                  )
                )}
              </Box>
            </>
          )}

          {testName === "Depression, Anxiety and Stress (DASS)" &&
            testName !== "Personality" && (
              <>
                <Box>
                  <Text fontWeight="bold">
                    Depression: {testResult?.result?.depression}
                  </Text>
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {dassDepression?.info}
                  </Text>
                  <Text>{dassDepression?.interpretation}</Text>
                  <Text fontWeight={"semibold"}>Recomendations</Text>
                  {dassDepression?.comments?.map((single, i) => (
                    <li key={i}>{single}</li>
                  ))}
                </Box>

                <Box>
                  <Text fontWeight="bold">
                    Anxiety: {testResult?.result?.anxiety}
                  </Text>
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {dassAnxiety?.info}
                  </Text>
                  <Text>{dassAnxiety?.interpretation}</Text>
                  <b>Recomendations</b>
                  {dassAnxiety?.comments?.map((single, i) => (
                    <li key={i}>{single}</li>
                  ))}
                </Box>

                <Box>
                  <Text fontWeight="bold">
                    Stress: {testResult?.result?.stress}
                  </Text>
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {dassStress?.info}
                  </Text>
                  <Text>{dassStress?.interpretation}</Text>
                  <b>Recomendations</b>
                  {dassStress?.comments?.map((single, i) => (
                    <li key={i}>{single}</li>
                  ))}
                </Box>
              </>
            )}
          {
            testName !== "Personality" &&
            testName !== "Depression, Anxiety and Stress (DASS)" && (
              <Box mb="20px">
                <Text fontWeight="bold">
            <span
              style={{
                fontSize: "1.1em",
                color: "#09B5A9",
                fontWeight: "bold",
              }}
            >
              Test Score:
            </span>{" "}
            {testResult?.result}
          </Text> 
                <Text>
                  <b>Interpretation of total scores:</b>
                </Text>
                <Text fontSize="1.2em" fontWeight="bold" mt="20px">
                  {response?.interpretation?response?.interpretation:`No sign of ${testResult?.testName}` }
                </Text>
              </Box>
            )}

          {response?.comments &&
            testName !== "Personality" &&
            testName !== "Depression, Anxiety and Stress (DASS)" && (
            <Box mt="20px">
              
                <Text>
                  <b>Recomendation</b>
                </Text>
                {response?.info && <Text mb="10px">{response?.info}</Text>}
                {response?.comments?.map((res, i) => {
                  return (
                    <Stack direction={"row"}>
                      <Text> {i + 1}.</Text>
                      <Text key={i}>{res}</Text>
                    </Stack>
                  );
                })}
              </Box>
            )}
        </Stack>
        <Flex mt="40px" justifyContent={"space-between"} alignItems="center">
          <Text
            fontWeight="bold"
            textDecoration={"underline"}
            cursor="pointer"
            onClick={retakeTest}
          >
            Take test again
          </Text>
          <Button
            size="sm"
            _hover={{ bg: "#5C2BA8" }}
            p="15px 20px"
            color="#fff"
            bg={"#5C2BA8"}
            onClick={() =>
              history.push({
                pathname: "/doctors",
                state: {
                  afterTestBooking: true,
                  testId,
                },
              })
            }
          >
            See Doctor &nbsp;
            <BsArrowRight />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
