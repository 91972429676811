import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom"
import { errorNotifier } from "../../../components/NotificationHandler";
import { forgetPassword } from "../service";
import { BsArrowLeft } from "react-icons/bs";

export const ForgetPassword = (props) => {
  const [disabled, setDisabled] = useState(true);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    setLoading(true);
    forgetPassword({ email })
      .then(() => {
        props.updateData && props.updateData({ email });
      })
      .catch((e) => {
        errorNotifier(e.response?.data?.data || "Failed to submit email");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  return (
    // <Box>

    <Box width={"100%"} p="100px">
      <Heading
        fontFamily="Gilroy-Bold"
        mb="10px"
        color="#2A2047"
        fontSize="46px"
        lineHeight={1.1}
      >
        Forget <br />
        <Text style={{ fontWeight: "bold" }}>Password</Text>
      </Heading>
      <Text mb="20px">Enter your email to get your authentication code</Text>
      <form onSubmit={submit}>
        <FormControl>
          <FormLabel htmlFor="email" color="#929292">
            Email
          </FormLabel>
          <Input
            height="50px"
            borderRadius={"10px"}
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <Button
          mt="20px"
          bg="#2A2047"
          color="#fff"
          borderRadius={"10px"}
          width="100%"
          height="50px"
          isLoading={loading}
          type="submit"
          _hover={{ bg: "#5C2BA8" }}
          disabled={disabled}
        >
          Continue
        </Button>
      </form>

      <Link to="/">
        <Stack
          marginTop="10px"
          color="red"
          fontSize=".8em"
          direction="row"
          alignItems="center"
        >
          <BsArrowLeft /> <Text>Go Back</Text>
        </Stack>
      </Link>
    </Box>

    // </Box>
  );
};
