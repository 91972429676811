import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  useDisclosure,
  Divider,
  Input,
  Heading,
  Select,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllTests } from "../service";
import { InviteUser } from "../services/InviteUser";
export const InviteModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [testName, setTestName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [allTestNames, setAllTests] = useState([]);

  const handleSubmit = () => {
    const payload = {
      email: email,
      testName: testName,
    };
    InviteUser(payload, setLoading)
      .then(() => {
        setEmail("");
        onClose();
      })
      .catch(() => null);
  };

  useEffect(() => {
    getAllTests(setAllTests);
  }, []);

  return (
    <Box>
      <Button
        onClick={onOpen}
        p="5px 45px"
        borderRadius="25px"
        textAlign="center"
        bg="#09B5A9"
        color="#fff"
        _hover={{ bg: "#09B5A9" }}
      >
        Invite
      </Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Box p="30px" fontSize="14px">
            <Heading
              fontFamily="Gilroy-Bold"
              color="#2A2047"
              p="10px 0 7px 20px"
            >
              Send an Invite
            </Heading>
            <ModalCloseButton size={"lg"} color="gray" mt="5" mr="10" />
            <Divider orientation="horizontal" w="90%" mx="auto" />
            <ModalBody>
              <Text color="#200960" py="10px">
                Everyone deserves to get a proper mental test, do well to send
                <br /> a test invitation to family, friends and colleagues.
                <br />
                <br />
                Select or input test
              </Text>
              <Select
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
                variant="filled"
                fontSize="14px"
                color="#00B4D8"
              >
                {allTestNames?.map((test, id) => {
                  return (
                    <option key={id} value={test?.testName}>
                      {test?.testName}
                    </option>
                  );
                })}
              </Select>
              <Text color="#200960" py="10px">
                Enter Recipient Email
              </Text>
              <Input
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                display="flex"
                m="auto"
                fontSize="12px"
                color="#fff"
                _hover={{ bg: "#5C2BA8" }}
                bg="#5C2BA8"
                borderRadius="30px"
                my="10%"
                h="35px"
                isLoading={loading}
                w="50%"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};
