// import {
//   errorNotifier,
//   successNotifier,
// } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getDoctorDetails = async (doctorId) => {
  const data = await http.get(AUTH_ROUTES.GET_DOCTOR_INFO(doctorId));

  return data;
};
