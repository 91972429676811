// import { errorNotifier } from '../../../components/NotificationHandler'
import http, { AUTH_ROUTES } from "../../../services/api";

export const getDoctors = async (
  setAllDoctors,
  setDoctorsOnline,
  setLoading
) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_DOCTORS);

    let doctors = data?.data?.doctorDetails?.data?.doctors;

    setAllDoctors(doctors);
    const onlineDoctors = doctors?.filter(
      (doctor) => doctor?.available === true
    );
    setDoctorsOnline(onlineDoctors);

    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const getDoctorList = async (id) => {
  const resp = await http.get(AUTH_ROUTES.GET_DOCTORS);

  const data = resp.data?.doctorDetails?.data?.doctors;
  return data;
};

export const getConversations = async (id) => {
  const resp = await http.get(AUTH_ROUTES.GET_CONVERSATIONS(id));

  const data = resp.data?.result?.data?.conversations;
  return data;
};

export const getDoctorById = async (id) => {
  const resp = await http.get(AUTH_ROUTES.GET_DOCTOR_INFO(id));
  return resp?.data?.doctorDetails?.data?.doctors?.[0];
};
