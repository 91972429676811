import React, { useContext, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { UserInformationComponent } from "./components/UserInformationComponent";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import { UserProfileSettings } from "./components/UserProfileSettings";
import { getUserInfomation } from "./services/updateUser";
import { UserProfileContext } from "../../context/UserContext";

export const Profile = () => {
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(true);
  const [userInformation, setUserInformation] = useState([]);

  const { userProfile } = useContext(UserProfileContext) || {};
  const { _id } = userProfile || {};

  useEffect(() => {
    getUserInfomation(setLoading, _id, setUserInformation);
  }, [refresh, _id]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box p={["10px", "10px", "0px"]}>
      <Flex direction={["column", "column"]} mt="10px">
        <Box w={["100%", "100%", "100%"]} mr="40px">
          <CurveBackgroundColor>
            <UserInformationComponent
              data={userInformation}
              refresh={async () => setRefresh({})}
            />
          </CurveBackgroundColor>
          <CurveBackgroundColor my="10px">
            <UserProfileSettings refresh={async () => setRefresh({})} />
          </CurveBackgroundColor>
        </Box>
      </Flex>
    </Box>
  );
};
