import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Box,
  Stack,
} from "@chakra-ui/react";
import ChooseAccount from "./ChooseAccount";
import bgImg from "../../../assets/images/choose_acc_bg.png";
import logo from "../../../assets/icons/howbodi_logo.svg";

function ChooseAccountModal({ open, onClose, data }) {
  return (
    <>
      <Modal onClose={onClose} size="full" isOpen={open}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton _focus={{ boxShadow: "none" }} />
          <ModalBody
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateAreas="overlaydemo"
              height="100%"
              position="absolute"
              top="0"
              left="0"
            >
              <Box gridArea="overlaydemo">
                <Image
                  src={bgImg}
                  alt="background image"
                  height="100vh"
                  width="100vw"
                  objectFit="cover"
                />
              </Box>

              <Box
                gridArea="overlaydemo"
                background="linear-gradient(90deg, rgb(0 0 0 / 58%) 0%, rgb(64 48 88 / 83%) 35%);"
              />
            </Box>
            <Stack
              zIndex="999"
              position="relative"
              spacing="20px"
              direction={["column", "column", "row"]}
              alignItems={"center"}
              justifyContent="center"
            >
              <Image src={logo} alt="logo" />
              <ChooseAccount accountData={data} />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChooseAccountModal;
