import { Button, Input, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

function ChildCheckListForParentForm({ filledFormData }) {
  const inputStyle = {
    _focus: { borderColor: "#5C2BA8" },
    fontWeight: "bold",
  };

  const [childName, setChildName] = useState("");
  const [childDOB, setChildDOB] = useState("");
  const [filledBy, setFilledBy] = useState("");

  const [disabled, setDisabled] = useState("");

  //validation
  useEffect(() => {
    if (!childDOB || !childName || !filledBy) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [childDOB, childName, filledBy]);

  const continueToTest = () => {
    let payload = {
      extraDetails: [{ childDOB, childName, filledBy }],
    };
    filledFormData(payload);
  };
  return (
    <Stack justifyContent={"center"} alignItems="center" py="40px">
      <Stack maxW={["300px", "600px"]} w={["300px", "600px"]}>
        <Stack direction="row">
          <Text flex={"2"}>Child's Name</Text>
          <Input
            value={childName}
            onChange={(e) => setChildName(e?.target?.value)}
            {...inputStyle}
            flex={"5"}
            variant={"flushed"}
          />
        </Stack>
        <Stack direction="row">
          <Text flex={"2"}>Child's Date of Birth</Text>
          <Input
            value={childDOB}
            onChange={(e) => setChildDOB(e?.target?.value)}
            {...inputStyle}
            flex={"5"}
            type="date"
            variant={"flushed"}
          />
        </Stack>
        <Stack direction="row">
          <Text flex={"2"}>Filled by</Text>
          <Input
            value={filledBy}
            onChange={(e) => setFilledBy(e?.target?.value)}
            {...inputStyle}
            flex={"5"}
            variant={"flushed"}
          />
        </Stack>

        <Stack mt="30px !important">
          <Button
            borderRadius={"33px"}
            w="150px"
            disabled={disabled}
            alignSelf={"center"}
            color="#fff"
            bg="#5C2BA8"
            _hover={{ background: "#5C2BA8" }}
            _focus={{ boxShadow: "none" }}
            onClick={continueToTest}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ChildCheckListForParentForm;
