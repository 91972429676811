export const tableBodyData = [
  {
    name: "John Riddle",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "20",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Addiction",
    resident: "Lagos",
    specialty: "Therapist",
    serviceType: "22-March, 2021",
    paymentStatus: "Paid",
    visitationType: "Consultation",
    frequency: "First Visit",
    time: "4:00pm",
    chat: "Have you applied for medications?",
    reply: "Fine Sir",
    chats: [
      {
        status: "mine",
        myChat: [
          "Have you applied for medications?",
          "Have is your health?",
        ],
      },
    ],
  },
  {
    name: "Tom Riddle",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "50",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Anxiety",
    resident: "Calabar",
    specialty: "Psychologist",
    serviceType: "22-March, 2021",
    paymentStatus: "Payout",
    visitationType: "Therapy",
    frequency: "Emergency",
    time: "11:00am",
    chat: "How are you doing?",
  },
  {
    name: "Tantalizer Riddle",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "23",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Life Stress",
    resident: "Lagos",
    specialty: "Physiotherapist",
    serviceType: "22-March, 2021",
    paymentStatus: "Payout",
    visitationType: "Consultation",
    frequency: "Second Visit",
    time: "2:00pm",
    chat: "What symptoms are you experiencing?",
  },
  {
    name: "Chicken Republic",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "20",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Addiction",
    resident: "Lagos",
    specialty: "Dentist",
    serviceType: "22-March, 2021",
    paymentStatus: "Payout",
    visitationType: "Consultation",
    frequency: "Consultaion",
    time: "6:00pm",
    chat: "How are you doing?",
  },
  {
    name: "Terry Riddle",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "20",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Addiction",
    resident: "Abuja",
    specialty: "Gynaecologist",
    serviceType: "22-March, 2021",
    paymentStatus: "Payout",
    visitationType: "Consultation",
    frequency: "First Visit",
    time: "9:00am",
    chat: "Good Morning Doc",
  },
  {
    name: "Mega Chicken",
    email: "anayo@gmail.com",
    phone: "+23470345678",
    branches: "40",
    status: "Active",
    imageUrl: "",
    dateCreated: "22-01-2022",
    testTaken: "Addiction",
    resident: "Ibadan",
    specialty: "Psychologist",
    serviceType: "22-March, 2021",
    paymentStatus: "Paid",
    visitationType: "Consultation",
    frequency: "First Visit",
    time: "4:00pm",
    chat: "How are you doing?",
  },
];
