import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { LoginForm } from "./components/LoginForm";
// import { Wave } from './svgs/Wave'
import background from "./images/background.png";
import GoogleSignin from "./components/GoogleSignin";

export const Login = () => {
  return (
    <Box>
      <Flex direction={["column", "column", "column", "row"]}>
        <Box flex="1">
          <Image
            objectFit={"cover"}
            width="100%"
            height="100%"
            p={["25px", "40px 50px 0", "50px 100px 0px", "0"]}
            src={background}
          />
        </Box>
        <Box flex="1">
          <LoginForm />
        </Box>
      </Flex>
    </Box>
  );
};
