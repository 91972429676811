import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  useDisclosure,
  Divider,
  Flex,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { giftCredit } from "../services/giftCredit";

export const GiftCreditModal = ({ userDetails, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [email, setEmail] = useState("");
  const [credits, setCredits] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      email,
      credits,
    };
    giftCredit(payload, setLoading)
      .then(() => {
        refresh();
        onClose();
        setEmail("");
        setCredits("");
      })
      .catch(() => null);
  };

  return (
    <Box>
      <Button
        _hover={{ bg: "#5C2BA8" }}
        onClick={onOpen}
        bg="#5C2BA8"
        color="#fff"
        borderRadius="25px"
      >
        Gift Credit
      </Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Flex justify="space-between" p=" 30px 30px 0px 30px">
            <Text fontSize="xl" fontWeight="bold">
              Gift Credit
            </Text>
            <Stack direction="row" alignItems={"center"}>
              <Text>Balance:</Text>

              <Text fontSize="xl" color="#3D348B" fontWeight="semibold">
                {userDetails?.credits}
              </Text>
            </Stack>
          </Flex>
          <Divider orientation="horizontal" w="90%" mx="auto" />
          <ModalBody>
            <Text fontSize="15px" fontWeight="light" color="#200960" px="10px">
              Gift your love ones some credit to be able to use all the features
              on this platform
            </Text>
            <Text
              px="10px"
              fontSize="15px"
              fontWeight="light"
              color="#200960"
              py="10px"
              pt="20px"
            >
              Enter Amount
            </Text>
            <Input
              mx="auto"
              type={"number"}
              variant="filled"
              value={credits}
              onChange={(e) => setCredits(e.target.value)}
            />
            <Text
              px="10px"
              fontSize="15px"
              fontWeight="light"
              color="#200960"
              py="10px"
            >
              Enter Recipient Email
            </Text>
            <Input
              mx="auto"
              variant="filled"
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={loading}
              my="40px"
              mx="auto"
              w="50%"
              color="#fff"
              borderRadius="30px"
              _hover={{ bg: "#5C2BA8" }}
              bg="#5C2BA8"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
