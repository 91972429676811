import { Text } from "@chakra-ui/react";
import { useState } from "react";
import RateModal from "./RateModal";

function RateDoctorOnClick({ doctorId }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Text cursor={"pointer"} onClick={() => setOpen(true)}>
        Rate Therapist
      </Text>

      <RateModal
        open={open}
        key={doctorId}
        close={() => setOpen(false)}
        doctorId={doctorId}
      />
    </>
  );
}

export default RateDoctorOnClick;
