import http, { AUTH_ROUTES } from "../../../services/api";

export const searchDoctor = async (search) => {
  const res = await http.get(AUTH_ROUTES.SEARCH_CONTACT(search));
 // console.log(res?.data)
  return res?.data?.conversations?.data?.conversations;
};

export const getBookingCountDownStartTimer = (bookingId) => {
  return http.get(AUTH_ROUTES.GET_BOOKING_TIMER(bookingId));
};
