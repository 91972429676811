import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";
// import { usersData } from "../../../store/actions/authAction";

export const loginUser = async (payload, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.LOGIN, payload);

    const token = data?.token;
    sessionStorage.setItem("HBU#221#", token);
    window.location.reload();
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
  }
};

export async function forgetPassword(data) {
  const resp = await http.post(AUTH_ROUTES.FORGOT_PASSWORD, data);
  return resp;
}

export async function resetPassword(data) {
  const resp = await http.post(AUTH_ROUTES.RESET_PASSWORD, data);
  return resp;
}
