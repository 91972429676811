import { Box, Divider, Heading, Stack, Text } from "@chakra-ui/react";
import { IoIosArrowForward } from "react-icons/io";
import { BiUserCircle } from "react-icons/bi";
import { loginAs } from "../service/loginUser";
import { useState } from "react";
import { LoadingMessage } from "../../../components/LoadingMessage";

function ChooseAccount({ accountData, ...props }) {
  const { otp, enterpriseDetails, _id, email } = accountData || {};
  const [showEnterpriseAccount, setShowEnterpriseAccount] = useState(false);
  const [clickId, setClickId] = useState("");
  const chooseEnterpriseAccount = () => {
    setShowEnterpriseAccount(true);
  };
  const loginToEnterprise = (enterpriseCode) => {
    LoadingMessage({ bg: "#5C2BA8" });
    const payload = {
      otp,
      enterpriseCode,
      email,
      _id,
    };
    loginAs(payload);
  };
  const loginToPersonal = () => {
    LoadingMessage({ bg: "#5C2BA8" });
    const payload = {
      otp,
      email,
      _id,
    };
    loginAs(payload);
  };
  return (
    <Box
      bg="#fff"
      maxW="400px"
      width={["350px", "400px"]}
      border="1px solid black"
      borderRadius="10px"
      py="30px"
      height="400px"
      {...props}
    >
      <Heading textAlign={"center"} fontFamily="Gilroy-Bold" color="#200960">
        Choose Account:
      </Heading>
      <Divider mt="7px" mb="50px" border="1px solid #757575 !important" />
      {showEnterpriseAccount ? (
        <Stack flex="1" px="3px" maxBlockSize={"400px"} overflow="auto">
          <Text fontWeight="bold" textAlign="center">
            Choose enterprise account
          </Text>
          {enterpriseDetails?.map((enterprise, id) => {
            return (
              <Box
                key={enterprise?.enterpriseCode}
                cursor="pointer"
                border="1px solid #200960"
                bg={clickId === id ? "#200960" : "transparent"}
                color={clickId === id ? "#fff" : "#200960"}
                fontWeight="bold"
                borderRadius="30px"
                textAlign="center"
                fontSize="1.1em"
                onClick={() => {
                  setClickId(id);
                  loginToEnterprise(enterprise?.enterpriseCode);
                }}
                padding="8px 5px"
              >
                <Text>{enterprise?.enterpriseName}</Text>
              </Box>
            );
          })}
        </Stack>
      ) : (
        <Stack direction="row">
          <Stack px="20px" flex="1">
            <Stack
              onClick={chooseEnterpriseAccount}
              cursor="pointer"
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
              p="20px 20px"
            >
              <BiUserCircle fontSize="2em" color="#09B5A9" />
              <Text fontSize="1.2em" fontWeight="bold">
                Enterprise account
              </Text>
              <IoIosArrowForward fontSize="1.2em" />
            </Stack>
            <Divider border="1px solid #757575" />
            <Stack
              onClick={loginToPersonal}
              cursor="pointer"
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
              p="20px 20px"
            >
              <BiUserCircle fontSize="2em" color="#09B5A9" />
              <Text fontSize="1.2em" fontWeight="bold">
                Personal account
              </Text>
              <IoIosArrowForward fontSize="1.2em" />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

export default ChooseAccount;
