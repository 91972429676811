import { createAction } from "@reduxjs/toolkit";

export const setNotificationData = createAction(
  "incomingNotificationData",
  (idsObj) => {
    return {
      payload: idsObj,
    };
  }
);

export const deleteNotificationData = createAction("deleteOldNotificationData");
