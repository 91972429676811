import { Avatar, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

export const VisitationDetails = ({
  data,
  name,
  frequency,
  time,
  px,
  bg,
  cursor,
  avatar,
}) => {
  return (
    <Flex
      // key={key}
      justifyContent={"space-between"}
      // my="10px"
      alignItems="center"
      borderBottom={"1px solid #EFEDF3"}
      py="12px"
      px={px}
      bg={bg}
      cursor={cursor}
    >
      <Flex width="70%" alignItems={"center"}>
        <Avatar size="sm" src={avatar} name={name} alt={`Picture of ${name}`} />
        <Box ml="20px">
          <Tooltip label={name}>
            <Text
              fontWeight="bold"
              color="#403058"
              fontSize="1.1em"
              lineHeight={1.2}
              // maxWidth="100px"
              isTruncated
            >
              {name}
            </Text>
          </Tooltip>
          <Flex
            width="150px"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Tooltip label={frequency}>
              <Text fontSize={"12px"} maxWidth="150px" isTruncated>
                {frequency}
              </Text>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>

      <Box>
        <Text fontSize={".8em"} fontWeight={"bold"} color="#757575">
          {time}
        </Text>
      </Box>
    </Flex>
  );
};
