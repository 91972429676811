import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { HealthInfoForm } from './HealthInfoForm'
import { PersonalInfoForm } from './PersonalInfoForm'
import { ChangePasswordForm } from './ChangePasswordForm'
import { ChangePhoneNumberForm } from './ChangePhoneNumberForm'
import { ChangeEmailForm } from './ChangeEmailForm'
import { FiUser } from 'react-icons/fi'
import { AiOutlinePlus, AiOutlineLock } from 'react-icons/ai'
import { HiOutlineMail } from 'react-icons/hi'
import { IoCallOutline } from 'react-icons/io5'

export const SettingsAccordion = ({ refresh }) => {
  const BTN_STYLE = { bg: '#fff', mb: '10px', _focus: { boxShadow: 'none' } }
  const REMOVE_BORDER = { border: 'none' }
  return (
    <Accordion allowToggle>
      <AccordionItem {...REMOVE_BORDER}>
        <h2>
          <AccordionButton {...BTN_STYLE}>
            <Stack
              alignItems={'center'}
              direction='row'
              flex='1'
              textAlign='left'
            >
              <FiUser /> <Text>Personal Information</Text>
            </Stack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px='0'>
          <PersonalInfoForm refresh={refresh} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem {...REMOVE_BORDER}>
        <h2>
          <AccordionButton {...BTN_STYLE}>
            <Stack
              alignItems={'center'}
              direction='row'
              flex='1'
              textAlign='left'
            >
              <AiOutlinePlus /> <Text>Health Information</Text>
            </Stack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px='0'>
          <HealthInfoForm refresh={refresh} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem {...REMOVE_BORDER}>
        <h2>
          <AccordionButton {...BTN_STYLE}>
            <Stack
              alignItems={'center'}
              direction='row'
              flex='1'
              textAlign='left'
            >
              <HiOutlineMail /> <Text>Change Email</Text>
            </Stack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px='0'>
          <ChangeEmailForm refresh={refresh} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem {...REMOVE_BORDER}>
        <h2>
          <AccordionButton {...BTN_STYLE}>
            <Stack
              alignItems={'center'}
              direction='row'
              flex='1'
              textAlign='left'
            >
              <IoCallOutline /> <Text>Change Phone Number</Text>
            </Stack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px='0'>
          <ChangePhoneNumberForm refresh={refresh} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem {...REMOVE_BORDER}>
        <h2>
          <AccordionButton {...BTN_STYLE}>
            <Stack
              alignItems={'center'}
              direction='row'
              flex='1'
              textAlign='left'
            >
              <AiOutlineLock /> <Text>Change Password</Text>
            </Stack>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px='0'>
          <ChangePasswordForm refresh={refresh} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
